import React, { useEffect, useState } from "react";

export default function DynamicModal({children}) {


  return (
        <>
          {children}
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
  );
}