import React from 'react'

import PropTypes from 'prop-types'

import './footer.css'

const Footer = (props) => {
  return (
    <div className={`footer-footer ${props.rootClassName} `}>
      <h1 className="footer-logo">{props.logo}</h1>
      <h1 className="footer-text">
        <span className="">Upgrade your business with our paid plans for</span>
        <br className=""></br>
        <span className="">Enhanced Features and Performance</span>
        <br className=""></br>
        <br className=""></br>
      </h1>
      <span className="footer-text06">
        <span className="">
          Email us at
          <span
            dangerouslySetInnerHTML={{
              __html: ' ',
            }}
          />
        </span>
        <span className="footer-text08">contact@50chats.com</span>
      </span>
      <span className="footer-text09">{props.Text}</span>
      <span className="footer-text10">{props.Text1}</span>
    </div>
  )
}

Footer.defaultProps = {
  logo: '50chats',
  Text: 'Copyright © 2023 50chats. All Rights Reserved.',
  Text1: 'Steksolutions Services',
  rootClassName: '',
}

Footer.propTypes = {
  logo: PropTypes.string,
  Text: PropTypes.string,
  Text1: PropTypes.string,
  rootClassName: PropTypes.string,
}

export default Footer
