import {Button, Input, Modal, Select, message as toast} from "antd";
import {test_api_endpoint} from "../../../apis/index"
import React, {useState, useEffect, useRef} from "react";
import {update_bot} from "../../../apis/index";
import "./api_drawer.css";
import Select_variable from "../buttons/select_variable";
import {json} from "d3";
import Headers from "../api_drawer_component/header";
import Body from "../api_drawer_component/body";
import Response_mapping from "../api_drawer_component/response_mapping";
import Response from "../api_drawer_component/response";
import FloatingButton from "../buttons/FloatingButton";

const APIDrawer = ({bot_id, variables, setVariables, node, onNodeChange}) => {
  const [tab, setTab] = useState("headers");
  const [headers, setHeaders] = useState(node.data.headers);
  const [mappings, setMappings] = useState(node.data.mappings);
  const [url, setUrl] = useState(node.data.url);
  const [method, setMethod] = useState(node.data.method);
  const [body, setBody] = useState(node.data.body);
  const [loading, setLoading] = useState(false);
  const [test_response, setTest_response] = useState({status_code: "", response: ""})

  useEffect(() => {
    function handleClickOutside(event) {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setShow_float(false);
      }
    }

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);



  useEffect(() => {
    setHeaders(node.data.headers);
    setMappings(node.data.mappings);
    setUrl(node.data.url);
    setMethod(node.data.method);
    setBody(node.data.body);
  }, [node.id]);

  
  const components = {
    headers: <Headers headers={headers} setHeaders={setHeaders} bot_id={bot_id} variables={variables} setVariables={setVariables} />,
    body: <Body variables={variables} body={body} setBody={setBody} />,
    response: <Response loading={loading} test_response={test_response} />,
    save_response: <Response_mapping test_response={test_response} mappings={mappings} setMappings={setMappings} bot_id={bot_id} variables={variables} setVariables={setVariables} />,
  };

  const handle_tab_click = (tab) => {
    setTab(tab);
  };

  const [show_float, setShow_float] = useState(false);
  const selectRef = useRef(null);

  const [insert_var, setInsert_var] = useState(false)

  const HandleFloatButtonClick = () => {
    setInsert_var(true)
  }

  const Handle_Modal_Close = () => {
    setInsert_var(false)
  }

  const Handle_Select_Change = (value) => {
      let new_url = url;
      setUrl((new_url += `{{${value}}}`));
      setInsert_var(false)
  }

  const HandleTestApiEndpoint = async () => {
    setTab("response")
    let data = JSON.stringify({method, url, headers, body, mappings})
    setLoading(true)
    let response = await test_api_endpoint(data)
    setTest_response(response)
    setLoading(false)
  }


  useEffect(() => {
    const updatedNode = {
      ...node,
      data: {method, url, headers, body, mappings},
    };
    onNodeChange(updatedNode);
  }, [mappings, method, url, headers, body]);

  return (
    <div className="api-apieditor">
      <div className="api-methodendpoint">
        <div className="api-method">
          <span className="api-text01">Method</span>
          <Select value={method} style={{width: "100%"}} onChange={(value) => setMethod(value)}>
            <Option value="GET">GET</Option>
            <Option value="POST">POST</Option>
            <Option value="PUT">PUT</Option>
            <Option value="PATCH">PATCH</Option>
            <Option value="DELETE">DELETE</Option>
            <Option value="HEAD">HEAD</Option>
            <Option value="OPTIONS">OPTIONS</Option>
          </Select>
        </div>
        <div className="api-endpoint">
          <span className="api-text02">Url</span>
          <input
            type="text"
            placeholder="e.g (https://50chats.com/api)"
            className="api-textinput1 input"
            value={url}
            onFocus={() => {
              setShow_float(true);
            }}
            onChange={(e) => {
              setUrl(e.target.value);
            }}
            ref={selectRef}
          />
          {show_float && <FloatingButton onClick={HandleFloatButtonClick} />}
        </div>
      </div>
      <div className="api-navbar">
        <div className="api-navgroup">
          <span className={`api-text0${tab === "headers" ? "5" : "3"}`} onClick={() => handle_tab_click("headers")}>
            Headers
          </span>
          <span className={`api-text0${tab === "body" ? "5" : "3"}`} onClick={() => handle_tab_click("body")}>
            Body
          </span>
          <span className={`api-text0${tab === "response" ? "5" : "3"}`} test_response={test_response} onClick={() => handle_tab_click("response")}>
            Response
          </span>
          <span className={`api-text0${tab === "save_response" ? "5" : "3"}`} onClick={() => handle_tab_click("save_response")}>
            Save Response
          </span>
        </div>
        <span className="api-text07" onClick={HandleTestApiEndpoint}>test api endpoint</span>
      </div>

      <Select_variable open={insert_var} onClose={Handle_Modal_Close} variables={variables} onChange={Handle_Select_Change}/>

      {components[tab]}
    </div>
  );
};

export default APIDrawer;
