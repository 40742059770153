import React, {useState, useEffect} from "react";
import {Select, Modal} from "antd";
import "./select_variable.css";
import {useRecoilState} from "recoil";
import {flow_data_state} from "../../../atom";

const Select_variable = ({value, size, open, onChange, variables, onClose}) => {
  const [visible, setVisible] = useState(false);
  const [tab, setTab] = useState("custom_variables");
  const [flow_data, setFlow_data] = useRecoilState(flow_data_state);

  const params = variables.params
  const system_variables = variables.filter((variable) => variable.is_system_variable);
  const custom_variables = variables.filter((variable) => !variable.is_system_variable);

  const reformatted_variables = {
    system_variables,
    custom_variables,
    params,
  };

  const HandleShowAddVariable = () => {
    setVisible(!visible);
  };

  if (!size) {
    size = "100%";
  }

  const handle_tab_click = (tab) => {
    setTab(tab);
  };

  useEffect(() => {
    const handlePageUnload = () => {
      setVisible(false);
    };
    // Attach the event listener when the component is mounted
    window.addEventListener("beforeunload", handlePageUnload);

    // Remove the event listener when the component is unmounted to avoid memory leaks
    return () => {
      window.removeEventListener("beforeunload", handlePageUnload);
    };
  }, []);

  return (
    <Modal title={"Select Variable"} open={open} onCancel={onClose} destroyOnClose={true} footer={null}>
      <div className="selectvariable-body1">
        <div className="selectvariable-variabletypes">
          <span className={`selectvariable-text${tab === "system_variables" ? "" : "1"}`} onClick={() => handle_tab_click("system_variables")}>
            System Variables
          </span>
          <span className={`selectvariable-text${tab === "custom_variables" ? "" : "1"}`} onClick={() => handle_tab_click("custom_variables")}>
            Custom Variables
          </span>
          {flow_data.type === "Plugin" && (
            <span className={`selectvariable-text${tab === "params" ? "" : "1"}`} onClick={() => handle_tab_click("params")}>
              Params
            </span>
          )}
        </div>
        <div className="selectvariable-variablevalues">
          <Select open placeholder={"select variable"} showSearch={true} value={value} style={{width: "100%", marginLeft: 10}} dropdownStyle={{maxHeight: "auto", overflowY: "auto"}} onChange={(value) => onChange(value)}>
            {reformatted_variables[tab].map((option) => (
              <Select.Option key={option.name} value={option.name}>
                {option.name}
              </Select.Option>
            ))}
          </Select>
        </div>
      </div>
    </Modal>
  );
};

export default Select_variable;
