import React, {useState} from 'react'
import { Link } from 'react-router-dom'
import {login_validator} from "../utils/validator"
import {login} from "../apis/index"
import { Helmet } from 'react-helmet'
import {message as toast} from 'antd'
import {Button} from "antd"
import { Form, Input } from 'antd';

import './login.css'

const Login = (props) => {
const [email, setEmail] = useState("")
const [password, setPassword] = useState("")
const [loading, setLoading] = useState(false)

const handleSubmit = async (e) => {
  setLoading(true)
  const {value, error} = login_validator.validate({email, password})
  if(error){
    setLoading(false)
    return toast.error(error.details[0].message)
  }
  await login(JSON.stringify(value))
  setLoading(false)
}

  return (
    <div className="login-container">
      <Helmet>
        <title>login - 50Chats</title>
        <meta property="og:title" content="login - wapi" />
      </Helmet>
      <div className="login-container1">
        <h2 className="login-text">50Chats</h2>
        <Link to="/signup" className="login-navlink button">
          Signup
        </Link>
      </div>
      
      <div className="login-container2">
        <div className="login-container3">
          <div className="login-container4">
            <h3>Login</h3>
          </div>
          <input
            type="email"
            required
            value={email}
            placeholder="Email address"
            autoComplete="email"
            className="login-textinput input"
            onChange={(e) => {
              setEmail(e.target.value.trim())
            }}
          />
          <input
            type="password"
            required
            value={password}
            placeholder="Password"
            className="login-textinput1 input"
            onChange={(e) => {
              setPassword(e.target.value.trim().toString())
            }}
          />
          <div className="login-container5">
            <span>Forgot password,  </span>
            <Link  to="/changepassword" className="login-text3">Click here to reset</Link>
          </div>
          <Button className="login-button button" onClick={handleSubmit} loading={loading} >Login </Button>
          
        </div>


      </div>
    </div>
  )
}

export default Login
