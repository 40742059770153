import {Link} from "react-router-dom";
import {message as toast, Dropdown, Popconfirm} from "antd";
import {MoreOutlined} from "@ant-design/icons";
import {useHistory} from "react-router-dom";
import {get_bot} from "../../../apis/index"
import {v4 as uuidv4} from "uuid";

const TriggerItem = ({trigger, chatbot, setChatbot, create_node, delete_node, HandleEditNode, HandleDeleteNode}) => {
  const history = useHistory()
  function get_flow_name(target_id) {
    const flow = chatbot.flows.find((flow) => flow.id === target_id);
    return flow ? flow.name : "----------";
  }

  const on_load_get_bot = async (bot_id) => {
    let bot = await get_bot(bot_id);
    if (bot) {
      setChatbot(bot);
    }
  };
  
  const HandleCreateFlow= async () => {
    let new_node = {}
    new_node["type"] = "Flow";
    new_node["name"] = "Untitled";
    new_node["bot_id"] = chatbot._id;
    new_node["last_modified"] = new Date().toISOString();
    new_node["id"] = `flow-${uuidv4().replace(/-/g, "")}`;
    toast.info("creating new flow")
    let response = await create_node(JSON.stringify(new_node));
    if (response) {
      await create_node(JSON.stringify({"id": trigger.id, "target_id": new_node['id']}))
      toast.success(`Flow Created`);
      on_load_get_bot(chatbot._id);
    }
  };


  

  const Handle_Delete_Trigger = async () => {
    let id_to_be_deleted = trigger.id;
    let response = await delete_node(id_to_be_deleted);
    if (response) {
      toast.success("Trigger deleted successfully");
      chatbot.triggers = chatbot.triggers.filter((trigger) => trigger.id !== id_to_be_deleted);
      setChatbot({...chatbot});
    }
};


  // const more_buttons = [
  //   {
  //     label: "Edit",
  //     key: "edit",
  //   },
  //   {
  //     label: "Delete",
  //     key: "delete",
  //     danger: true
  //   },
  // ];


  const more_buttons = [
    {
      key: 'edit',
      label: (
        
        <a onClick={() =>HandleEditNode(trigger)}>
          Edit
        </a>
      ),
    },
    {
      key: 'delete',
      label: (

        <Popconfirm
        title="Delete Trigger"
        description="Are you sure you want to delete the selected trigger?"
        onConfirm={Handle_Delete_Trigger}
        okText="Yes"
        cancelText="No"
      >
        <a>
          Delete
        </a>
          </Popconfirm>

      ),
      danger: true
    },
  ]

  const Navigate_to_flow = () => {
    const to = `/${chatbot._id}/flowchart/${trigger.target_id}`;
    history.push(to);
  };


  return (
    <div className="chatbot-triggeritem1">
      <div
        className="chatbot-container26"
        onClick={() => {
          let isActive = trigger.isActive;
          trigger.isActive = !isActive;
          create_node(JSON.stringify(trigger));
          setChatbot({...chatbot});
        }}
      >
        {trigger.isActive ? (
          <svg viewBox="0 0 1170.2857142857142 1024" className="chatbot-icon12">
            <path d="M0 512c0-201.714 164-365.714 365.714-365.714h438.857c201.714 0 365.714 164 365.714 365.714s-164 365.714-365.714 365.714h-438.857c-201.714 0-365.714-164-365.714-365.714zM804.571 804.571c161.143 0 292.571-131.429 292.571-292.571s-131.429-292.571-292.571-292.571-292.571 131.429-292.571 292.571 131.429 292.571 292.571 292.571z"></path>
          </svg>
        ) : (
          <svg viewBox="0 0 1170.2857142857142 1024" className="chatbot-icon10">
            <path d="M658.286 512c0-161.143-131.429-292.571-292.571-292.571s-292.571 131.429-292.571 292.571 131.429 292.571 292.571 292.571 292.571-131.429 292.571-292.571zM1097.143 512c0-161.143-131.429-292.571-292.571-292.571h-220.571c89.143 66.857 147.429 173.143 147.429 292.571s-58.286 225.714-147.429 292.571h220.571c161.143 0 292.571-131.429 292.571-292.571zM1170.286 512c0 201.714-164 365.714-365.714 365.714h-438.857c-201.714 0-365.714-164-365.714-365.714s164-365.714 365.714-365.714h438.857c201.714 0 365.714 164 365.714 365.714z"></path>
          </svg>
        )}
      </div>
      <div className="chatbot-container27">
        <span className="chatbot-text20">{trigger.name}</span>
      </div>
      <div className="chatbot-container28">
        {trigger.target_id ? (
        <span className="chatbot-text21" style={{cursor:"pointer"}} onClick={Navigate_to_flow}>{get_flow_name(trigger.target_id)} </span>
        ):(
          <>
          <span className="chatbot-text21" style={{cursor:"pointer", color: "#0074F0", "fontSize": "14px"}} onClick={() =>  HandleEditNode(trigger)}>Select Existing</span>
          <span style={{fontSize: "14px", marginRight: "8px", marginLeft: "8px"}}>Or</span>
          <span style={{cursor: "pointer", color: "#0074F0", fontSize: "14px"}} onClick={HandleCreateFlow}>Create New</span>
          </>
        )}
      </div>
      <div className="chatbot-container29">
        <Dropdown
          menu={{
            items: more_buttons
          }}
          trigger={['click']}
        >
          <MoreOutlined style={{fontSize: "30px"}} onClick={(e) => e.preventDefault()} />
        </Dropdown>

      </div>
    </div>
  );
};

export default TriggerItem;
