import React, {useState} from "react";
import {DeleteOutlined} from "@ant-design/icons";
import {Button, DatePicker, Input, Select} from "antd";
import moment from "moment";

const FilterItem = ({item, deleteFilter, onUpdateFilter, variables}) => {
  const [rightBox, setRightBox] = useState({
    type: "input",
    options: ["Standard reply"],
  });
  // const [middleBox, setMiddleBox] = useState({
  //   type: "select",
  //   options: ["Run", "Don't run"],
  // });

  const [middleBox, setMiddleBox] = useState({
    type: "input",
    options: ["is", "is not", "contains", "doesn't contain", "is empty", "is not empty", "is name", "is email"],
  });


  const options = variables.map((variable) => ({
    value: variable.name,
    label: variable.name,
  }));

  const onChangeFirstBox = (e) => {
    onUpdateFilter(e, "", "", item);
    setRightBox({
      ...rightBox,
      type: "input",
    });
    // switch (e) {
    //   case "Name":
    //     setMiddleBox({
    //       ...middleBox,
    //       options: ["contains"],
    //     });
    //     setRightBox({
    //       ...rightBox,
    //       type: "input",
    //     });
    //     break;
    //   case "Tags":
    //     setMiddleBox({
    //       ...middleBox,
    //       options: ["Tag assigned", "Tag unassigned"],
    //     });
    //     setRightBox({
    //       ...rightBox,
    //       type: "select",
    //       options: ["Select criteria"],
    //     });
    //     break;
    //   case "Was in flow":
    //     setMiddleBox({
    //       ...middleBox,
    //       options: ["Run", "Don't run"],
    //     });
    //     setRightBox({
    //       ...rightBox,
    //       type: "select",
    //       options: ["Select criteria", "Standard reply", "Welcome message"],
    //     });
    //     break;
    //   case "Last activity":
    //     setMiddleBox({
    //       ...middleBox,
    //       options: ["after", "before", "between"],
    //     });
    //     setRightBox({
    //       ...rightBox,
    //       type: "date",
    //     });
    //     break;
    //   default:
    //     return;
    // }
  };

  const onChangeSecondBox = (e) => {
    onUpdateFilter("", e, "", item);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        rowGap: "8px",
        background: "#eee",
        padding: "8px",
        borderRadius: "8px",
        marginTop: "12px",
      }}
    >
      <Select onSelect={onChangeFirstBox} style={{width: "100%"}} dropdownStyle={{minWidth: "100px"}} defaultValue={item.criteria1} options={options} />
      <div
        style={{
          display: "flex",
          columnGap: "8px",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Select
          style={{width: "45%"}}
          defaultValue={item.condition}
          onChange={onChangeSecondBox}
          options={[
            ...middleBox.options.map((item) => ({
              lebel: item,
              value: item,
            })),
          ]}
        />
        {rightBox.type === "input" && <Input onChange={(e) => onUpdateFilter("", "", e.target.value, item)} defaultValue={item.criteria2} style={{width: "40%"}} />}
        {rightBox.type === "select" && (
          <Select
            onChange={(e) => onUpdateFilter("", "", e, item)}
            style={{width: "45%"}}
            defaultValue={item.criteria2}
            options={[
              ...rightBox.options.map((item) => ({
                lebel: item,
                value: item,
              })),
            ]}
          />
        )}
        {rightBox.type === "date" && <DatePicker defaultValue={item.criteria2} onChange={(e) => onUpdateFilter("", "", e.$d, item)} style={{width: "45%"}} />}

        <DeleteOutlined onClick={deleteFilter} style={{cursor: "pointer", fontSize: "20px"}} />
      </div>
    </div>
  );
};

export default FilterItem;
