import {Button, Select} from "antd";
import React, {useEffect, useState} from "react";
import "./Drawer.css";

const FlowDrawer = ({node, onNodeChange, onNodeDelete, loading}) => {

  const [flow_id, setFlow_id] = useState("")
  const [flows, setFlows] = useState([])
  useEffect(() => {
    let flows_st = localStorage.getItem("flows")
    flows_st = JSON.parse(flows_st)
    setFlows(flows_st)
    setFlow_id(node.data.flow_id)
  }, [node.id]);


  const HandleFlowChange = (e)=> {
    setFlow_id(e)
  }
  

  useEffect(() => {
    const updatedNode = {
      ...node,
      data: {flow_id},
    };
    onNodeChange(updatedNode);
}, [flow_id]);

  return (
    <div className="flow-drawer-container">
      <div>
        <h3>Start another flow</h3>
        <Select placeholder="Choose a flow" defaultOpen showSearch optionFilterProp="children" value={flow_id} filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}  style={{width: "100%"}} onChange={HandleFlowChange}>
          {flows.map((option) => (
            <Select.Option key={option.id} value={option.id}>
              {option.name}
            </Select.Option>
          ))}
        </Select>
      </div>

    </div>
  );
};


export default FlowDrawer;
