import {Input} from "antd";
import {useCallback, useState} from "react";
import {Handle, Position, useStore} from "reactflow";
import "./FlowNodes.css";

const connectionNodeIdSelector = (state) => state.connectionNodeId;

const ChatgptNode = ({id, data, isConnectable}) => {
  const action_maps = {
    chat_completion: "Chats Completion",
  };

  const actionLabel = action_maps[data.type];

  const handleStyleLeft = {
    top: 18,
    width: 20,
    height: 20,
    left: -10,
    borderColor: "white",
    borderWidth: "3.5px",
    boxShadow: "0 8px 8px rgba(0,0,0,.1)",
    backgroundColor: "rgb(56, 135, 255)",
  };
  const handleStyleRight = {
    top: 18,
    width: 20,
    height: 20,
    right: -10,
    borderColor: "white",
    borderWidth: "3.5px",
    boxShadow: "0 8px 8px rgba(0,0,0,.1)",
    backgroundColor: "rgb(56, 135, 255)",
  };
  const connectionNodeId = useStore(connectionNodeIdSelector);
  const isTarget = connectionNodeId && connectionNodeId !== id;

  const [hover, setHover] = useState(false);

  const handleMouseEnter = () => {
    setHover(true);
  };

  const handleMouseLeave =  () => {
    setHover(false);
  };

  const HandleDuplicate = useCallback((e) => {
    e.click_type = "duplicate"
  })

  const HandleDelete = (e) => {
    e.click_type = "delete"
  }


  return (
    <div style={{flex: 1}} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <div
        style={{
          borderWidth: "2px",
          borderColor: isTarget && hover ? "rgb(56, 135, 255)" : "transparent",
        }}
        className="action-node"
      >
        <div className="chatgpt-node-header">
          <p style={{color: "white"}}>Chatgpt</p>
        </div>
        <div className="action-node-container">
          <p className="placeholder" style={{marginTop: "8px"}}>
            {actionLabel}
          </p>
          <Handle
            type="target"
            position={Position.Left}
            id="z"
            style={{
              position: "absolute",
              bottom: 0,
              width: "100%",
              height: "100%",
              zIndex: isTarget ? 50 : -1,
              backgroundColor: "transparent",
              borderRadius: 0,
              opacity: 0,
            }}
            isConnectable={isConnectable}
          />
        </div>
        <Handle type="target" position={Position.Left} id="a" style={handleStyleLeft} isConnectable={isConnectable} />
        <Handle type="source" position={Position.Right} style={handleStyleRight} id="b" isConnectable={isConnectable} />
      </div>
      {hover && (
        <div className="bottomaction-button-container">
          <div className="bottomaction-btns">
            <div className="bottomaction-delete" onClick={HandleDelete}>
              <svg viewBox="0 0 1024 1024" className="bottomaction-icon">
                <path d="M128 320v640c0 35.2 28.8 64 64 64h576c35.2 0 64-28.8 64-64v-640h-704zM320 896h-64v-448h64v448zM448 896h-64v-448h64v448zM576 896h-64v-448h64v448zM704 896h-64v-448h64v448z"></path>
                <path d="M848 128h-208v-80c0-26.4-21.6-48-48-48h-224c-26.4 0-48 21.6-48 48v80h-208c-26.4 0-48 21.6-48 48v80h832v-80c0-26.4-21.6-48-48-48zM576 128h-192v-63.198h192v63.198z"></path>
              </svg>
            </div>
            <div className="bottomaction-copy" onClick={HandleDuplicate}>
              <svg viewBox="0 0 1024 1024" className="bottomaction-icon3">
                <path d="M810 896v-598h-468v598h468zM810 214q34 0 60 25t26 59v598q0 34-26 60t-60 26h-468q-34 0-60-26t-26-60v-598q0-34 26-59t60-25h468zM682 42v86h-512v598h-84v-598q0-34 25-60t59-26h512z"></path>
              </svg>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatgptNode;
