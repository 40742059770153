import React from "react";


const Convert_Object_To_TreeData = ({data}) => {
    let reformatted_data = {};

    if (data) {
      try {
        reformatted_data = JSON.parse(data);
      } catch (err) {
        // Handle JSON parse error
        console.error("Error parsing JSON:", err);
      }
    } else {
      // Handle when data is undefined
      console.warn("Data is undefined.");
    }

  function convertObjectToTreeData(obj, parentKey = "") {
    const titleStyle = {
      display: "flex",
    };
    const keyStyle = {
      background: "#705CF6",
      color: "white",
      paddingLeft: 5,
      paddingRight: 5,
      borderRadius: 5,
      marginRight: 5,
    };

    if (typeof obj !== "object" || obj === null) {
      return {
        title: (
          <div style={keyStyle}>
            {parentKey}: {obj}
          </div>
        ),
        key: `$['${parentKey}']`,
      };
    }

    return Object.keys(obj).map((key) => {
      const newKey = parentKey ? `${parentKey}['${key}']` : key;
      const fullKey = `$['${newKey}']`;

      if (Array.isArray(obj[key])) {
        return {
          title: <div style={keyStyle}>{key} [ ]</div>,
          key: fullKey,
          children: obj[key].map((item, index) => {
            const itemKey = `${newKey}[${index}]`;
            return {
              title: (
                <div style={keyStyle}>
                  {key} [{index}]
                </div>
              ),
              key: `$['${itemKey}']`,
              children: convertObjectToTreeData(item, itemKey),
            };
          }),
        };
      }

      if (typeof obj[key] === "object") {
        return {
          title: <div style={keyStyle}>{key}</div>,
          key: `$['${newKey}']`,
          children: convertObjectToTreeData(obj[key], newKey),
        };
      }

      return {
        title: (
          <div style={titleStyle}>
            <div style={keyStyle}>{key}:&nbsp;</div>
            <span>{obj[key]}</span>
          </div>
        ),
        key: `$['${newKey}']`,
      };
    });
  }

  const treeData = convertObjectToTreeData(reformatted_data, ""); // Passing an empty string as the root key

  return treeData
};

export default Convert_Object_To_TreeData