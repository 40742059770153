import {Button, Input, Modal, Select, message as toast, Tabs} from "antd";
import Select_variable from "../buttons/select_variable";
import Headers from "./body_component"
import {useState, useRef, useEffect} from "react";
import FloatingButton from "../buttons/FloatingButton";

const Body = ({variables, body, setBody}) => {
  const [show, setShow] = useState(false);
  const [isValidJSON, setIsValidJSON] = useState(true); // Added state for JSON validity
  const textareaRef = useRef(null);

  const Handle_Modal_Close = () => {
    setShow(false);
  };

  useEffect(() => {
    try {
      JSON.parse(body); // Attempt to parse the body as JSON
      setIsValidJSON(true); // JSON is valid
    } catch (error) {
      setIsValidJSON(false); // JSON is not valid
    }
  }, [body]);

  const handleInsertClick = (selectedValue) => {
    const textarea = document.getElementById("myTextarea");
    const {selectionStart, selectionEnd, value} = textarea;
    const newValue = value.substring(0, selectionStart) + "{{" + selectedValue + "}}" + value.substring(selectionEnd);
    textarea.value = newValue;
    setBody(newValue);
    textarea.setSelectionRange(selectionStart + selectedValue.length, selectionStart + selectedValue.length);
    setShow(false);
  };

  const items = [
    {
      key: "assisted",
      label: "Assisted Json Input",
      children: <Headers variables={variables} body={body} setBody={setBody} />
    },
    {
      key: "manual",
      label: "Manual Json Input",
      children: (
        <div className="api-bodycontainer">
        <Input.TextArea
            id="myTextarea"
            value={body}
            ref={textareaRef}
            onChange={(e) => {
              const text = e.target.value;
              setBody(text);
            }}
            autoSize={{minRows: 6}}
            style={{height: "500px", borderColor: isValidJSON ? "initial" : "red"}}
          />
          {isValidJSON ? null : <div style={{color: "red"}}>Invalid JSON - Please check your JSON syntax.</div>}

          <FloatingButton onClick={() => setShow(true)} />
          </div>
      ),
    }
  ];

  return (
    <div className="api-bodycontainer">
      {show && <Select_variable open={show} onClose={Handle_Modal_Close} variables={variables} onChange={handleInsertClick} />}
      <Tabs style={{width: "100%"}} defaultActiveKey="1" items={items} />
    </div>
  );
};

export default Body;
