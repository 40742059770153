import React, {useEffect, useState} from "react";
import dayjs from "dayjs";
import {TimePicker} from "antd";

export default function TimingModal({showModal, setShowModal, sequence, setSequence, currentSequence}) {
  const [seq, setSeq] = useState("");
  const [timing, setTiming] = useState("");

  useEffect(() => {
    let filter = sequence.filter((item) => item["id"] == currentSequence);
    if (filter && filter?.length == 1) {
      setSeq(filter[0]);
      setTiming(filter[0].send_after);
    }
  }, [currentSequence]);

  const updateFinalData = (id) => {
    const newArray = [...sequence];
    const index = newArray.findIndex((obj) => obj?.id === id);
    if (index !== -1) {
      newArray[index] = {
        ...newArray[index],
        ...{send_after: {value: timing["value"], unit: timing["unit"], at: timing.at}},
      };
    }
    setSequence(newArray);
  };

  const handleClick = () => {
    updateFinalData(currentSequence);
    setShowModal(false);
  };

  return (
    <>
      {showModal ? (
        <>
          <div className="modal justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-xl ">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-gray-100 outline-none focus:outline-none">
                {/*header*/}
                {/* <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-3xl font-semibold">
                    Modal Title
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-gray-500 text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div> */}
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <p className="my-4 text-blueGray-500 text-lg leading-relaxed">This Message Will be sent exactly at</p>
                  {(timing && timing.unit != "immediately") && (
                    <input
                      type="number"
                      className="outline-none h-[5vh] border p-4 rounded-md mr-6"
                      value={timing && timing.value}
                      onChange={(e) =>
                        setTiming((timing) => ({
                          ...timing,
                          value: e.target.value,
                        }))
                      }
                      min={1}
                    />
                  )}
                  <select
                    name=""
                    id=""
                    value={timing && timing.unit}
                    onChange={(e) =>
                      setTiming((timing) => ({
                        ...timing,
                        unit: e.target.value,
                      }))
                    }
                    className="w-[200px] border h-[5vh] pl-4 rounded-md outline-none"
                  >
                    <option value={"immediately"}>Immediately</option>
                    <option value={"minute"}>Minutes</option>
                    <option value={"hour"}>Hours</option>
                    <option value={"day"} defaultChecked>
                      Days
                    </option>
                  </select>
                </div>
                {(timing && timing.unit == "day") && (
                  <>
                    <h2 className="mb-4">Send At</h2>
                    <div className="w-[100%] h-[1px] bg-gray-300" />
                    <div>
                    <TimePicker onChange={(time, timestring) => {  
                      setTiming((timing) => ({
                        ...timing,
                        at: timestring,
                      }))}} size="small" value={dayjs(timing.at, "HH:mm")} format={"HH:mm"} />
                    </div>
                  </>
                )}

                {/* <select
                  name=""
                  id=""
                  className="m-auto w-[80%] my-4 border h-[5vh] rounded-md text-center px-10 outline-none"
                >
                  <option defaultChecked>Any Day</option>
                  <option>Hours</option>
                </select> */}

                <div className="flex items-center justify-center p-6 border-t border-solid border-blueGray-200 rounded-md">
                  <button className="bg-sky-500 text-white active:bg-sky-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" onClick={handleClick}>
                    Save And Update Schedule
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
