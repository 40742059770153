import Joi from "joi"


const signup_validator = Joi.object({
    email:  Joi.string()
    .trim()
    .required()
    .lowercase(),
    first_name:  Joi.string().trim().required(),
    last_name:  Joi.string().trim().required(),
    password: Joi.string().trim().required(),
    confirm_password: Joi.string()
    .trim()
    .required(),
    timezone: Joi.string().trim().required(),
});

const login_validator =  Joi.object({
    email:  Joi.string()
    .trim()
    .required()
    .lowercase(),
    password: Joi.string().trim().required(),
});

export {
    signup_validator,
    login_validator
}