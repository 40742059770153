
import TriggerItem from "./trigger_item"

const Trigger = ({chatbot, delete_node, create_node, setChatbot, HandleEditNode}) => {
  return (
    <div className="chatbot-triggercontainer">
      <div className="chatbot-triggeritem">
        <div className="chatbot-container23">
          <span className="chatbot-text16">Status</span>
        </div>
        <div className="chatbot-container24">
          <span className="chatbot-text17">Name</span>
        </div>
        <div className="chatbot-container25">
          <span className="chatbot-text18">Target flow</span>
        </div>
      </div>

      {chatbot.triggers.length == 0 && (
        <div className="chatbot-emptytrigger">
          <span className="chatbot-text19">No Data</span>
        </div>
      )}

      {chatbot.triggers.map((trigger) => (
        <TriggerItem key={trigger.id} chatbot={chatbot} delete_node={delete_node} create_node={create_node} setChatbot={setChatbot} trigger={trigger} HandleEditNode={HandleEditNode} />
      ))}
    </div>
  );
};

export default Trigger;
