import React, {useState, useEffect, useRef} from "react";
import {Button, Input, Radio, Space, Select, message as toast, Form} from "antd";
import {get_bot} from "../../../apis/index";
import CustomSelect from "../buttons/custom_select";
import Select_variable from "../../component/buttons/select_variable";

import "./Drawer.css";

const ActionDrawer = ({onNodeChange, node, onNodeDelete, loading, variables, setVariables, bot_id}) => {
  const textareaRef = useRef();
  const [message, setMessage] = useState("");
  const [showAddvariable, setShowAddvariable] = useState(false);
  const [sequences, setSequences] = useState([]);
  const [data, setData] = useState({});

  const Handle_Select_Sequence = (id) => {
    setData({...data, sequence_id: id})
  };

  const HandleShowAddVariable = (e) => {
    setShowAddvariable(!showAddvariable);
  };

  const handleInput = (e) => {
    setMessage(e.target.value);
  };

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
    
    const get_sequence = async () => {
      let bot = await get_bot(bot_id);
      if (bot) {
        setSequences(bot.sequences);
      }
    };
    get_sequence();

    setData(node.data)
    
  }, [node.id]);

  const onChange = (e) => {
    setData({...data, type: e.target.value});
  };

  const handleInsertClick = (selectedValue) => {
    const textarea = document.getElementById("myTextarea");
    const {selectionStart, selectionEnd, value} = textarea;
    const newValue = value.substring(0, selectionStart) + "{{" + selectedValue + "}}" + value.substring(selectionEnd);
    textarea.value = newValue;
    setMessage(newValue);
    textarea.setSelectionRange(selectionStart + selectedValue.length, selectionStart + selectedValue.length);
    setShowAddvariable(false);
  };

  useEffect(() => {
    const updatedNode = {
      ...node,
      data,
    };
    onNodeChange(updatedNode);
  }, [data]);

  return (
    <div className="action-drawer-container">
      <h3>Choose an action</h3>
      <Radio.Group style={{width: "100%", paddingBottom: "64px"}} onChange={onChange} value={data.type}>
        <Space style={{width: "100%"}} direction="vertical">
          <div className="list-item">
            <Radio value={"hand_over_to_human"}>Hand Over to Human</Radio>
            {/* Hold bot automation for one hour */}
          </div>
          <div className="list-item">
            <Radio value={"resume_automation"}>Resume Automation</Radio>
            {/* Hold bot automation for one hour */}
          </div>

          <div className="list-item">
            <Radio value={"notify_admin"}>Notify Admin</Radio>
            <p className="para">Notify all admins via email</p>

            {data.type == "notify_admin" && (
              <div style={{marginLeft: "1.5rem", marginTop: "12px"}}>

                <p className="para1">Message</p>
                <Input.TextArea id="myTextarea" ref={textareaRef} onChange={(e) => setData({...data, message: e.target.value})} autoSize={{minRows: 5, maxRows: 18}} value={data.message} placeholder="Enter your message" />

                <div style={{height: 30, display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "flex-start"}}>
                  <div style={{backgroundColor: "black", cursor: "pointer", height: "100%", width: 40, display: "flex", justifyContent: "center", alignItems: "center", borderRadius: 8}} onClick={HandleShowAddVariable}>
                    <span style={{color: "white", fontSize: 18}}>{"{ }"}</span>

                    
                  </div>
                </div>

                <Select_variable open={showAddvariable} onClose={() => setShowAddvariable(false)} variables={variables} onChange={handleInsertClick} />

              </div>
            )}
          </div>

          <div className="list-item">
            <Radio value={"unsubscribe"}>Unsubscribe from the bot</Radio>
            <p className="para">Unsubscribe a user from bulk and automated messages</p>
          </div>

          <div className="list-item">
            <Radio value={"subscribe_to_sequence"}>Subscribe user to a Sequence</Radio>
            {data.type == "subscribe_to_sequence" && (
              <Select placeholder="Select a Sequence" showSearch optionFilterProp="children" value={data.sequence_id} style={{width: "100%"}} onChange={Handle_Select_Sequence}>
                {sequences.map((option) => (
                  <Select.Option key={option.id} value={option.id}>
                    {option.name}
                  </Select.Option>
                ))}
              </Select>
            )}
          </div>

          <div className="list-item">
            <Radio value={"save_variable"}>Save variable</Radio>
            <p className="para">Assign a variable to a user</p>

            {data.type == "save_variable" && (
              <div style={{marginLeft: "1.5rem", marginTop: "12px"}}>
                <Form>
                  <Form.Item label="Save as" style={{marginBottom: "15px"}}>
                    <CustomSelect value={data.save_as} variables={variables} setVariables={setVariables} onChange={(value) => setData({...data, save_as: value})} />
                  </Form.Item>

                  <Form.Item label="Value" style={{marginBottom: "15px"}}>
                    <Input value={data.value} onChange={(e) => setData({...data, value: e.target.value})}></Input>
                  </Form.Item>
                </Form>
              </div>
            )}

          </div>
        </Space>
      </Radio.Group>
    </div>
  );
};

export default ActionDrawer;

