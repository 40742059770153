export const ActionTypes = {
    NODE_DRAG_START: "NODE_DRAG_START",
    NODE_DRAG_MOVE: "NODE_DRAG_MOVE",
    NODE_DRAG_END: "NODE_DRAG_END",

    API_NODE_DRAG_MOVE: "API_NODE_DRAG_MOVE",

    DOT_DRAG_START: "DOT_DRAG_START",
    DOT_DRAG_MOVE: "DOT_DRAG_MOVE",
    DOT_DRAG_END: "DOT_DRAG_END",

    ON_TEMP_LINE_DRAW: "ON_TEMP_LINE_DRAW",
    ON_LINE_DRAW: "ON_LINE_DRAW",
    ON_NODE_ADD: "ON_NODE_ADD",

    ON_MOUSE_UP: "ON_MOUSE_UP",
    ON_SIZE_CHANGE: "ON_SIZE_CHANGE",
    ON_NODE_CHANGE: "ON_NODE_CHANGE",

    ON_MOUSE_ENTER: "ON_MOUSE_ENTER",
    ON_MOUSE_LEAVE: "ON_MOUSE_LEAVE",
    ON_EDGE_ADD: "ON_EDGE_ADD",
    ON_CHANGE_NODE: "ON_CHANGE_NODE",
    ON_NEW_NODE: "ON_NEW_NODE",
    ON_DELETE_NODE: "ON_DELETE_NODE",
    ON_UPDATE_NODE: "ON_UPDATE_NODE",
    ON_HANDLE_DRAG_END: "ON_HANDLE_DRAG_END",
}