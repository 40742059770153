import React, {useState, useRef, useEffect} from "react";
import {message as toast} from "antd";
import FloatingButton from "../buttons/FloatingButton";
import SelectVariable from "../buttons/select_variable";

const Headers = ({body, setBody, variables}) => {
    const [headers, setHeaders] = useState([])
  const [showFloats, setShowFloats] = useState(Array(headers.length).fill(false));
  const [current_index, setCurrentIndex] = useState(null);


  const convertToObjectArray = (obj) => {
    try {
      const result = [];

      for (const key in obj) {
        if (Object.hasOwnProperty.call(obj, key)) {
          const value = obj[key];
          const newObj = { key: key, value: value };
          result.push(newObj);
        }
      }
      return result;
    } catch (error) {
      console.error("An error occurred:", error);
      return [];
    }
  };


  const convertTextToJSON = (text) => {
    try {
      const jsonObject = JSON.parse(text);
      return jsonObject;
    } catch (error) {
      console.error("An error occurred while parsing JSON:", error);
      return {}; // Return an empty object in case of an error
    }
  };


  const convertArrayToText = () => {
    const convertedObj = {};
    const arr = headers
    arr.forEach(obj => {
      const keys = Object.keys(obj);
      if ('key' in obj && 'value' in obj) {
        convertedObj[obj.key] = obj.value;
      } else {
        // If 'key' and 'value' attributes are missing, handle the object differently
        for (const key of keys) {
          convertedObj[key] = obj[key];
        }
      }
    });
    return JSON.stringify(convertedObj);
  };




  useEffect(() => {
    const obj = convertTextToJSON(body)
    const array_of_headers = convertToObjectArray(obj)
    setHeaders(array_of_headers)
  }, []);


  useEffect(() => {
    const convertedText = convertArrayToText();
    setBody(convertedText)
  }, [headers]);






  const hasDuplicateKeys = (objects) => {
    const keySet = new Set();
    for (const object of objects) {
      if (keySet.has(object.key)) {
        return true;
      }
      keySet.add(object.key);
    }
    return false; // No duplicate keys found
  };



  const handleModalClose = (index) => {
    const updatedShowFloats = [...showFloats];
    updatedShowFloats[index] = false;
    setShowFloats(updatedShowFloats);
    setCurrentIndex(null)
  };

  const addNewHeader = () => {
    if (hasDuplicateKeys(headers)) {
      return toast.error("Key already exists in the headers");
    }

    const allHeadersValid = headers.every((header) => header.key.trim() !== "" && header.value.trim() !== "");

    if (!allHeadersValid) {
      return toast.error("All Items must have a valid key and value");
    }

    const header = {
      key: "",
      value: "",
    };
    setHeaders([...headers, header]);
  };

  const deleteHeader = (index) => {
    const updatedHeaders = headers.filter((_, i) => i !== index);
    setHeaders(updatedHeaders);
  };

  const handleSelectHeaderFocus = (index) => {
    setCurrentIndex(index);
   
  };

  const handleSelectInHeaderChange = (value, index) => {
    const updatedHeaders = [...headers];
    updatedHeaders[index].value += `{{${value}}}`;
    setHeaders(updatedHeaders);
    handleModalClose(index)
  };

  const onHeaderValueChange = (value, index = current_index) => {
    const updatedHeaders = [...headers];
    updatedHeaders[index].value = value;
    setHeaders(updatedHeaders);
  };

  const onHeaderKeyChange = (value, index) => {
    const updatedHeaders = [...headers];
    updatedHeaders[index].key = value;
    setHeaders(updatedHeaders);
  };

  const handleBlur = (index) => {
    setTimeout(() => {
      setCurrentIndex(null);
    }, 1000);
  };

  const handleFloatButtonClick = (index) => {
    const updatedShowFloats = [...showFloats];
    updatedShowFloats[index] = true;
    setShowFloats(updatedShowFloats);
    setCurrentIndex(index)
  };

  
  const delete_header = (index) => {
    const updatedHeaders = [...headers];
    updatedHeaders.splice(index, 1);
    setHeaders(updatedHeaders);
  };


  return (
    <div className="api-headerscontainer">
      <div className="api-headerlabel">
        <div className="api-method1">
          <span className="api-text08">Key</span>
        </div>
        <div className="api-endpoint1">
          <span className="api-text09">Value</span>
        </div>
      </div>
      {headers.map((header, index) => (
        <div className="api-header" key={index}>
          <div className="api-method2">
            <input type="text" value={header.key} onChange={(e) => onHeaderKeyChange(e.target.value, index)} className="api-textinput2 input" />
          </div>
          <div className="api-endpoint2">
            <input type="text" value={header.value}  className="api-textinput3 input" onChange={(e) => onHeaderValueChange(e.target.value, index)} onFocus={() => handleSelectHeaderFocus(index)} onBlur={() => handleBlur(index)} />
            {current_index === index && <FloatingButton onClick={() => handleFloatButtonClick(index)} />}
            <SelectVariable
              open={showFloats[index]}
              onClose={() => handleModalClose(index)}
              variables={variables}
              onChange={(value) => {
                handleSelectInHeaderChange(value, index);
              }}
            />
          </div>

          <svg viewBox="0 0 1024 1024" className="api-icon" onClick={() => delete_header(index)}>
            <path d="M128 320v640c0 35.2 28.8 64 64 64h576c35.2 0 64-28.8 64-64v-640h-704zM320 896h-64v-448h64v448zM448 896h-64v-448h64v448zM576 896h-64v-448h64v448zM704 896h-64v-448h64v448z"></path>
            <path d="M848 128h-208v-80c0-26.4-21.6-48-48-48h-224c-26.4 0-48 21.6-48 48v80h-208c-26.4 0-48 21.6-48 48v80h832v-80c0-26.4-21.6-48-48-48zM576 128h-192v-63.198h192v63.198z"></path>
          </svg>
        </div>
      ))}

      <button type="button" className="api-button1 button" onClick={addNewHeader}>
        + New Item
      </button>
    </div>
  );
};

export default Headers;
