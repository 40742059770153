import { useRef } from "react";
import { addEdge, applyNodeChanges } from "reactflow";
import { ActionTypes } from "../constants/actionTypes";

const mapState = {
  nodes: [],
  edges: [],
  activeNodeId: "",
};

const HEIGHT = 150;
const WIDTH = 250;

const flowReducer = (state = mapState, action) => {
  switch (action.type) {
    case ActionTypes.ON_MOUSE_ENTER:
      const { id } = action.payload;
      return {
        ...state,
        activeNodeId: id,
      };
    case ActionTypes.ON_HANDLE_DRAG_END:
      return {
        ...state,
      };
    case ActionTypes.ON_MOUSE_LEAVE:
      return {
        ...state,
        activeNodeId: "",
      };
    case ActionTypes.ON_EDGE_ADD:
      const { conn, edges } = action.payload;
      const newEdges = addEdge(conn, edges);
      return {
        ...state,
        edges: newEdges,
      };
    case ActionTypes.ON_CHANGE_NODE:
      const { changes, nodes } = action.payload;
      const updatedNodes = applyNodeChanges(changes, nodes);
      return {
        ...state,
        nodes: updatedNodes,
      };
    case ActionTypes.ON_NEW_NODE:
      return {
        ...state,
        nodes: [...state.nodes, action.payload.node],
      };
    case ActionTypes.ON_DELETE_NODE:
      const { updatedNodeList } = action.payload;
      return {
        ...state,
        nodes: updatedNodeList,
      };
    case ActionTypes.ON_UPDATE_NODE:
      const { newList } = action.payload;
      return {
        ...state,
        nodes: newList,
      };
    default:
      return {
        ...state,
      };
  }
};

export default flowReducer;
