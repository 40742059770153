
import { format_to_date_time} from "../../utils/helper";

const Contact_detail = ({setSee_contact_detail, contact}) => {
    return(
    <div className="livechat-contact-detail">
    <div className="livechat-chat-header1">
      <h3 className="livechat-text84">Contact Detail</h3>
      <svg
        viewBox="0 0 1024 1024"
        className="livechat-icon24"
        onClick={() => {
          setSee_contact_detail(false);
        }}
      >
        <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
      </svg>
    </div>
    <div className="livechat-chat-body">
      <div className="livechat-container12">
        <img alt="image" src="/user-thumbnail-200w-200w.png" className="livechat-image28" />
        <div className="livechat-container13">
          <h3 className="livechat-text85">{contact && contact.variables.full_name}</h3>
        </div>
      </div>
      <div className="livechat-container14">
        <div className="livechat-container15">
          <span className="livechat-text86">Created</span>
          <span className="livechat-text87">{contact && format_to_date_time(contact.created_on)}</span>
        </div>
        <div className="livechat-container16">
          <span className="livechat-text88">Last seen</span>
          <span className="livechat-text89">{contact && format_to_date_time(contact.last_messaged)}</span>
        </div>
      </div>
    </div>
  </div>
    )
}

export default Contact_detail