import React, {useState, useEffect} from "react";
import {Link, useLocation} from "react-router-dom";

import "./sidebar.css";

const Sidebar = (props) => {
  const location = useLocation();
  const currentPath = location.pathname.split("/")[1];

  const activeButton = `${currentPath}button`;

  useEffect(() => {
    if (activeButton) {
      let element = document.querySelector(`.${activeButton}`);
      element.style.backgroundColor = "#74e1bc";
    }
  }, [activeButton]);

  const handleLogout = async () => {
    console.log("Logout Button Clicked");
    localStorage.clear();
    window.location.reload();
  };

  return (
    <div className="chatbots-sidebar">
      <div className="chatbots-sidebarheader">
        <h3 className="chatbots-logotext">50chats</h3>
      </div>
      <div className="chatbots-sidebaritemcontainer">
        <div className="chatbots-buttons-wrapper">
          <Link to="/home" className="chatbots-navlink">
            <div className="chatbots-sidebaritem homebutton">
              <svg viewBox="0 0 1024 1024" className="chatbots-whatsappicon">
                <path d="M1024 590.444l-512-397.426-512 397.428v-162.038l512-397.426 512 397.428zM896 576v384h-256v-256h-256v256h-256v-384l384-288z"></path>
              </svg>
              <h5 className="chatbots-sidebaritemtext">Home</h5>
            </div>
          </Link>

          <Link to="/chatbots" className="chatbots-navlink">
            <div className="chatbots-sidebaritem chatbotsbutton chatbotbutton">
              <svg viewBox="0 0 1024 1024" className="chatbots-whatsappicon">
                <path d="M873 148.8c-95.8-96-223.2-148.8-359-148.8-279.6 0-507.2 227.6-507.2 507.4 0 89.4 23.4 176.8 67.8 253.6l-72 263 269-70.6c74.2 40.4 157.6 61.8 242.4 61.8h0.2c0 0 0 0 0 0 279.6 0 507.4-227.6 507.4-507.4 0-135.6-52.8-263-148.6-359zM514.2 929.6v0c-75.8 0-150-20.4-214.8-58.8l-15.4-9.2-159.6 41.8 42.6-155.6-10-16c-42.4-67-64.6-144.6-64.6-224.4 0-232.6 189.2-421.8 422-421.8 112.6 0 218.6 44 298.2 123.6 79.6 79.8 123.4 185.6 123.4 298.4-0.2 232.8-189.4 422-421.8 422zM745.4 613.6c-12.6-6.4-75-37-86.6-41.2s-20-6.4-28.6 6.4c-8.4 12.6-32.8 41.2-40.2 49.8-7.4 8.4-14.8 9.6-27.4 3.2s-53.6-19.8-102-63c-37.6-33.6-63.2-75.2-70.6-87.8s-0.8-19.6 5.6-25.8c5.8-5.6 12.6-14.8 19-22.2s8.4-12.6 12.6-21.2c4.2-8.4 2.2-15.8-1-22.2s-28.6-68.8-39-94.2c-10.2-24.8-20.8-21.4-28.6-21.8-7.4-0.4-15.8-0.4-24.2-0.4s-22.2 3.2-33.8 15.8c-11.6 12.6-44.4 43.4-44.4 105.8s45.4 122.6 51.8 131.2c6.4 8.4 89.4 136.6 216.6 191.4 30.2 13 53.8 20.8 72.2 26.8 30.4 9.6 58 8.2 79.8 5 24.4-3.6 75-30.6 85.6-60.2s10.6-55 7.4-60.2c-3-5.6-11.4-8.8-24.2-15.2z"></path>
              </svg>
              <h5 className="chatbots-sidebaritemtext">Chatbots</h5>
            </div>
          </Link>

          <Link to="/contacts" className="chatbots-navlink1 ">
            <div className="chatbots-sidebaritem contactsbutton">
              <svg viewBox="0 0 1024 1024" className="chatbots-contacticon">
                <path d="M512 554q64 0 140 18t139 60 63 94v128h-684v-128q0-52 63-94t139-60 140-18zM512 170q70 0 120 51t50 121-50 120-120 50-120-50-50-120 50-121 120-51zM512 636q-88 0-174 33t-86 57v46h520v-46q0-24-86-57t-174-33zM512 252q-38 0-64 26t-26 64 26 63 64 25 64-25 26-63-26-64-64-26z"></path>
              </svg>
              <h5 className="chatbots-sidebaritemtext">Contacts</h5>
            </div>
          </Link>

          <Link to="/livechat" className="chatbots-navlink2">
            <div className="chatbots-sidebaritem livechatbutton">
              <svg viewBox="0 0 1152 1024" className="chatbots-chaticon">
                <path d="M480 0v0c265.096 0 480 173.914 480 388.448s-214.904 388.448-480 388.448c-25.458 0-50.446-1.62-74.834-4.71-103.106 102.694-222.172 121.108-341.166 123.814v-25.134c64.252-31.354 116-88.466 116-153.734 0-9.106-0.712-18.048-2.030-26.794-108.558-71.214-177.97-179.988-177.97-301.89 0-214.534 214.904-388.448 480-388.448zM996 870.686c0 55.942 36.314 104.898 92 131.772v21.542c-103.126-2.318-197.786-18.102-287.142-106.126-21.14 2.65-42.794 4.040-64.858 4.040-95.47 0-183.408-25.758-253.614-69.040 144.674-0.506 281.26-46.854 384.834-130.672 52.208-42.252 93.394-91.826 122.414-147.348 30.766-58.866 46.366-121.582 46.366-186.406 0-10.448-0.45-20.836-1.258-31.168 72.57 59.934 117.258 141.622 117.258 231.676 0 104.488-60.158 197.722-154.24 258.764-1.142 7.496-1.76 15.16-1.76 22.966z"></path>
              </svg>
              <h5 className="chatbots-sidebaritemtext">Livechat</h5>
            </div>
          </Link>

          <Link to="/settings" className="chatbots-navlink2">
            <div className="chatbots-sidebaritem settingsbutton">
              <svg viewBox="0 0 1024 1024" className="chatbots-chaticon">
                <path d="M512 662q62 0 106-44t44-106-44-106-106-44-106 44-44 106 44 106 106 44zM830 554l90 70q14 10 4 28l-86 148q-8 14-26 8l-106-42q-42 30-72 42l-16 112q-4 18-20 18h-172q-16 0-20-18l-16-112q-38-16-72-42l-106 42q-18 6-26-8l-86-148q-10-18 4-28l90-70q-2-14-2-42t2-42l-90-70q-14-10-4-28l86-148q8-14 26-8l106 42q42-30 72-42l16-112q4-18 20-18h172q16 0 20 18l16 112q38 16 72 42l106-42q18-6 26 8l86 148q10 18-4 28l-90 70q2 14 2 42t-2 42z"></path>
              </svg>
              <h5 className="chatbots-sidebaritemtext">Settings</h5>
            </div>
          </Link>
        </div>

        <div className="chatbots-logout-wrapper" onClick={handleLogout}>
          <div className="chatbots-sidebaritem">
            <svg viewBox="0 0 1024 1024" className="chatbots-logouticon">
              <path d="M384 853.333h-170.667c-11.776 0-22.4-4.736-30.165-12.501s-12.501-18.389-12.501-30.165v-597.333c0-11.776 4.736-22.4 12.501-30.165s18.389-12.501 30.165-12.501h170.667c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-170.667c-35.328 0-67.413 14.379-90.496 37.504s-37.504 55.168-37.504 90.496v597.333c0 35.328 14.379 67.413 37.504 90.496s55.168 37.504 90.496 37.504h170.667c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667zM793.003 469.333h-409.003c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667h409.003l-140.501 140.501c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l213.333-213.333c4.096-4.096 7.168-8.789 9.259-13.824 6.4-15.445 3.328-33.92-9.259-46.507l-213.333-213.333c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331z"></path>
            </svg>

            <h5 className="chatbots-logouttext">Logout</h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
