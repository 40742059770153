import "./flowchart.css";
import React, {useEffect, useState} from "react";
import {Provider} from "react-redux"; 
import ReactFlowCreator from "../flow_builder/container/ReactFlowCreator";
import {store} from "../flow_builder/store/store";
import "reactflow/dist/style.css";
import {get_bot} from "../apis/index";
import {base_url as API_URL} from "../constants";
import axios from "axios";
import {sleep} from "../utils/helper";
import {message as toast} from "antd";
import {v4 as uuidv4} from "uuid";
import { useRecoilState } from 'recoil';
import { flow_data_state } from '../atom';
import {useHistory} from "react-router-dom";

const FlowChart = (props) => {
  const {bot_id, flow_id} = props.match.params;
  const [flow_data, setFlow_data] = useRecoilState(flow_data_state);
  const history = useHistory()

  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [loading, setLoading] = useState(false);
  const [flowname, setFlowname] = useState("----------");
  const [variables, setVariables] = useState([]);
  const [bot, setBot] = useState({});
  const access_token = localStorage.getItem("access_token");

  const starter_node = {
    id: `node-${uuidv4().replace(/-/g, "")}`,
    type: "starter",
    selector: "node",
    position: {x: 0, y: 0},
  };

  const plugin_init_node = {
    id: `node-${uuidv4().replace(/-/g, "")}`,
    type: "plugin_initiate",
    selector: "node",
    data: {params: []},
    position: {x: 0, y: 0},
  };

  const plugin_response_node = {
    id: `node-${uuidv4().replace(/-/g, "")}`,
    type: "plugin_response",
    selector: "node",
    data: {responses: []},
    position: {x: 0, y: 150},
  };

  if (!bot_id || !flow_id) {
    return (window.location.href = "/chatbots"); // Redirect to chatbots if status code is 404
  }

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "";
    };

    const handleWindowClose = () => {
      return "Are you sure you want to navigate away from this page?";
    };

    const handlePopstate = () => {
      window.history.pushState(null, document.title, window.location.href);
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("unload", handleWindowClose);
    window.addEventListener("popstate", handlePopstate);

    const on_load_get_variables = async () => {
      let bot = await get_bot(bot_id);
      if (bot) {
        setBot(bot);
        setVariables(bot.variables);
        if (bot.flows) {
          localStorage.setItem("flows", JSON.stringify(bot.flows));
        }
      }
    };

    const handleLoadFlow = async () => {
      setLoading(true);
      try {
        const url = `${API_URL}/automation/flow/` + flow_id;
        const result = await axios.get(url, {
          headers: {
            access_token: access_token,
          },
        });
        if (result.data) {
          const data = result.data;
          let flow_data = {id: flow_id, name: data.name, type: data.type, description: data.description, bot_id: data.bot_id};
          setFlow_data(flow_data)
          const {nodes, edges, name} = data;
          const hasStarterNode = nodes.some((node) => node.type === "starter");
          const hasResponseNode = nodes.some((node) => node.type === "plugin_response");
          const hasInitiateNode = nodes.some((node) => node.type === "plugin_initiate");

          if (flow_data.type == "Plugin") {
            if (!hasInitiateNode) {
              nodes.push(plugin_init_node);
            }

            if (!hasResponseNode) {
              nodes.push(plugin_response_node);
            }
          } else {
            if (!hasStarterNode) {
              nodes.push(starter_node);
            }
          }

          setNodes(nodes);
          setEdges(edges);
          setFlowname(name);
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        toast.error("this flow doesn't, exist it may have been deleted");
        await sleep(2000);
        return (window.location.href = "/chatbots"); // Redirect to chatbots if status code is 404
      }
    };

    handleLoadFlow();
    on_load_get_variables();

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("unload", handleWindowClose);
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  return (
    <Provider store={store}>
      <div className="flowchart">
        <ReactFlowCreator flow_id={flow_id} variables={variables} bot={bot} setVariables={setVariables} bot_id={bot_id} flowname={flowname} nodes={nodes} edges={edges} setNodes={setNodes} setEdges={setEdges} loading={loading} setLoading={setLoading} />
      </div>
    </Provider>
  );
};

export default FlowChart;
