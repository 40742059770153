import {Select, Button, Popconfirm} from "antd";
import TrashItem from "./trash_item";

const Trash = ({chatbot, delete_loading, HandleRecoverFlow, handleTrashSelect, trash_selectall, handleTrashSelectAll, create_loading, HandleDeleteTrashItems, getSelectedTrashCount }) => {
  return (
    <div className="chatbot-flowcontainer">
      <div className="chatbot-flowheader">
        <div className="chatbot-container15">
          <span className="chatbot-text10">{getSelectedTrashCount()} selected</span>

          <Button loading={create_loading} style={{backgroundColor: "#e6e8e7", marginLeft: "10px"}} onClick={HandleRecoverFlow}>
            Recover
          </Button>

          <Popconfirm placement="bottomRight" title={"Confirmation"} description={"Are you sure you wanted to permanetly delete the selected flows"} onConfirm={HandleDeleteTrashItems} okText="Yes" cancelText="No">
            <Button loading={delete_loading} style={{backgroundColor: "var(--dl-color-danger-500)", marginLeft: "10px", color: "white"}}>
              Delete
            </Button>
          </Popconfirm>
        </div>
      </div>
      <div className="chatbot-flowitem">
        <div className="chatbot-container16">
          <input type="checkbox" checked={trash_selectall} onChange={handleTrashSelectAll} className="chatbot-checkbox" />
        </div>
        <div className="chatbot-container17">
          <span className="chatbot-text11">Name</span>
        </div>
        <div className="chatbot-container18">
          <span className="chatbot-text12">Last Modified</span>
        </div>
      </div>
      {chatbot.trash.length == 0 && (
        <div className="chatbot-emptyflow">
          <span className="chatbot-text13">No Data</span>
        </div>
      )}

      {chatbot.trash.map((trash) => (
        <TrashItem key={trash.id} bot_id={chatbot._id} handleTrashSelect={handleTrashSelect} trash={trash} />
      ))}
    </div>
  );
};

export default Trash;
