import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";
import {useHistory, useLocation} from "react-router-dom";
import {verifyemail} from "../apis";
import {message as toast, Form, Input, Button, Space} from "antd";
import {Spin} from "antd";
import "./verifyemail.css";

const Verifyemail = (props) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const [user_id, setUser_id] = useState(searchParams.get('user_id'));
  const [email, setEmail] = useState(searchParams.get('email'));
  
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState("");
  const [email_disabled, setEmail_disabled] = useState(true);
  const [countdown_restart, setCountdown_restart] = useState(false);
  const [countdown, setCountdown] = useState(15);

  const handleChange = (e) => {
    setCode(e.target.value.trim());
  };

  const Handle_Submit = async () => {
    setLoading(true);
    if (!code) {
      setLoading(false);
      return toast.error("Enter code");
    }
    await verifyemail(JSON.stringify({user_id, code, email}));
    setLoading(false);
  };

  

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown((prevCount) => {
        if (prevCount === 0) {
          clearInterval(interval); // Stop the interval when countdown reaches 0
          return prevCount; // Keep countdown at 0
        } else {
          return prevCount - 1;
        }
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [countdown_restart]);

  const Handle_Email_Change = () => {
    setEmail_disabled(false);
  };

  const Resend_Code = () => {
    setCountdown(15);
    setCountdown_restart(!countdown_restart);
    setEmail_disabled(true);
    toast.success("Verication code sent");
  };

  const Email_Changed = (e) => {
    const trimmedLowercaseEmail = e.target.value.trim().toLowerCase();
    setEmail(trimmedLowercaseEmail);
  }

  const Handle_Email_Blur = (e) => {
    setEmail_disabled(true)
  }
  
  return (
    <div className="verifyemail-container">
      <Helmet>
        <title>verifyemail - 50Chats</title>
        <meta property="og:title" content="verifyemail - wapi" />
      </Helmet>
      <div className="verifyemail-container1">
        <h2 className="verifyemail-text">50Chats</h2>
        <Link to="/signup" className="verifyemail-navlink button">
          Signup
        </Link>
      </div>
      <div className="verifyemail-container2">
        <div className="verifyemail-container3">
          {/* <div className="verifyemail-container4"> */}
          <h3 style={{marginBottom: 20}}>Please enter the verification code sent to your email</h3>
          {/* </div> */}

          <Space style={{width: "350px", marginBottom: 20}} direction="horizontal">
            <Input onChange={Email_Changed} onBlur={Handle_Email_Blur} disabled={email_disabled} value={email} style={{width: 260}} placeholder="Enter 4 digit code" />

            <Button
              style={{
                width: 80,
              }}
              onClick={Handle_Email_Change}
            >
              change
            </Button>
          </Space>

          <Input onChange={(e) => setCode(e.target.value)} value={code} maxLength={4} style={{width: "350px", marginBottom: 30}} placeholder="Enter 4 digit code" />

          <div style={{width: "350px", display: "flex", flexDirection: "row", justifyContent: "end"}}>
            {countdown > 0 ? (
              <Button type="default">resend code in {countdown}s</Button>
            ) : (
              <Button onClick={Resend_Code} type="default">
                resend code
              </Button>
            )}

            <Button onClick={Handle_Submit} style={{marginLeft: "15px"}} type="primary" htmlType="submit">
              Submit
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Verifyemail;
