import React, {useCallback, useEffect, useState, useRef} from "react";
import ReactFlow, {addEdge, applyEdgeChanges, applyNodeChanges, ReactFlowProvider, useReactFlow, Controls, MarkerType} from "reactflow";
import {v4 as uuidv4} from "uuid";
import "./Flow.css";
import "../FlowComponents/customEdge/styles.css";
import ActionDrawer from "../component/drawers/ActionDrawer.js";
import APIDrawer from "../component/drawers/APIDrawer.js";
import FilterDrawer from "../component/drawers/FilterDrawer.js";
import FlowDrawer from "../component/drawers/FlowDrawer.js";
import MessageDrawer from "../component/drawers/MessageDrawer.js";
import PauseDrawer from "../component/drawers/PauseDrawer.js";
import RandomDrawer from "../component/drawers/RandomDrawer.js";
import ChatgptDrawer from "../component/drawers/ChatgptDrawer.js";
import PluginInitiateDrawer from "../component/drawers/PluginInitiateDrawer";
import PluginResponseDrawer from "../component/drawers/PluginResponseDrawer";

import FlowActionNode from "../FlowComponents/FlowActionNode.js";
import FlowApiNode from "../FlowComponents/FlowApiNode.js";
import FlowFilterNode from "../FlowComponents/FlowFilterNode.js";
import FlowFlowNode from "../FlowComponents/FlowFlowNode.js";
import FlowStarterNode from "../FlowComponents/FlowStarterNode.js";
import FlowMessageNode from "../FlowComponents/FlowMessageNode.js";
import ChatgptNode from "../FlowComponents/ChatgptNode.js";
import "../FlowComponents/FlowNodes.css";
import FlowPauseNode from "../FlowComponents/FlowPauseNode.js";
import FlowRandomNode from "../FlowComponents/FlowRandomNode.js";
import FlowPluginInitiateNode from "../FlowComponents/FlowPluginInitiateNode";
import FlowPluginResponseNode from "../FlowComponents/FlowPluginResponseNode";

import {Button, Drawer, Input, Modal, message as toast, Popconfirm, Tooltip} from "antd";
import {DeleteOutlined} from "@ant-design/icons";

import LeftMenu from "./LeftMenu";
import CustomEdge from "../FlowComponents/customEdge/CustomEdge";
import {bindActionCreators} from "redux";
import {flowActions} from "../store/actions";
import {useDispatch, useSelector} from "react-redux";
import FloatingEdge from "../FlowComponents/customEdge/FloatingEdge";
import CustomConnectionLine from "../FlowComponents/customEdge/CustomConnectionLine";
import CustomNode from "../FlowComponents/CustomNode";
import axios from "axios";
import {base_url} from "../../constants";
import {sleep} from "../../utils/helper";
import {UndoOutlined, RedoOutlined} from "@ant-design/icons";
import {local} from "d3";
import {node} from "prop-types";
import {useRecoilState} from "recoil";
import {flow_data_state} from "../../atom";

const API_URL = base_url + "/automation";

const rfStyle = {
  backgroundColor: "rgb(242,244,246)",
};

const edgeTypes = {
  floating: FloatingEdge,
};

const connectionLineStyle = {
  strokeWidth: 3,
  stroke: "rgb(161,170,187)",
};

const defaultEdgeOptions = {
  style: {strokeWidth: 3, stroke: "rgb(56, 135, 255)"},
  type: "floating",
  data: {isclicked: false},
};

// we define the nodeTypes outside of the component to prevent re-renderings
// you could also use useMemo inside the component

const nodeTypes = {
  message: FlowMessageNode,
  flow: FlowFlowNode,
  action: FlowActionNode,
  condition: FlowFilterNode,
  api: FlowApiNode,
  delay: FlowPauseNode,
  random: FlowRandomNode,
  custom: CustomNode,
  plugin_initiate: FlowPluginInitiateNode,
  chatgpt: ChatgptNode,
  plugin_response: FlowPluginResponseNode,
  starter: FlowStarterNode,
};

const access_token = localStorage.getItem("access_token");

const ReactFlowCreator = ({flow_id, bot_id, bot, nodes, setNodes, variables, setVariables, edges, setEdges, loading, setLoading, flowname}) => {
  const [selectedNode, setSelectedNode] = useState(null);
  const reactFlowWrapper = useRef(null);
  const [rfInstance, setRfInstance] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const {project} = useReactFlow();
  const [flow_data, setFlow_data] = useRecoilState(flow_data_state);

  const plugin_inititate_node = nodes.find((node) => node.type === "plugin_initiate");
  const params = plugin_inititate_node?.data.params.map((item) => ({
    name: item.key,
  }));
  variables.params = params;

  const [openModal, setOpenModal] = useState(false);
  const [edgehover, setEdgehover] = useState(false);
  const [flows, setFlows] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [drawer_size, setDrawer_size] = useState(null);
  const [drawer_title, setDrawer_title] = useState(null);

  const dispatch = useDispatch();
  const flowState = useSelector((state) => state.flow);

  const currentFlow = flows[currentIndex];

  const undo = useCallback(() => {
    if (currentIndex > 0) {
      const newIndex = currentIndex - 1;
      setCurrentIndex(newIndex);
      setNodes(flows[newIndex].nodes);
      setEdges(flows[newIndex].edges);
    }
  }, [currentIndex, flows, setCurrentIndex, setNodes, setEdges]);

  const redo = useCallback(() => {
    if (currentIndex < flows.length - 1) {
      const newIndex = currentIndex + 1;
      setCurrentIndex(newIndex);
      setNodes(flows[newIndex].nodes);
      setEdges(flows[newIndex].edges);
    }
  }, [currentIndex, flows, setCurrentIndex, setNodes, setEdges]);

  useEffect(() => {
    const clickInterval = setInterval(() => {
      const button = document.getElementById("saveflow");
      if (button) {
        button.click();
      }
    }, 60000);

    return () => {
      clearInterval(clickInterval);
    };
  }, []);

  // const {nodes, edges} = flowState;

  const {onEdgeAdd, onNodeChange, onNewNode, onDeleteNode, onUpdateNode} = bindActionCreators(flowActions, dispatch);

  const onNodeAdd = useCallback(async (node) => {
    let type = node.type;
    let data = {};
    switch (type) {
      case "message":
        data = {message: "", input: "no", save_as: "", validation: "", file: {}, button_data:  {
          type: "reply",
          buttons: []
        }};
        break;
      case "flow":
        data = {flow_id: ""};
        break;
      case "action":
        data = {type: "hand_over_to_human"};
        break;
      case "condition":
        data = {conditions: []};
        break;
      case "api":
        // need delete all existing api node on the database
        data = {url: "", method: "GET", headers: [], fields: [], mappings: [], body: ""};
        break;
      case "delay":
        data = {unit: "minutes", value: 0};
        break;
      case "chatgpt":
        data = {type: "chat_completion", use_plugin: "yes"};
        break;
      case "plugin_initiate":
        data = {params: []};
        break;
      case "plugin_response":
        data = {responses: []};
        break;
      case "random":
        data = {
          options: [
            {index: 0, value: 50},
            {index: 1, value: 50},
          ],
        };
        break;
      default:
        return;
    }

    const newNode = {
      id: `node-${uuidv4().replace(/-/g, "")}`,
      type,
      position: node.position,
      data: data,
    };
    // onNewNode(newNode)
    // handleNodeUpdate(newNode)

    setNodes([...nodes, newNode]);
    handleFlowAutosave();
  });

  const renderDrawer = () => {
    switch (selectedNode?.type) {
      case "plugin_initiate":
        return <PluginInitiateDrawer onNodeChange={handleNodeUpdate} node={selectedNode} />;
      case "plugin_response":
        return <PluginResponseDrawer onNodeChange={handleNodeUpdate} node={selectedNode} variables={variables} />;
      case "message":
        return <MessageDrawer node={selectedNode} onNodeChange={handleNodeUpdate} onNodeDelete={handleNodeDelete} loading={loading} variables={variables} setVariables={setVariables} bot_id={bot_id} />;
      case "flow":
        return <FlowDrawer node={selectedNode} onNodeChange={handleNodeUpdate} onNodeDelete={handleNodeDelete} loading={loading} />;
      case "action":
        return <ActionDrawer bot_id={bot_id} node={selectedNode} onNodeChange={handleNodeUpdate} onNodeDelete={handleNodeDelete} loading={loading} variables={variables} setVariables={setVariables} />;
      case "api":
        return <APIDrawer node={selectedNode} onNodeChange={handleNodeUpdate} onNodeDelete={handleNodeDelete} loading={loading} variables={variables} setVariables={setVariables} bot_id={bot_id} />;
      case "random":
        return <RandomDrawer node={selectedNode} onNodeChange={handleNodeUpdate} onNodeDelete={handleNodeDelete} loading={loading} />;
      case "delay":
        return <PauseDrawer node={selectedNode} onNodeChange={handleNodeUpdate} onNodeDelete={handleNodeDelete} loading={loading} />;
      case "condition":
        return <FilterDrawer node={selectedNode} onNodeChange={handleNodeUpdate} onNodeDelete={handleNodeDelete} loading={loading} variables={variables} setVariables={setVariables} />;
      case "chatgpt":
        return <ChatgptDrawer node={selectedNode} onNodeChange={handleNodeUpdate} onNodeDelete={handleNodeDelete} loading={loading} variables={variables} setVariables={setVariables} bot_id={bot_id} />;
      default:
        return <div>Loading...</div>;
    }
  };

  const handleNodeUpdate = useCallback(async (updatedNode) => {
    setLoading(true);
    // try {
    //   const url = `${API_URL}/node`;
    //   const result = await axios(url, {
    //     method: "POST",
    //     headers: {
    //       access_token: access_token,
    //     },
    //     data: {
    //       ...updatedNode,
    //       selector: "node",
    //       flow_id,
    //       bot_id,
    //     },
    //   });
    //   if (result.data) {
    //     const updatedNodeList = nodes.map((n) => (n.id === updatedNode.id ? updatedNode : n));
    //     closeDrawer();
    //     // onUpdateNode(updatedNodeList)
    //     setNodes(updatedNodeList);
    //   }
    // } catch (er) {
    //   console.log(er);
    //   setLoading(false);
    //   closeDrawer();
    // }

    const updatedNodeList = nodes.map((n) => (n.id === updatedNode.id ? updatedNode : n));
    // closeDrawer();
    // onUpdateNode(updatedNodeList)
    setNodes(updatedNodeList);
    setLoading(false);
    await sleep(1000);
    return handleFlowAutosave();
  });

  const handleNodeDelete = useCallback(async () => {
    // deleting the node
    setLoading(true);
    try {
      const url = `${API_URL}` + "/node/" + selectedNode.id;
      const result = await axios(url, {
        method: "DELETE",
        headers: {
          access_token: access_token,
        },
      });
      if (result.data) {
        const updatedNodeList = nodes.filter((n) => n.id !== selectedNode.id);
        setNodes(updatedNodeList);
      }
    } catch (er) {
      console.log(er);
      setLoading(false);
      closeDrawer();
    }

    // deleting the associated edge
    const updatedEdgeList = edges.filter((e) => e.source === selectedNode.id || e.target === selectedNode.id);

    updatedEdgeList.forEach(async (edge) => {
      await handleDeleteEdge(edge);
    });

    toast.success("Node deleted successfull!");
    setLoading(false);
    closeDrawer();
  });

  const handleNodeDuplicate = useCallback(async (event, node) => {
    const {top, left} = reactFlowWrapper.current.getBoundingClientRect();

    let position = project({x: event.clientX - left - 75, y: event.clientY - top});
    const newNode = {
      id: `node-${Date.now()}`,
      type: node.type,
      position,
      data: node.data,
    };

    return setNodes([...nodes, newNode]);
  });

  // const handleUpdateEdge = async (updatedEdge) => {
  //   try {
  //     const url =   `${API_URL}/node`;
  //     const result = await axios(url, {
  //       method: "POST",
  //       headers: {
  //         access_token: access_token,
  //       },
  //       data: {
  //         ...updatedEdge,
  //         selector: "edge",
  //         flow_id,
  //         bot_id
  //       },
  //     });
  //   }  catch (er) {
  //     console.log(er);
  //   }
  // }

  const handleDeleteEdge = useCallback((edge) => {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${API_URL}/node/` + edge.id;
        const result = await axios(url, {
          method: "DELETE",
          headers: {
            access_token: access_token,
          },
        });
        if (result.data) {
          resolve(result.data);
        }
      } catch (er) {
        console.log(er);
        reject(er);
        setLoading(false);
        closeDrawer();
      }
    });
  });

  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  const openDrawer = () => {
    setDrawerOpen(true);
  };

  const onNodesChange = useCallback(async (changes) => {
    onNodeChange(changes, nodes);
    return setNodes((nds) => applyNodeChanges(changes, nds)), [setNodes];
  });

  const handleDeleteNode = (node) => {
    const updatedNodeList = nodes.filter((n) => n.id !== node.id);
    setNodes(updatedNodeList);
    closeDrawer();
  };

  const handleNodeClick = useCallback((event, node) => {
    if (event.click_type === "duplicate") {
      event.preventDefault(); // Prevent default behavior
      handleNodeDuplicate(event, node);
      return;
    }

    if (event.click_type === "delete") {
      event.preventDefault(); // Prevent default behavior
      handleDeleteNode(node);
      return;
    }

    if (flow_data.type == "Plugin") {
      if (node.type == "plugin_initiate") {
        setDrawer_title("Plugin Initiate");
      }

      if (node.type == "plugin_response") {
        setDrawer_title("Plugin Response");
      }
    } else {
      setDrawer_title(node.type);
    }

    let large_nodes = ["api", "plugin_initiate", "plugin_response"];
    if (large_nodes.includes(node.type)) {
      setDrawer_size("large");
    } else {
      setDrawer_size("default");
    }
    
    if(node.type == "starter"){
      return
    }

    setSelectedNode(node);
    openDrawer();
    return;
  });

  const onEdgesChange = useCallback(async (changes) => {
    setEdges((eds) => applyEdgeChanges(changes, eds)), [setEdges];
    handleFlowAutosave();
  });

  const onConnect = useCallback((conn) => {
    if (conn.source == conn.target) {
      return;
    }

    let edgeWithSameSource = edges.find((edge) => edge.source === conn.source && edge.sourceHandle === conn.sourceHandle);
    if (edgeWithSameSource) {
      setEdges((eds) => eds.filter((edge) => edge.id !== edgeWithSameSource.id));
    }

    // let source_node = nodes.find((node) => node.id === conn.source);

    // if (source_node.type !== "condition" && source_node.type !== "message") {
    //   setEdges((eds) => eds.filter((edge) => edge.source !== conn.source));
    //   setEdges((eds) => eds.filter((edge) => nodes.some((node) => node.id === edge.target)));
    // }

    if (conn?.targetHandle === "z") {
      conn.targetHandle = "a";
    }

    // onEdgeAdd(conn, edges)
    setEdges((eds) => addEdge(conn, eds)), [setEdges];
    return handleFlowAutosave();
  });

  const handleSaveFlow = async () => {
    setLoading(true);
    setEdges((eds) => eds.filter((edge) => nodes.some((node) => node.id === edge.target)));
    try {
      const myNodes = [];
      const myEdges = [];
      nodes.map((node) => {
        myNodes.push({
          ...node,
          flow_id,
          selector: "node",
          bot_id,
        });
      });
      edges.map((edge) => {
        delete edge.data;
        myEdges.push({
          ...edge,
          flow_id,
          selector: "edge",
          bot_id,
        });
      });
      const url = `${API_URL}/flow`;
      const result = await axios({
        url: url,
        method: "POST",
        headers: {
          access_token: access_token,
        },
        data: {
          nodes: myNodes,
          edges: myEdges,
          flow_id,
          bot_id,
        },
      });
      if (result.data) {
        toast.success("Flow chart saved successfully!");
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
    setLoading(false);
  };

  const HandleEdgeClick = (event, edge) => {
    let edgeId = edge.id;

    if (edgehover == true) {
      const updatedEdges = edges.filter((edge) => edge.id !== edgeId);
      setEdges(updatedEdges);
    }
    return;
  };

  const onEdgeMouseEnter = (event, edge) => {
    let edgeId = edge.id;

    const updatedEdges = edges.map((edge) => {
      if (edge.id === edgeId) {
        return {
          ...edge,
          data: {isclicked: true},
        };
      }
      return edge;
    });
    setEdges(updatedEdges);
    setEdgehover(true);
  };

  const onEdgeMouseLeave = (event, edge) => {
    let edgeId = edge.id;

    const updatedEdges = edges.map((edge) => {
      if (edge.id === edgeId) {
        return {
          ...edge,
          data: {isclicked: false},
        };
      }
      return edge;
    });
    setEdges(updatedEdges);
    setEdgehover(true);
  };

  const handleDeleteFlow = async () => {
    setLoading(true);
    try {
      const url = `${API_URL}/flow/` + flow_id;
      const result = await axios({
        url: url,
        method: "DELETE",
        headers: {
          access_token: access_token,
        },
      });
      if (result.data) {
        toast.success("Flow chart deleted successfully!");
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
    setLoading(false);
  };

  const HandleDrop = useCallback(async () => {
    handleFlowAutosave();
  });

  const handleTest = useCallback(() => {
    if (rfInstance) {
      const flow = rfInstance.toObject();

      // console.log(flow);

      localStorage.setItem(flow_id, JSON.stringify(flow));
      console.log("these are all the flows", flows);
    }
  });

  const isEqual = (a, b) => JSON.stringify(a) === JSON.stringify(b);

  const handleFlowAutosave = useCallback(async () => {
    console.log("Autosaving in progress ===========================");
    await sleep(1000);
    if (rfInstance) {
      const flow = rfInstance.toObject();
      localStorage.setItem(flow_id, JSON.stringify(flow));
      console.log(flow);

      setFlows((prevFlows) => [...prevFlows, flow]);
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }
  }, [rfInstance]);

  // const onConnectEnd = useCallback(
  //   (event) => {
  //     const targetIsPane = event.target.classList.contains('react-flow__pane');

  //     if (targetIsPane) {
  //       // we need to remove the wrapper bounds, in order to get the correct position
  //       const { top, left } = reactFlowWrapper.current.getBoundingClientRect();

  //       const newNode = {
  //         type: "message",
  //         position: project({ x: event.clientX - left - 75, y: event.clientY - top }),
  //       };

  //   console.log(newNode)
  //     }
  //   },
  //   []
  // );

  return (
    <div className="react-flow-container" ref={reactFlowWrapper}>
      <LeftMenu onNodeAdd={onNodeAdd} reactFlowWrapper={reactFlowWrapper} project={project} />
      <ReactFlow
        nodes={nodes}
        minZoom={0.1}
        maxZoom={2}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onEdgeMouseEnter={onEdgeMouseEnter}
        onEdgeMouseLeave={onEdgeMouseLeave}
        onEdgeClick={HandleEdgeClick}
        onNodeClick={handleNodeClick}
        onConnect={onConnect}
        nodeTypes={nodeTypes}
        fitView
        style={rfStyle}
        edgeTypes={edgeTypes}
        connectionLineComponent={CustomConnectionLine}
        connectionLineStyle={connectionLineStyle}
        defaultEdgeOptions={defaultEdgeOptions}
        onInit={setRfInstance}
        onNodeDragStop={HandleDrop}
        // snapToGrid
        // snapGrid={[15, 15]}
      />
      <Drawer
        size={drawer_size}
        title={
          <div>
            {drawer_title}

            {!(selectedNode?.type == "plugin_initiate" || selectedNode?.type == "plugin_response") && (
              <div style={{float: "right"}}>
                <Button disabled={loading} loading={loading} onClick={() => handleDeleteNode(selectedNode)} style={{color: "#ff0000", borderColor: "#ff0000", marginLeft: "8px"}}>
                  Delete
                </Button>
              </div>
            )}
          </div>
        }
        placement="right"
        onClose={closeDrawer}
        open={drawerOpen}
      >
        {renderDrawer()}
      </Drawer>

      <div
        style={{
          position: "fixed",
          top: 16,
          right: 16,
          display: "flex",
          alignItems: "center",
          columnGap: "16px",
        }}
      >
        <h4>{flowname}</h4>

        <Tooltip title="Undo Ctrl+Z">
          <Button onClick={undo} disabled={currentIndex <= 0}>
            <UndoOutlined style={{color: currentIndex <= 0 ? "grey" : "blue", cursor: "pointer"}} />
          </Button>
        </Tooltip>

        <Tooltip title="Redo Ctrl+Y">
          <Button onClick={redo} disabled={currentIndex === flows.length - 1}>
            <RedoOutlined style={{color: currentIndex === flows.length - 1 ? "grey" : "blue", cursor: "pointer"}} />
          </Button>
        </Tooltip>

        <Popconfirm placement="bottomRight" title={"Confirmation"} description={"Are you sure you wanted to delete this flow"} onConfirm={handleDeleteFlow} okText="Yes" cancelText="No">
          <Button style={{background: "red"}} loading={loading} type="primary">
            Delete Flow
          </Button>
        </Popconfirm>

        <Button
          loading={loading}
          onClick={handleSaveFlow}
          type="primary"
        >
          {" "}
          Publish Flow
        </Button>
      </div>
    </div>
  );
};

// wrapping with ReactFlowProvider is done outside of the component
function FlowWithProvider(props) {
  return (
    <ReactFlowProvider>
      <ReactFlowCreator {...props} />
    </ReactFlowProvider>
  );
}

export default FlowWithProvider;
