import React, {useEffect, useState} from "react";
import {Select} from "antd";
import timezones from "../../timezones.json";
import {get_settings, update_settings} from "../../apis/index";


function General() {
  const [setting, setSetting] = useState({integrations: {}});

  useEffect(() => {
    const onLoad = async () => {
      let result = await get_settings();
      if (result && result.data) {
        setSetting(result.data);
      }
    };

    onLoad();
  }, []);

  const HandleTimezone = async (timezone) => {
    let payload = {timezone};
    update_settings(JSON.stringify(payload));
    setSetting({...setting, timezone});
  };

  return (
    <div className="flex flex-col w-[100%]">
      <div className="flex md:gap-0 gap-4 md:flex-row flex-col justify-between items-center min-h-[10vh] bg-white border border-gray-300 p-6 rounded-md ">
        <h2 className="text-[1em] font-semibold">Account Time Zone</h2>
        {/* <select className="border border-gray-400 rounded-md p-2">
          <option className="font-semibold" value={"utc +5:30"}>
            UTC + 5:30
          </option>
          <option className="font-semibold" value={"utc +5:30"}>
            UTC + 5:00
          </option>
        </select> */}

        <Select showSearch={true} value={setting.timezone} style={{width: "200px"}} onSelect={HandleTimezone}>
          {timezones.map((timezone) => (
            <Select.Option key={timezone} value={timezone}>
              {timezone}
            </Select.Option>
          ))}
        </Select>

        <h3 className="md:w-[30%] w-[70%] text-center text-[0.8em]">All the data in 50chats will be displayed and exported according to this timezone. Learn more</h3>
      </div>


      <div className="flex md:flex-row md:gap-0 gap-4 flex-col justify-between items-center min-h-[10vh] bg-white p-6 border border-gray-300 rounded-md">
        <h2 className="text-[1em] font-semibold">Api Key</h2>
        {/* <div> */}
          <p>{setting.integrations.api_key}</p>
        {/* </div> */}
        <h3 className="md:w-[30%] w-[70%] text-center text-[0.8em]">This Api Key can be used if you are building an API based integration</h3>
      </div>


      {/* <div className="flex md:flex-row md:gap-0 gap-4 flex-col justify-between items-center min-h-[10vh] bg-white p-6 border border-gray-300 rounded-md">
        <h2 className="text-[1em] font-semibold">Clone to Another Page</h2>
        <button className="bg-[#705CF6] text-white p-2 rounded-md">Clone This Bot</button>
        <h3 className="md:w-[30%] w-[70%] text-center text-[0.8em]">Transfer all content of this bot to another page</h3>
      </div> */}


      <div className="flex md:flex-row flex-col md:gap-0 gap-4 justify-between items-center min-h-[10vh] bg-white p-6 border border-gray-300 rounded-md">
        <h2 className="text-[1em] font-semibold">Delete Account</h2>
        <button className="border border-red-500 p-2 font-regular rounded-md text-red-500">Delete Account</button>

        <h3 className="md:w-[30%] w-[70%] text-center text-[0.8em]">Permanently delete account.</h3>
      </div>
    </div>
  );
}

export default General;
