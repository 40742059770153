import {Link} from "react-router-dom";

const TrashItem = ({trash, handleTrashSelect, bot_id}) => {
  return (
    <div className="chatbot-trashitem1">
      <div className="chatbot-container19">
        <input type="checkbox" onChange={() => handleTrashSelect(trash.id)} checked={trash.selected} className="chatbot-checkbox1" />
      </div>
      <div  className="chatbot-container20">
        <span className="chatbot-text14">{trash.name}</span>
      </div>
      <div className="chatbot-container21">
        <span className="chatbot-text15">{trash.last_modified}</span>
      </div>
      <div className="chatbot-container22">
        {/* <svg
          viewBox="0 0 1024 1024"
          className="chatbot-icon08"
          onClick={() => {
            HandleEditNode(trash);
          }}
        >
          <path d="M884 300l-78 78-160-160 78-78q12-12 30-12t30 12l100 100q12 12 12 30t-12 30zM128 736l472-472 160 160-472 472h-160v-160z"></path>
        </svg> */}
      </div>
    </div>
  );
};

export default TrashItem;
