import React, {useEffect} from 'react'
function Analytics() {
    useEffect(() => {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.type = 'text/css';
        link.href =  "../../tailwind.css"
    
        document.head.appendChild(link);
    
        return () => {
          document.head.removeChild(link);
        };
      }, []); 

      


  return (
    <>
        <div className='lg:w-[80%] ml-[3vw] my-[3vh] gap-[3vw] h-[10vh] items-center w-[90%] overflow-auto'>
            <h2 className='font-bold text-[1.4em]' >Chatbot Analytic</h2>
        </div>
        <div className='flex flex-col lg:w-[90%] w-[100%]'>
            <div className='flex flex-wrap mb-10 md:p-4 gap-4 ml-[3vw] justify-evenly '>
                <div className='items-center gap-2 bg-gradient-to-b from-amber-200 to-amber-100 p-4 lg:w-[20vw] md:w-[25vw] sm:w-[40vw] w-[45vw] h-[30vh] rounded-lg flex flex-col'>
                    <h2 className='p-0 m-0'>Top Performing Chatbot</h2>
                    <h1 className='p-0 m-0 font-semibold text-[1.5em]'>KaoushikBot</h1>
                    <div className='flex justify-between gap-2'>
                        <div className='flex flex-col justify-between p-3 rounded-md h-[13vh] items-center bg-white'>
                            <h3 className='text-[0.8em]'>Success Rate</h3>
                            <h2 className='text-green-600 text-[1.2em] font-semibold'>86%</h2>
                        </div>
                        <div className='flex flex-col justify-between p-3 rounded-md h-[13vh] items-center bg-white'>
                            <h3 className='text-[0.8em]'>Success Rate</h3>
                            <h2 className='text-green-600 text-[1.2em] font-semibold'>86%</h2>
                        </div>
                        <div className='flex flex-col justify-between p-3 rounded-md h-[13vh] items-center bg-white'>
                            <h3 className='text-[0.8em]'>Success Rate</h3>
                            <h2 className='text-green-600 text-[1.2em] font-semibold'>86%</h2>
                        </div>
                    </div>
                </div>
                <div className='items-center bg-gradient-to-b from-purple-300 to-purple-100 p-4 md:w-[20vw] sm:w-[40vw] w-[45vw] h-[30vh] rounded-lg flex flex-col'>
                    <h2 className='p-0 m-0'>Average Satisfaction</h2>
                    <img className='h-[20vh] w-[20vw]' src='/assets/Group 289.svg'/>
                    
                </div>
                <div className='items-center bg-gradient-to-b from-blue-500 to-blue-100 p-4 md:w-[20vw] sm:w-[40vw] w-[45vw] h-[30vh] rounded-lg flex flex-col'>
                    <h2 className='p-0 m-0'>Average Success</h2>
                    <img className='h-[20vh] w-[20vw]' src='/assets/Group 289.svg'/>
                </div>
            </div>
            <div className='flex flex-wrap mb-10 md:px-4 gap-4 ml-[3vw] justify-evenly'>
                <div className='w-[45%] h-[40vh] border rounded-lg p-4'>
                    <h2>Message Sent Performance Over Time</h2>
                    <input type="date" className='border rounded-lg outline-none px-3 py-2 text-gray-500'/>
                </div>
                <div className='w-[45%] h-[40vh] border rounded-lg p-4'>
                    <h2>Message Received Performance Over Time</h2>
                    <input type="date" className='border rounded-lg outline-none px-3 py-2 text-gray-500'/>
                </div>
                
            </div>
        </div> 
    </>
  )
}

export default Analytics