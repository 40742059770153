import React, {useState, useEffect} from "react";
import Select_variable from "../buttons/select_variable";
import FloatingButton from "../buttons/FloatingButton";
import {message as toast} from "antd";

import "./plugin.css";

const PluginResponseDrawer = ({variables, onNodeChange, node, params}) => {
  const [mappings, setMappings] = useState([]);
  const [showFloats, setShowFloats] = useState(Array(mappings.length).fill(false));
  const [current_index, setCurrentIndex] = useState(null);

  
  useEffect(() => {
    if(node.data.responses){
      setMappings(node.data.responses)
    }
  }, [node.id]);

  
  useEffect(() => {
    const updatedNode = {
      ...node,
      data: {responses: mappings},
    };
    onNodeChange(updatedNode);
}, [mappings]);



  function hasDuplicateKeys(objects) {
    const keySet = [];
    for (const object of objects) {
      if (keySet.includes(object.key)) {
        return true;
      }
      keySet.push(object.key);
    }
    console.log(keySet);

    return false; // No duplicate keys found
  }

  const add_new_mapping = () => {
    if (hasDuplicateKeys(mappings)) {
      return toast.error("key already exists");
    }

    const allMappingsValid = mappings.every((mapping) => mapping.key.trim() !== "" && mapping.value.trim() !== "");
    if (!allMappingsValid) {
      return toast.error("key must not be empty");
    }

    const mapping = {key: "", value: "", description: ""};
    setMappings([...mappings, mapping]);
  };

  const delete_mapping = (index) => {
    const updatedMappings = [...mappings];
    updatedMappings.splice(index, 1);
    setMappings(updatedMappings);
  };

  const onMappingKeyChange = (value, index) => {
    const updatedMappings = [...mappings];
    updatedMappings[index].key = value;
    setMappings(updatedMappings);
  };

  const onMappingValueChange = (value, index) => {
    const updatedMappings = [...mappings];
    updatedMappings[index].value = value;
    setMappings(updatedMappings);
  };

  
  const handleModalClose = (index) => {
    const updatedShowFloats = [...showFloats];
    updatedShowFloats[index] = false;
    setShowFloats(updatedShowFloats);
    setCurrentIndex(null)
  };


  const handleSelectInValueChange = (value, index) => {
    const updatedMappings = [...mappings];
    updatedMappings[index].value += `{{${value}}}`;
    setMappings(updatedMappings);
    handleModalClose(index)
  };

  const handleBlur = (index) => {
    setTimeout(() => {
      setCurrentIndex(null);
    }, 1000);
  };

  const handleFloatButtonClick = (index) => {
    const updatedShowFloats = [...showFloats];
    updatedShowFloats[index] = true;
    setShowFloats(updatedShowFloats);
    setCurrentIndex(index)
  };

  const onMappingDescriptionChange = (value, index) => {
    const updatedMappings = [...mappings];
    updatedMappings[index].description = value;
    setMappings(updatedMappings);
  };

  const handleSelectValueFocus = (index) => {
    setCurrentIndex(index);
  };

  return (
    <div className="plugin-group1">
      <div className="plugin-param-label2">
        <div className="plugin-namelabel1">
          <span className="plugin-text10">Key</span>
        </div>
        <div className="plugin-typelabel1">
          <span className="plugin-text11">Value</span>
        </div>
        <div className="plugin-descriptionlabel1">
          <span className="plugin-text12">Description</span>
          <span className="plugin-text04">(optional)</span>
        </div>
      </div>
      {mappings.map((mapping, index) => (
        <div className="plugin-param-body2">
          <div className="plugin-name2">
            <input value={mapping.key} onChange={(e) => onMappingKeyChange(e.target.value, index)} type="text" className="plugin-textinput5 input" />
          </div>
          <div className="plugin-type1">
            <input type="text" value={mapping.value} onChange={(e) => onMappingValueChange(e.target.value, index)} onBlur={() => handleBlur(index)} onFocus={() => handleSelectValueFocus(index)}  className="plugin-textinput6 input" />
            {current_index === index && <FloatingButton onClick={() => handleFloatButtonClick(index)}  />}

            <Select_variable
              open={showFloats[index]}
              onClose={() => handleModalClose(index)}
              variables={variables}
              onChange={(value) => {
                handleSelectInValueChange(value, index);
              }}/>

          </div>
          <div className="plugin-description2">
            <input type="text" value={mapping.description} onChange={(e) => onMappingDescriptionChange(e.target.value, index)} className="plugin-textinput7 input" />
          </div>
          <svg
            viewBox="0 0 1024 1024"
            className="plugin-icon3"
            onClick={() => {
              delete_mapping(index);
            }}
          >
            <path d="M128 320v640c0 35.2 28.8 64 64 64h576c35.2 0 64-28.8 64-64v-640h-704zM320 896h-64v-448h64v448zM448 896h-64v-448h64v448zM576 896h-64v-448h64v448zM704 896h-64v-448h64v448z"></path>
            <path d="M848 128h-208v-80c0-26.4-21.6-48-48-48h-224c-26.4 0-48 21.6-48 48v80h-208c-26.4 0-48 21.6-48 48v80h832v-80c0-26.4-21.6-48-48-48zM576 128h-192v-63.198h192v63.198z"></path>
          </svg>
        </div>
      ))}

      <button type="button" onClick={add_new_mapping} className="plugin-button3 button">
        <span>
          <span>+ New Response Item</span>
          <br></br>
        </span>
      </button>
    </div>
  );
};

export default PluginResponseDrawer;
