


import { io } from 'socket.io-client';
import { useEffect, useState } from 'react';
import {base_url} from "../constants"

const useSocket = () => {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const socket = io(base_url);
    setSocket(socket);

    return () => {
      socket.disconnect();
    };
  }, []);

  return socket;
};

export default useSocket;
