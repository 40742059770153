import {message as toast} from "antd";
import {whitelisted_paths, base_url} from "../constants";

let access_token = localStorage.getItem("access_token");

const signup = async (data) => {
  try {
    const response = await fetch(base_url + "/auth/signup", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      const errorMessage = errorResponse.message;
      return toast.error(errorMessage);
    }

    const result = await response.json();
    // window.location.href = `/verifyemail?user_id=${result.user_id}`;
    window.location.href = `/login`;

    return result;
  } catch (error) {
    toast.error(error.message);
  }
};

const login = async (data) => {
  try {
    const response = await fetch(base_url + "/auth/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    });

    if (!response.ok) {
      if (response.status == 403) {
        const errorResponse = await response.json();
        const errorMessage = errorResponse.message;
        toast.error(errorMessage);
        return (window.location.href = `/verifyemail?user_id=${errorResponse.user_id}`);
      }
      const errorResponse = await response.json();
      const errorMessage = errorResponse.message;
      return toast.error(errorMessage);
    }

    const result = await response.json();
    let {access_token, user} = result;
    localStorage.setItem("access_token", access_token);
    localStorage.setItem("user", JSON.stringify(user));

    window.location.href = `/chatbots`;
    return result;
  } catch (error) {
    toast.error(error.message);
  }
};

const verifyemail = async (data) => {
  try {
    const response = await fetch(base_url + "/auth/verifyemail", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      const errorMessage = errorResponse.message;
      return toast.error(errorMessage);
    }

    const result = await response.json();
    let {access_token} = result;
    localStorage.setItem("access_token", access_token);
    window.location.href = `/chatbots`;
    return result;
  } catch (error) {
    toast.error(error.message);
  }
};

const resendcode = async (data) => {
  try {
    const response = await fetch(base_url + "/auth/resendcode", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      const errorMessage = errorResponse.message;
      toast.error(errorMessage);
      return null
    }

    const result = await response.json();
    toast.success("Verification code sent");
    return result
  } catch (error) {
    toast.error(error.message);
  }
};

const change_password = async (data) => {
  try {
    const response = await fetch(base_url + "/auth/changepassword", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      const errorMessage = errorResponse.message;
      toast.error(errorMessage);
      return null
    }

    const result = await response.json();
    return result
  } catch (error) {
    toast.error(error.message);
  }
};






const update_bot = async (_id, data) => {
  try {
    const response = await fetch(base_url + "/bot/" + _id, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        access_token: access_token,
      },
      body: data,
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      const errorMessage = errorResponse.message;
      toast.error(errorMessage);
      return null;
    }

    const result = await response.json();
    return result;
  } catch (error) {
    toast.error(error.message);
    return null;
  }
};


const get_bots = async () => {
  try {
    const response = await fetch(base_url + "/bot", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "access_token": access_token,
        // Consider adding Authorization header as a backup
        "Authorization": `Bearer ${access_token}`
      },
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      const errorMessage = errorResponse.message;
      return toast.error(errorMessage);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    toast.error(error.message);
    return null;  }
};


const get_bot = async (_id) => {
  try {
    const response = await fetch(base_url + "/bot/" + _id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        access_token: access_token,
      },
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      const errorMessage = errorResponse.message;
      return toast.error(errorMessage);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    toast.error(error.message);
    return null;
  }
};


const create_node = async (data) => {
  try {
    const response = await fetch(base_url + "/automation", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        access_token: access_token,
      },
      body: data,
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      const errorMessage = errorResponse.message;
      toast.error(errorMessage);
      return
    }

    const result = await response.json();
    return result;
  } catch (error) {
    toast.error(error.message);
    return null;
  }
};


const delete_node = async (id) => {
  try {
    const response = await fetch(base_url + "/automation/" + id, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        access_token: access_token,
      },
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      const errorMessage = errorResponse.message;
      return toast.error(errorMessage);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    toast.error(error.message);
    return null;
  }
};


const delete_flow = async (flow_id) => {
  try {
    const url = `${base_url}/automation/flow/` + flow_id;
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        access_token: access_token,
      },
    });

    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return null;
    }
  } catch (err) {
    console.log(err);
    return null;
  }
};


const recover_flow = async (data) => {
  try {
    const response = await fetch(base_url + "/automation/recyclebin/recover", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        access_token: access_token,
      },
      body: data,
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      const errorMessage = errorResponse.message;
      return toast.error(errorMessage);
    }

    const result = await response.json();
    toast.success("Flows recovered successfully! Please refresh the page");
    return result;
  } catch (error) {
    toast.error(error.message);
    return null;
  }
};





const copy_flow = async (id) => {
  try {
    const response = await fetch(base_url +  `/automation/flow/${id}/copy`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        access_token: access_token,
      },
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      const errorMessage = errorResponse.message;
      return toast.error(errorMessage);
    }

    const result = await response.json();
    toast.success("Flows recovered successfully! Please refresh the page");
    return result;
  } catch (error) {
    toast.error(error.message);
    return null;
  }
};


const delete_flow_permanently = async (data) => {
  try {
    const response = await fetch(base_url + "/automation/recyclebin/delete", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        access_token: access_token,
      },
      body: data,
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      const errorMessage = errorResponse.message;
      return toast.error(errorMessage);
    }

    const result = await response.json();
    toast.success("Flows Deleted permanently! Please refresh the page");
    return result;
  } catch (error) {
    toast.error(error.message);
    return null;
  }
};


const get_contacts = async (filter) => {
  try {
    const response = await fetch(base_url + "/contact?filter="+filter, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        access_token: access_token,
      },
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      const errorMessage = errorResponse.message;
      return toast.error(errorMessage);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    toast.error(error.message);
    return null;
  }
};


const contact_action = async (data) => {
  try {
    const response = await fetch(base_url + "/contact/action", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        access_token: access_token,
      },
      body: data
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      const errorMessage = errorResponse.message;
      return toast.error(errorMessage);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    toast.error(error.message);
    return null;
  }
}


const get_messages = async (contact_id, timestamp, sort_by, limit = 100) => {
  let url = base_url + `/message/${contact_id}`;

  if (timestamp) {
    url = base_url + `/message/${contact_id}?timestamp=${timestamp}&sort_by=${sort_by}&limit=${limit}`;
  }

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        access_token: access_token,
      },
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      const errorMessage = errorResponse.message;
      return toast.error(errorMessage);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    toast.error(error.message);
    return null;
  }
};


const send_message = async (data) => {
  try {
    const response = await fetch(base_url + "/message", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        access_token: access_token,
      },
      body: data,
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      const errorMessage = errorResponse.message;
      return toast.error(errorMessage);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    toast.error(error.message);
    return null;
  }
};



const get_datas = async () => {
  try {
    const response = await fetch(`${base_url}/data`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        access_token: access_token,
      },
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      const errorMessage = errorResponse.message;
      return toast.error(errorMessage);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    toast.error(error.message);
    return null;
  }
};


const create_data = async (data) => {
  try {
    const response = await fetch(`${base_url}/data`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        access_token: access_token,
      },
      body: data,
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      const errorMessage = errorResponse.message;
      return toast.error(errorMessage);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    toast.error(error.message);
    return null;
  }
};



const rename_data = async (data_id, data) => {
  try {
    const response = await fetch(`${base_url}/data/${data_id}/rename`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        access_token: access_token,
      },
      body: data,
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      const errorMessage = errorResponse.message;
      return toast.error(errorMessage);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    toast.error(error.message);
    return null;
  }
};




const delete_data = async (data_id) => {
  try {
    const response = await fetch(`${base_url}/data/${data_id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        access_token: access_token,
      },
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      const errorMessage = errorResponse.message;
      return toast.error(errorMessage);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    toast.error(error.message);
    return null;
  }
};


const create_data_item = async (data_id, data) => {
  try {
    const response = await fetch(`${base_url}/data/${data_id}/add`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        access_token: access_token,
      },
      body: data,
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      const errorMessage = errorResponse.message;
      toast.error(errorMessage);
      return 
    }

    const result = await response.json();
    return result;
  } catch (error) {
    toast.error(error.message);
    return null;
  }
};




const delete_data_item = async (data_id, data_item_id) => {
  try {
    const response = await fetch(`${base_url}/data/${data_id}/delete/${data_item_id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        access_token: access_token,
      },
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      const errorMessage = errorResponse.message;
      return toast.error(errorMessage);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    toast.error(error.message);
    return null;
  }
};



const test_api_endpoint = async (data) => {
  try {
    const response = await fetch(base_url + "/test/api", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        access_token: access_token,
      },
      body: data,
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      const errorMessage = errorResponse.message;
      return toast.error(errorMessage);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    toast.error(error.message);
    return null;
  }
};



const get_upload_url = async (objKey) => {
  try {
    const response = await fetch(base_url + `/get_upload_url?blob_name=${objKey}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        access_token: access_token,
      },
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      const errorMessage = errorResponse.message;
      return toast.error(errorMessage);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    toast.error(error.message);
    return null;
  }
};



const delete_file = async (file_url) => {
  try {
    const response = await fetch(base_url + `/delete_file?file_url=${file_url}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        access_token: access_token,
      },
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      const errorMessage = errorResponse.message;
      return toast.error(errorMessage);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    toast.error(error.message);
    return null;
  }
};




const get_settings = async () => {
  try {
    const response = await fetch(base_url + "/setting", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        access_token: access_token,
      },
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      const errorMessage = errorResponse.message;
      return toast.error(errorMessage);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    toast.error(error.message);
    return null;
  }
};




const update_settings = async (data) => {
  try {
    const response = await fetch(base_url + "/setting", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        access_token: access_token,
      },
      body: data
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      const errorMessage = errorResponse.message;
      return toast.error(errorMessage);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    toast.error(error.message);
    return null;
  }
};


const get_subscription = async () => {
  try {
    const response = await fetch(base_url + "/subscription", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        access_token: access_token,
      },
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      const errorMessage = errorResponse.message;
      return toast.error(errorMessage);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    toast.error(error.message);
    return null;
  }
};



const create_subscription = async (data) => {
  try {
    const response = await fetch(base_url + "/subscription", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        access_token: access_token,
      },
      body: data
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      const errorMessage = errorResponse.message;
      return toast.error(errorMessage);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    toast.error(error.message);
    return null;
  }
};




const get_templates = async (bot_id) => {
  try {
    const response = await fetch(base_url + `/template?bot_id=${bot_id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        access_token: access_token,
      },
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      const errorMessage = errorResponse.message;
      return toast.error(errorMessage);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    toast.error(error.message);
    return null;
  }
};






const get_template_detail = async (bot_id, template_name) => {
  try {
    const response = await fetch(base_url + `/template/${template_name}?bot_id=${bot_id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        access_token: access_token,
      },
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      const errorMessage = errorResponse.message;
      return toast.error(errorMessage);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    toast.error(error.message);
    return null;
  }
};




const update_template = async (bot_id, data) => {
  try {
    const response = await fetch(base_url + `/template/${bot_id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        access_token: access_token,
      },
      body: data
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      const errorMessage = errorResponse.message;
      return toast.error(errorMessage);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    toast.error(error.message);
    return null;
  }
};


const get_timestamp = async () => {
  try {
    const response = await fetch(base_url + `/timestamp`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        access_token: access_token,
      },
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      const errorMessage = errorResponse.message;
      return toast.error(errorMessage);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    toast.error(error.message);
    return null;
  }
};




const get_broadcasts = async (bot_id) => {
  try {
    const response = await fetch(base_url + `/broadcast?bot_id=${bot_id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        access_token: access_token,
      },
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      const errorMessage = errorResponse.message;
      return toast.error(errorMessage);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    toast.error(error.message);
    return null;
  }
};


const run_broadcast = async (broadcast_id) => {
  try {
    const response = await fetch(base_url + `/broadcast/${broadcast_id}/run`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        access_token: access_token,
      },
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      const errorMessage = errorResponse.message;
      return toast.error(errorMessage);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    toast.error(error.message);
    return null;
  }
};




export {verifyemail, resendcode, change_password, update_bot, get_bot, signup, login, get_messages, send_message, test_api_endpoint, create_node, get_settings, update_settings, delete_data, rename_data, contact_action, delete_file,
   delete_node, delete_flow, recover_flow, get_contacts, delete_flow_permanently, get_datas, create_data, create_data_item, delete_data_item, get_bots, get_subscription, create_subscription, get_upload_url,
    get_templates, get_template_detail, update_template, get_timestamp, get_broadcasts, run_broadcast, copy_flow };
