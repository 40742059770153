import React, {useState, useEffect, useRef} from "react";
import {Button, Input, Radio, Space, Select, message as toast} from "antd";
import {get_datas} from "../../../apis/index";
import "./Drawer.css";
import CustomSelect from "../buttons/custom_select";

const ChatgptDrawer = ({onNodeChange, node, onNodeDelete, loading, variables, setVariables}) => {
  const [value, setValue] = useState("chat_completion");
  const textareaRef = useRef(null);
  const [message, setMessage] = useState("");
  const [showAddvariable, setShowAddvariable] = useState(false);
  const [phone_numbers, setPhone_numbers] = useState("");
  const [datas, setDatas] = useState([]);
  const [data, setData] = useState({});

  const models = ["gpt-4-turbo-preview", "gpt-4", "gpt-3.5-turbo-16k", "gpt-3.5-turbo-0125", "gpt-3.5-turbo"]

  // {plugin: {mode: "select_specific", plugins: ["lslls", "lsls"]}

  useEffect(() => {
    setData(node.data);
    // if(type){
    //   setValue(type)
    // }

    // if(prompt){
    //   setPrompt(prompt)
    // }

    // if(temperature){
    //   setTemperature(temperature)
    // }

    const onLoad = async () => {
      let datas = await get_datas();
      if (datas) {
        if (datas.data) {
          setDatas(datas.data);
        }
      }
    };

    onLoad();
  }, [node.id]);

  const onChange = (e) => {
    setData({...data, type: e.target.value});
  };

  const handle_data_source_insert = (selectedValue) => {
    setData({...data, data_source: selectedValue});
  };

  const plugin_yes_or_no = (e) => {
    setData({...data, use_plugin: e.target.value});
  }

  const handle_model_change = (model) => {
    setData({...data, model});
  }

  // const handleInsertClick = (selectedValue) => {
  //   const textarea = document.getElementById("myTextarea");
  //   const {selectionStart, selectionEnd, value} = textarea;
  //   const newValue = value.substring(0, selectionStart) + "{{" + selectedValue + "}}" + value.substring(selectionEnd);
  //   textarea.value = newValue;
  //   setMessage(newValue);
  //   textarea.setSelectionRange(selectionStart + selectedValue.length, selectionStart + selectedValue.length);
  //   setShowAddvariable(false);
  // };

  useEffect(() => {
    const updatedNode = {
      ...node,
      data,
    };
    onNodeChange(updatedNode);
  }, [data]);

  return (
    <div className="action-drawer-container">
      <h3>Choose an action</h3>
      <Radio.Group style={{width: "100%", paddingBottom: "64px"}} onChange={onChange} value={data.type}>
        <Space style={{width: "100%"}} direction="vertical">
          <div className="list-item">
            <Radio value={"chat_completion"}>Chats Completion</Radio>
            <p className="para">Automatically send chatgpt response</p>

            {data.type == "chat_completion" && (
              <div style={{marginLeft: "1.5rem", marginTop: "12px"}}>
                <p className="para1">{"Plugins"}</p>
             
                <Radio.Group style={{width: "100%", paddingBottom: "20px", paddingTop: "10px"}} onChange={plugin_yes_or_no} value={data.use_plugin}>
                  <Radio value={"yes"}>Use Plugins</Radio>

                  <Radio value={"no"}>Do not use Plugins</Radio>
                
                </Radio.Group>


                <p className="para1">Select Model</p>
                <Select placeholder="Select Model" defaultValue={"gpt-3.5-turbo"} value={data.model} onChange={handle_model_change} style={{width: "100%"}}>
                  {models.map((model) => (
                    <Select.Option key={model} value={model}>
                      {model}
                    </Select.Option>
                  ))}
                </Select>

                <p className="para1">{"Select Data Source (Optional)"}</p>
                <Select placeholder="Select Data Source" value={data.data_source} onChange={handle_data_source_insert} style={{width: "100%"}}>
                  {datas.map((data) => (
                    <Select.Option key={data.name} value={data.id}>
                      {data.name}
                    </Select.Option>
                  ))}
                </Select>

                <p className="para1">{"Edit prompt (optional)"}</p>
                <Input.TextArea
                  value={data.prompt}
                  onChange={(e) => {
                    setData({...data, prompt: e.target.value});
                  }}
                ></Input.TextArea>

                <p className="para1">{"Edit temperature (optional)"}</p>
                <Input
                  value={data.temperature}
                  onChange={(e) => {
                    setData({...data, temperature: e.target.value});
                  }}
                ></Input>

        <p className="para1">{"Edit max tokens (optional)"}</p>
                <Input
                  value={data.max_tokens}
                  onChange={(e) => {
                    setData({...data, max_tokens: e.target.value});
                  }}
                ></Input>

                {showAddvariable && (
                  <Select placeholder="Select Variable" defaultOpen showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0} onChange={handleInsertClick} style={{width: "100%"}}>
                    {variables.map((option) => (
                      <Select.Option key={option.name} value={option.name}>
                        {option.name}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </div>
            )}
          </div>

          {/* <div className="list-item">
            <Radio value={"generate_response"}>Generate reply and save as variable</Radio>
            <p className="para">{`(doesn't get automatically sent)`}</p>

            {data.type == "generate_response" && (
              <div style={{marginLeft: "1.5rem", marginTop: "12px"}}>
                <p className="para1">{"Select Data Source (Optional)"}</p>
                <Select placeholder="Select Data Source" value={data.data_source} onChange={handle_data_source_insert} style={{width: "100%"}}>
                  {datas.map((data) => (
                    <Select.Option key={data.name} value={data.id}>
                      {data.name}
                    </Select.Option>
                  ))}
                </Select>

                <p className="para1">{"Edit prompt (optional)"}</p>
                <Input.TextArea
                  value={data.prompt}
                  onChange={(e) => {
                    setData({...data, prompt: e.target.value});
                  }}
                ></Input.TextArea>

                <p className="para1">{"Edit temperature (optional)"}</p>
                <Input
                  value={data.temperature}
                  onChange={(e) => {
                    setData({...data, temperature: e.target.value});
                  }}
                ></Input>

                <p className="para1">{"Save As (optional)"}</p>
                <CustomSelect
                  value={data.save_as}
                  variables={variables}
                  setVariables={setVariables}
                  onChange={(value) => {
                    setData({...data, save_as: value});
                  }}
                />
              </div>
            )}
          </div> */}
        </Space>
      </Radio.Group>
    </div>
  );
};

export default ChatgptDrawer;

