import { ActionTypes } from "../constants/actionTypes";

export const onMouseEnter = (id) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.ON_MOUSE_ENTER,
      payload: { id },
    });
  };
};
export const onMouseLeave = () => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.ON_MOUSE_ENTER,
      payload: { },
    });
  };
};
export const onHandleDragEnd = (event) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.ON_MOUSE_ENTER,
      payload: { },
    });
  };
};

export const onEdgeAdd = (conn, edges) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.ON_EDGE_ADD,
      payload: { conn, edges },
    });
  };
};

export const onNodeChange = (changes, nodes) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.ON_CHANGE_NODE,
      payload: { changes, nodes },
    });
  };
};

export const onNewNode = (node) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.ON_NEW_NODE,
      payload: { node },
    });
  };
};

export const onDeleteNode = (updatedNodeList) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.ON_DELETE_NODE,
      payload: { updatedNodeList },
    });
  };
};

export const onUpdateNode = (newList) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.ON_UPDATE_NODE,
      payload: { newList },
    });
  };
};
