// import { useCallback } from 'react';
// import { useStore, getBezierPath } from 'reactflow';

// import { getEdgeParams } from './utils.js';

// function FloatingEdge({ id, source, target, markerEnd, style }) {
//   const sourceNode = useStore(useCallback((store) => store.nodeInternals.get(source), [source]));
//   const targetNode = useStore(useCallback((store) => store.nodeInternals.get(target), [target]));

//   if (!sourceNode || !targetNode) {
//     return null;
//   }

//   const { sx, sy, tx, ty } = getEdgeParams(sourceNode, targetNode);

//   const [edgePath] = getBezierPath({
//     sourceX: sx,
//     sourceY: sy,
//     targetX: tx,
//     targetY: ty,
//   });

//   return (
//     <path
//       id={id}
//       className="react-flow__edge-path"
//       d={edgePath}
//       markerEnd={markerEnd}
//       style={style}
//       onClick={(e) => {
//         console.log("clciked")
//       }}
//     />
//   );
// }

// export default FloatingEdge;

import React, {useState} from 'react';
import { EdgeProps, getBezierPath, EdgeLabelRenderer, BaseEdge } from 'reactflow';
import { CloseOutlined } from '@ant-design/icons';


const CustomEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  data
}) => {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <>
      <BaseEdge id={id} path={edgePath}  style={{
          strokeWidth: 3, 
          stroke: 'rgb(161,170,187)',
        }}/>
      <EdgeLabelRenderer>
        <div
          style={{
            position: 'absolute',
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            padding: 10,
            borderRadius: 5,
            fontSize: 12,
            fontWeight: 700,
            color: "red",
          }}
          className="nodrag nopan"
        >
          

            {data.isclicked == true && 
              <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: 24,
                height: 24,
                borderRadius: '50%',
                background: 'red',
                color: 'white',
              }}
            >
              <CloseOutlined />
            </div>
            }


        </div>
      </EdgeLabelRenderer>
    </>
  );
};

export default CustomEdge;
