import { Button, InputNumber, Slider } from "antd";
import React, { useState } from "react";
import "./Drawer.css";

const RandomDrawer = ({ node, onNodeChange, onNodeDelete, loading }) => {
  const [options, setOptions] = useState([
    {
      index: 0,
      value: 50,
    },
    {
      index: 1,
      value: 50,
    },
  ]);

  const onOptionAdd = () => {
    const option = {
      index: options.length,
      value: 0,
    };
    setOptions([...options, option]);
  };

  const onInputChange = (e, item) => {
    const { index, value } = item;
    let newValue = e;

    const oIndex = options.findIndex((opt) => opt.index === index);
    if (index === -1) return;
    // let quota = 0;
    // options.map((o) => {
    //   quota += parseInt(o.value);
    // });
    const updatedOption = {
      index: oIndex,
      value: newValue,
    };
    const list = [...options];
    list.splice(index, 1, updatedOption);
    setOptions(list);
  };


  useEffect(() => {
    const updatedNode = {
      ...node,
      data: {
        options
      },
    };
    onNodeChange(updatedNode);
}, [options]);


  return (
    <div>
      <p style={{ fontSize: "16px", fontWeight: "500" }}>
        Split flow randomly with thresholds
      </p>
      {options.length > 0 &&
        options.map((item) => (
          <div
            key={item.index}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "12px",
              background: "#eee",
              padding: "8px",
              borderRadius: "8px",
            }}
          >
            <h3 style={{ margin: 0 }}>Option {item.index + 1}</h3>
            <Slider
              style={{ width: "35%" }}
              min={1}
              max={100}
              onChange={(e) => onInputChange(e, item)}
              value={item.value}
            />
            <InputNumber
              min={1}
              max={100}
              value={item.value}
              onChange={(e) => onInputChange(e, item)}
            />
          </div>
        ))}
      <p
        style={{
          textDecoration: "underline",
          fontWeight: "500",
          color: "#3782fa",
          cursor: "pointer",
        }}
        onClick={onOptionAdd}
      >
        Add new condition
      </p>

    </div>
  );
};

export default RandomDrawer;
