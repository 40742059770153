import React, { useState, useEffect } from 'react'
import Sidebar from '../sidebar'
import { Helmet } from 'react-helmet'
import Card from './Card'
import LongCard from './LongCard'
import Analytics from './Analytics'

function Dashboard() {
  const details = JSON.parse(localStorage.getItem("user"))

  useEffect(() => {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href =  "../../tailwind.css"

    document.head.appendChild(link);

    return () => {
      document.head.removeChild(link);
    };
  }, []); 

  const [parent, setParent] = useState(true)

  const handleAnalytics = () => {

      setParent(parent => !parent)
  }
  return (
    <div className="w-[100%]">
        <Helmet>
            <title>Settings - 50chats Ai No-Code Chatbot Builder</title>
            <meta name="description" content="Empower your business with 50chats, the No-Code Chatbot Builder. Create powerful chatbots effortlessly" />
            <meta property="og:title" content="livechat - 50chats No-Code Chatbot Builder" />
            <meta property="og:description" content="Empower your business with 50chats, the No-Code Chatbot Builder. Create powerful chatbots effortlessly" />
        </Helmet>
        <div className="w-[100vw] h-[100%] flex m-0 bg-[#F3F3F3]">
            <Sidebar></Sidebar>
            <div className="w-[100%] bg-white min-h-[100%] lg:px-[3vw]">
                <div className="h-[70px] border-b-2 w-[100%] flex justify-between items-center m-0 p-0 px-[3vw]">
                    <h2 className="livechat-text font-medium text-[1.1em]">Home</h2>
                    <img src='./user-thumbnail-200w-200w.png' className="w-8 h-8"/>
                </div>
                {parent ? <><div className='lg:w-[80%] ml-[3vw] my-[3vh] gap-[3vw] h-[10vh] items-center w-[90%] overflow-auto'>
                    <h2 className='font-bold text-[1.4em]' >Hello, {details.last_name}!</h2>
                    <h2 className='font-semibold text-[1em]' >Welcome Back And Pick Up Where You Left</h2>
                </div>
                {/* <h2 className='text-[#705CF6] font-semibold text-[1em] text-end lg:w-[80%] md:w-[90%] w-[90%]'>All Templates</h2> */}
                {/* <div className='flex flex-col lg:w-[90%] w-[100%]'>
                  <div className='flex flex-wrap mb-10 md:p-4 gap-4 ml-[3vw] justify-evenly'>
                      <div className='flex flex-col justify-center items-center rounded-md bg-[#705CF6] h-[35vh] lg:w-[15vw] md:w-[23vw] sm:w-[30vw] w-[45vw] text-white p-4 text-center'>
                          <img src='/assets/add-circle.svg'/>
                          <h2>Create bot from Blank Template</h2>
                      </div>
                      <Card path={'/assets/image 28.png'} text={"Capture customer data with Lead Magnet"} install={4136}/>
                      <Card path={'/assets/image 29.png'} text={"Redirect Customers to your Website"}install={4228}/>
                      <Card path={'/assets/image 30.png'} text={"Use a Quiz to Qualify Leads"} install={4270}/>
                  </div>
                  <div className='flex flex-wrap mb-10 md:px-4 gap-4 ml-[3vw] justify-evenly'>
                      <LongCard>  
                        <h2 className='px-4 pt-4 font-semibold text-[0.9em] m-0 '>Overall Performance</h2>
                          <img className='w-[100%] h-[70%]' src='/assets/Group 1.png'/>
                        <h2 className='text-center text-[#705CF6] underline underline-offset-1 font-semibold text-[0.9em] cursor-pointer' onClick={handleAnalytics}>See Detailed Report</h2>
                      </LongCard>
                      <LongCard>  
                        <h2 className='px-4 pt-4 font-semibold text-[0.9em] m-0 '>Conversation Log</h2>
                        <div className='flex flex-col items-center gap-1'>
                            <h1 className='text-[1.6em] font-semibold'>430</h1>
                            <h3 className='text-[0.9em] font-medium'>Conversation History</h3>
                            <h1 className='text-[1.6em] font-semibold'>21</h1>
                            <h3 className='text-[0.9em] font-medium'>Active Chats</h3>
                            <h1 className='text-[1.6em] font-semibold'>03</h1>
                            <h3 className='text-[0.9em] font-medium'>Need Attendion</h3>
                        </div>
                      </LongCard>
                      <LongCard>  
                        <img src='/assets/Rectangle 90.png'/>
                        <h2 className='px-4 font-medium text-[0.9em] mb-10'>Learn how to connect a website chat widget</h2>
                        <img className='relative bottom-[30vh] left-24 w-10 h-10' src='/assets/play-button.svg'/>
                      </LongCard>
                      <LongCard>  
                        <h2 className='px-4 font-medium text-[0.9em] mb-10 pt-4'>Useful Resources</h2>
                        <div className='flex items-center justify-start gap-2 w-[80%] mx-auto'>
                            <img src="/assets/support.svg"/>
                            <h2 className='text-center text-[#705CF6] font-medium text-[0.9em]'>Help Centre</h2>
                        </div>
                        <div className='flex items-center justify-start gap-2 w-[80%] mx-auto'>
                            <img src='/assets/2users.svg'/>
                            <h2 className='text-center text-[#705CF6] font-medium text-[0.9em]'>50 Chats Community</h2>
                        </div>
                        <div className='flex items-center justify-start gap-2 w-[80%] mx-auto'>
                            <img src='/assets/find.svg'/>
                            <h2 className='text-center text-[#705CF6] font-medium text-[0.9em]'>Hire an Agency</h2>
                        </div>
                      </LongCard>
                  </div>
                </div> */}
               </>
                :
                <Analytics/>}
            </div>
        </div>
    </div>
  )
}

export default Dashboard