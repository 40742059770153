import React, {useState, useEffect} from "react";
import uuid from "react-uuid";
import {v4 as uuidv4} from "uuid";
import TimingModal from "./TimingModal";
import DynamicModal from "./DynamicModal";
import {Modal, Select} from "antd";
import {useHistory} from "react-router-dom";
import {create_node} from "../../../apis/index";

function Sequence({HandleCreateNode, sequence, setSequence, chatbot, bot_id}) {
  const history = useHistory();

  // const [sequence, setSequence] = useState([
  //   {
  //     id: 1,
  //     send_after: {unit: "day", value: 1},
  //     isActive: false,
  //     Sent: 0,
  //     target_id: "",
  //   },
  //   {
  //     id: 2,
  //     send_after: {unit: "day", value: 1},
  //     isActive: false,
  //     Sent: 0,
  //     target_id: "",
  //   },
  //   {
  //     id: 3,
  //     send_after: {unit: "day", value: 1},
  //     isActive: false,
  //     Sent: 0,
  //     target_id: "",
  //   },
  //   {
  //     id: 4,
  //     send_after: {unit: "hour", value: 1},
  //     isActive: false,
  //     Sent: 0,
  //     target_id: "",
  //   },
  // ]);

  const [showModal, setShowModal] = useState(false);
  const [select_modal, setSelect_modal] = useState(false);
  const [reply, setReply] = useState("");
  const [replyModal, setReplyModal] = useState(false);
  const [currentSequence, setCurrentSequence] = useState();
  const toggleClass = " transform translate-x-5";
  const [current_item_index, setCurrent_item_index] = useState(null);

  const HandleSelectModalClose = () => {
    setSelect_modal(false);
  };

  const HandleSelectModalOpen = (index) => {
    setCurrent_item_index(index);
    setSelect_modal(true);
  };

  const HandleTargetFlowSelect = (value) => {
    let newArrays = [...sequence];
    newArrays[current_item_index].target_id = value;
    setSequence(newArrays);
    setSelect_modal(false);
  };

  const handleSequence = () => {
    let new_array = [...sequence];
    let maxObject = new_array.reduce((max, current) => (current.id > max.id ? current : max), sequence[0]);
    let max_id = 1;
    if (maxObject) {
      max_id = maxObject.id + 1;
    }

    setSequence((prev) => [
      ...prev,
      {
        id: max_id,
        send_after: {unit: "day", value: 1, at: "00:00"},
        isActive: false,
        target_id: "",
        Sent: 0,
      },
    ]);
  };

  function get_flow_name(target_id) {
    const flow = chatbot.flows.find((flow) => flow.id === target_id);
    return flow ? flow.name : "----------";
  }

  const handleDelete = (id) => {
    setSequence((sequence) => sequence.filter((seq) => seq.id != id));
  };

  const Navigate_to_flow = (target_id) => {
    const to = `/${bot_id}/flowchart/${target_id}`;
    history.push(to);
  };

  const toggleSwitch = (id) => {
    const newArray = [...sequence];
    const index = newArray.findIndex((obj) => obj?.id === id);
    if (index !== -1) {
      newArray[index] = {
        ...newArray[index],
        ...{isActive: !newArray[index]["isActive"]},
      };
    }
    setSequence(newArray);
  };

  const handleTiming = (id) => {
    setCurrentSequence(id);
    setShowModal(true);
  };

  const handleReplyModal = (id) => {
    setCurrentSequence(id);
    setReplyModal(true);
  };

  const handleCreateReply = () => {
    const newArray = [...sequence];
    const index = newArray.findIndex((obj) => obj?.id === currentSequence);
    if (index !== -1) {
      newArray[index] = {...newArray[index], ...{Message: reply}};
    }
    setSequence(newArray);
    setReply("");
    setReplyModal(false);

    console.log(newArray);
  };

  const handleModal = (e) => {
    if (e.target.classList.contains("modal") && (showModal || replyModal)) {
      setShowModal(false);
      setReplyModal(false);
    }
  };

  const HandleCreateNewFlow = async (id, index) => {
    let new_node = {name: `Sequence${id}`, bot_id: bot_id};
    new_node["last_modified"] = new Date().toISOString();
    new_node['type'] = 'Flow'
    new_node["id"] = `${new_node.type.toLowerCase()}-${uuidv4().replace(/-/g, "")}`;
    await create_node(JSON.stringify(new_node));
    let flow_id = new_node.id
    let newArrays = [...sequence];
    newArrays[index].target_id = flow_id;
    setSequence(newArrays);
  };

  return (
    <div className="flex flex-col w-[100%] overflow-visible text-center justify-center items-center" onClick={handleModal}>
      {/* <div className='w-[2px] bg-slate-300 h-[60%] absolute left-[30%] top-[30%]'></div> */}
      <div className="flex w-[90%] justify-center h-[5vh] text-[1.1em] font-semibold">
        <h2 className="text-gray-700 w-[15vw]">Schedule</h2>
        <h2 className="text-gray-700 w-[35vw] text-left pl-[5vw]">Active</h2>
        <div className="flex justify-between w-[30%] mr-10">
          {/* <h2 className="text-gray-700">Sent</h2> */}
        </div>
        <div className="w-10"></div>
      </div>
      {currentSequence && <TimingModal showModal={showModal} setShowModal={setShowModal} sequence={sequence} setSequence={setSequence} currentSequence={currentSequence} />}

      {replyModal && (
        <DynamicModal>
          <div className="modal justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-xl ">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-gray-100 outline-none focus:outline-none p-10">
                <h2 className="mb-6">Create New Reply</h2>
                <textarea type="text" className="outline-none min-h-[15vh] border p-4 rounded-md mr-6" value={reply} onChange={(e) => setReply(e.target.value)} />
                <div className="flex items-center justify-center p-6 rounded-md">
                  <button className="bg-sky-500 text-white active:bg-sky-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" onClick={handleCreateReply}>
                    Save And Create Reply
                  </button>
                </div>
              </div>
            </div>
          </div>
        </DynamicModal>
      )}
      {sequence &&
        sequence.map((value, ind) => (
          <div className="flex w-[90%] items-center justify-around h-[7vh] mb-10 " key={ind}>
            <h2 className="w-[15vw] underline underline-offset-4 cursor-pointer" onClick={() => handleTiming(value["id"])}>
              {"After " + value["send_after"]["value"] + " " + value["send_after"]["unit"]}
            </h2>
            {/* <h2>{value["Active"]}</h2> */}
            <div className="bg-white w-[80%] flex h-[7vh] justify-around items-center">
              <div className="flex w-[30vw]">
                <div
                  className={`md:w-12 md:h-7 w-12 h-4 flex items-center mr-6 ${!value["isActive"] ? "bg-gray-400" : "bg-green-400"} rounded-full p-1 cursor-pointer`}
                  onClick={() => {
                    toggleSwitch(value["id"]);
                  }}
                >
                  <div className={`${!value["isActive"] ? "bg-gray-600" : "bg-white"} md:w-6 md:h-6 h-5 w-5 rounded-full shadow-md transform duration-300 ease-in-out` + (!value["isActive"] ? null : toggleClass)}></div>
                </div>
                {value["target_id"] ? (
                  <>
                    <h2 className="text-gray-500 text-[1.1em] font-medium">Send</h2>
                    <h2 className="ml-4" style={{cursor: "pointer"}} onClick={() => Navigate_to_flow(value.target_id)}>
                      {get_flow_name(value["target_id"])}
                    </h2>
                  </>
                ) : (
                  <>
                    <h2 className="text-sky-500 text-[1.1em] font-medium underline underline-offset-2 cursor-pointer" onClick={() => HandleCreateNewFlow(value.id, ind)}>
                      Create New Flow
                    </h2>
                    <h2 className="mx-3">or</h2>
                    <h2 className="text-sky-500 text-[1.1em] font-medium underline underline-offset-2 cursor-pointer" onClick={() => HandleSelectModalOpen(ind)}>
                      {" Select Existing"}
                    </h2>
                  </>
                )}
              </div>
              <div className="flex justify-between w-[30%]">
                {/* <h2>{value["Sent"]}</h2> */}
              </div>
              <svg viewBox="0 0 804.5714285714286 1024" className="w-4" style={{cursor: "pointer"}} onClick={() => handleDelete(value["id"])}>
                <path d="M292.571 786.286v-402.286c0-10.286-8-18.286-18.286-18.286h-36.571c-10.286 0-18.286 8-18.286 18.286v402.286c0 10.286 8 18.286 18.286 18.286h36.571c10.286 0 18.286-8 18.286-18.286zM438.857 786.286v-402.286c0-10.286-8-18.286-18.286-18.286h-36.571c-10.286 0-18.286 8-18.286 18.286v402.286c0 10.286 8 18.286 18.286 18.286h36.571c10.286 0 18.286-8 18.286-18.286zM585.143 786.286v-402.286c0-10.286-8-18.286-18.286-18.286h-36.571c-10.286 0-18.286 8-18.286 18.286v402.286c0 10.286 8 18.286 18.286 18.286h36.571c10.286 0 18.286-8 18.286-18.286zM274.286 219.429h256l-27.429-66.857c-1.714-2.286-6.857-5.714-9.714-6.286h-181.143c-3.429 0.571-8 4-9.714 6.286zM804.571 237.714v36.571c0 10.286-8 18.286-18.286 18.286h-54.857v541.714c0 62.857-41.143 116.571-91.429 116.571h-475.429c-50.286 0-91.429-51.429-91.429-114.286v-544h-54.857c-10.286 0-18.286-8-18.286-18.286v-36.571c0-10.286 8-18.286 18.286-18.286h176.571l40-95.429c11.429-28 45.714-50.857 76-50.857h182.857c30.286 0 64.571 22.857 76 50.857l40 95.429h176.571c10.286 0 18.286 8 18.286 18.286z"></path>
              </svg>
            </div>
          </div>
        ))}
      <button onClick={handleSequence} className="border bg-white p-4">
        + Message
      </button>

      <Modal open={select_modal} onCancel={HandleSelectModalClose} title="Select A Flow">
        <Select
          value={""}
          onChange={(value) => {
            HandleTargetFlowSelect(value);
          }}
          style={{width: "100%"}}
          placeholder="Select target flow"
          options={chatbot.flows.map((flow) => ({
            label: flow.name, // Replace 'customLabel' with the property name you want to use as the label
            value: flow.id, // Replace 'customValue' with the property name you want to use as the value
          }))}
        />{" "}
      </Modal>
    </div>
  );
}

export default Sequence;
