import PluginItem from "./plugin_item"
import { Popconfirm, Button, message as toast} from "antd";
import {useState} from "react"
import {delete_flow} from "../../../apis/index"

const Plugin = ({getSelectedFlowsCount, HandleEditNode, chatbot, setChatbot, bot_id }) => {
  const [loading, setLoading] = useState(false)
  const [selectAll, setSelectAll] = useState(false);

  const handleFlowSelect = (id) => {
    chatbot.plugins = chatbot.plugins.map((plugin) => (plugin.id === id ? {...plugin, selected: !plugin.selected} : plugin));
    setChatbot({...chatbot});
  };

  const HandleDeleteFlow = async () => {
    setLoading(true);

    let errorOccurred = false;
    let selected_flows = chatbot.plugins.filter((plugin) => plugin.selected).map((plugin) => plugin.id);
    toast.info("deleting");
    for (let i = 0; i < selected_flows.length; i++) {
      const flowId = selected_flows[i];
      let response = await delete_flow(flowId);
      if (!response) {
        errorOccurred = true;
        setLoading(false);
        break;
      }
      chatbot.plugins = chatbot.plugins.filter((plugin) => plugin.id !== flowId);
      setChatbot({...chatbot});
    }

    if (errorOccurred) {
      toast.error("Error deleting");
    } else {
      toast.success("Deleted");
    }
    setLoading(false);
    setSelectAll(false);
  };


  
  const handleSelectAll = () => {
    const updatedSelectAll = !selectAll;
    setSelectAll(updatedSelectAll);

    chatbot.plugins = chatbot.plugins.map((plugin) => ({
      ...plugin,
      selected: updatedSelectAll,
    }));
    setChatbot({...chatbot});
  };



  return (
    <div className="chatbot-flowcontainer">
      <div className="chatbot-flowheader">
        <div className="chatbot-container15">
          <span className="chatbot-text10">{getSelectedFlowsCount()} selected</span>

          <Popconfirm placement="bottomRight" title={"Confirmation"} description={"Are you sure you wanted to delete the selected flows"} onConfirm={HandleDeleteFlow} okText="Yes" cancelText="No">
            <Button loading={loading} className="chatbot-button1 button">
              Delete
            </Button>
          </Popconfirm>
        </div>
      </div>
      <div className="chatbot-flowitem">
        <div className="chatbot-container16">
          <input type="checkbox" checked={selectAll} onChange={handleSelectAll} className="chatbot-checkbox" />
        </div>
        <div className="chatbot-container17">
          <span className="chatbot-text11">Name</span>
        </div>
        <div className="chatbot-container18">
          <span className="chatbot-text12">Last Modified</span>
        </div>
      </div>
      {chatbot.plugins.length == 0 && (
        <div className="chatbot-emptyflow">
          <span className="chatbot-text13">No Data</span>
        </div>
      )}

      {chatbot.plugins.map((plugin) => (
        <PluginItem key={plugin.id} bot_id={bot_id} handleFlowSelect={handleFlowSelect} plugin={plugin} HandleEditNode={HandleEditNode} />
      ))}
    </div>
  );
};

export default Plugin