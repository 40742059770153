import {Link} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {message as toast, Form, Input, Button, Space} from "antd";
import {Helmet} from "react-helmet";
import {resendcode, change_password} from "../apis/index"
import {sleep} from "../utils/helper"

import "./changepassword.css";

const Changepassword = (props) => {
  const [countdown, setCountdown] = useState(15);
  const [countdown_restart, setCountdown_restart] = useState(false);
  const [email_disabled, setEmail_disabled] = useState(false);
  const [email, setEmail] = useState("")
  const [code, setCode] = useState("")
  const [password, setPassword] = useState("")
  const [confirm_password, setConfirm_password] = useState("")

  const Resend_Code = async () => {
    let response = await resendcode(JSON.stringify({email}))
    if(response){
      setCountdown(15);
      setCountdown_restart(!countdown_restart);
    }
  };

  const Handle_Email_Change = () => {
    setEmail_disabled(false);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown((prevCount) => {
        if (prevCount === 0) {
          clearInterval(interval); // Stop the interval when countdown reaches 0
          return prevCount; // Keep countdown at 0
        } else {
          return prevCount - 1;
        }
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [countdown_restart]);

  const input_style = {marginBottom: 10};


  const Handle_Submit = async () => {
    if(password != confirm_password){
      return toast.error("Password does not match")
    }

    let payload = JSON.stringify({email, code, password})
    let response = await change_password(payload)
    if(response){
      toast.success("password changed successfully")
      await sleep(2000)
      window.location.href ="/login"
    }
  }


  return (
    <div className="changepassword-container">
      <Helmet>
        <title>changepassword - Chatbot Builder</title>
        <meta property="og:title" content="changepassword - Chatbot Builder" />
      </Helmet>
      <div className="changepassword-container1">
        <h2 className="changepassword-text">50Chats</h2>
        <Link to="/login" className="changepassword-navlink button">
          Login
        </Link>
      </div>

      <div className="changepassword-container2">
        <div className="changepassword-container3">
          <div className="changepassword-container4">
            <h3>Reset password</h3>
          </div>
          <div className="changepassword-container5">
           

            <Input   onBlur={() => setEmail_disabled(true)} disabled={email_disabled} style={{width: "calc(100% - 90px)"}} value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Enter your email address" />

            <Button
              style={{
                width: 80,
                marginLeft: 10,
              }}
              onClick={Handle_Email_Change}
            >
              change
            </Button>
          </div>
          <Input value={code} onChange={(e) => setCode(e.target.value)} style={input_style} type="text" required placeholder="Verification Code" autoComplete="email" />
          <Input value={password} onChange={(e) => setPassword(e.target.value)} style={input_style} type="password" required placeholder="Password" />
          <Input value={confirm_password} onChange={(e) => setConfirm_password(e.target.value)} style={input_style} type="password" required placeholder="Confirm Password" />

          <div className="changepassword-container5">
            {countdown > 0 ? (
              <Button type="default">resend code in {countdown}s</Button>
            ) : (
              <Button onClick={Resend_Code} type="default">
                 send code
              </Button>
            )}

            <Button onClick={Handle_Submit} style={{marginLeft: "15px"}} type="primary" htmlType="submit">
              Submit
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Changepassword;
