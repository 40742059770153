import React, { useRef, useEffect } from 'react';
import { Button } from 'antd';

const FloatingButton = ({ onClick }) => {
  const buttonStyle = {
    position: 'relative',
    alignSelf: 'flex-end'

  };
  return (
    <div style={buttonStyle}>
      <Button onClick={onClick} type="primary">{"{ - }"}</Button>
    </div>
  );
};

export default FloatingButton;
