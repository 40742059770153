// dataState.js
import { atom } from 'recoil';

export const flow_data_state = atom({
  key: 'flow_data_state',
  default: {}
});


export const setting_state = atom({
  key: 'setting_state',
  default: {}
});
