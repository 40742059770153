import { ActionTypes } from "../constants/actionTypes";
import { throttle } from "lodash";

const GRID_SIZE = 4;

export const onDragDotStart = (event, node) => {
  return async (dispatch) => {
    const dotX = event.target.x();
    const dotY = event.target.y();
    const newPosition = { x: dotX, y: dotY };

    dispatch({
      type: ActionTypes.DOT_DRAG_START,
      payload: { newPosition, event, node },
    });
  };
};

export const onDragDotMove = (event, node) => {
  return async (dispatch) => {
    const newX = event.target.x();
    const newY = event.target.y();
    const dx = newX - node.x;
    const dy = newY - node.y;

    if (Math.abs(dx) > GRID_SIZE || Math.abs(dy) > GRID_SIZE) {
      const x = Math.round(newX / GRID_SIZE) * GRID_SIZE;
      const y = Math.round(newY / GRID_SIZE) * GRID_SIZE;
      const snapPosition = {
        x,
        y,
      };
      dispatch({
        type: ActionTypes.DOT_DRAG_MOVE,
        payload: { snapPosition, event, node },
      });
    }
  };
};

export const onDragDotEnd = (
  event,
  node,
  leftDot,
  rightDot,
  doneDot,
  errorDot,
  dragFrom,
  option
) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.DOT_DRAG_END,
      payload: {
        event,
        node,
        leftDot,
        rightDot,
        doneDot,
        errorDot,
        dragFrom,
        option,
      },
    });
  };
};

export const onApiNodeDragMove = (event, node) => {
  return async (dispatch) => {
    const newX = event.target.x();
    const newY = event.target.y();
    // const newPosition = {
    //   x: Math.round(newX / GRID_SIZE) * GRID_SIZE,
    //   y: Math.round(newY / GRID_SIZE) * GRID_SIZE,
    // };
    const newPosition = { x: newX, y: newY };

    dispatch({
      type: ActionTypes.NODE_DRAG_MOVE,
      payload: { newPosition, event, node },
    });
  };
};

export const onDragNodeMove = (event, node) => {
  return async (dispatch) => {
    const newX = event.target.x();
    const newY = event.target.y();
    const dx = newX - node.x;
    const dy = newY - node.y;
    if (Math.abs(dx) > GRID_SIZE || Math.abs(dy) > GRID_SIZE) {
      const x = Math.round(newX / GRID_SIZE) * GRID_SIZE;
      const y = Math.round(newY / GRID_SIZE) * GRID_SIZE;
      const snapPosition = {
        x,
        y,
      };
      dispatch({
        type: ActionTypes.NODE_DRAG_MOVE,
        payload: { snapPosition, event, node },
      });
    }
  };
}

export const onDragNodeEnd = (event, node) => {
  return async (dispatch) => {
    const newX = event.target.x();
    const newY = event.target.y();
    const newPosition = { x: newX, y: newY };

    dispatch({
      type: ActionTypes.NODE_DRAG_END,
      payload: { newPosition, event, node },
    });
  };
};

export const onTempLineDraw = (event) => {
  return async (dispatch) => {
    const mouseX = event.evt.clientX;
    const mouseY = event.evt.clientY;
    const newPosition = { x: mouseX, y: mouseY };
    dispatch({
      type: ActionTypes.NODE_DRAG_MOVE,
      payload: { newPosition, event },
    });
  };
};

export const onLineDraw = (event) => {
  return async (dispatch) => {
    const mouseX = event.evt.clientX;
    const mouseY = event.evt.clientY;
    const newPosition = { x: mouseX, y: mouseY };
    dispatch({
      type: ActionTypes.NODE_DRAG_MOVE,
      payload: { newPosition, event },
    });
  };
};

export const onAddNode = (nodeType) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.ON_NODE_ADD,
      payload: nodeType,
    });
  };
};

export const onMouseUp = (node) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.ON_MOUSE_UP,
      payload: node,
    });
  };
};

export const onSizeChange = (height, node) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.ON_SIZE_CHANGE,
      payload: { height, node },
    });
  };
};

export const onNodeChange = (node) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.ON_NODE_CHANGE,
      payload: node,
    });
  };
};
