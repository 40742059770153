import {Modal, Radio, message as toast, Input, Button} from "antd";
import {useState} from "react";
import uuid from "react-uuid";

const Buttons = ({button_data, setButton_data, open, onClose}) => {

  const [input_text, setInput_text] = useState("");
  const [url, setUrl] = useState("")
  const [current_btn_id, setCurrent_btn_id] = useState(null);
  const [show_edit_btn, setShow_edit_btn] = useState(false);

  const Add_Button = () => {
    if (input_text.trim() === "") {
      return toast.error("Button is empty");
    }

    const isTitleAlreadyExists = button_data.buttons.some((button) => button.title === input_text);

    if (isTitleAlreadyExists && current_btn_id == null) {
      return toast.error("Button with this title already exists");
    }

    const newButton = {id: uuid(), title: input_text};

    setButton_data((prevData) => {
      if (current_btn_id !== null) {
        // If current_btn_id is not null, update the title of the button with that ID
        const updatedButtons = prevData.buttons.map((button) => {
          if (button.id === current_btn_id) {
            if(button_data.type == "cta_url"){
              return {...button, title: input_text, url: url};
            }else{
              return {...button, title: input_text};
            }
          }
          return button;
        });

        return {
          ...prevData,
          buttons: updatedButtons,
        };
      } else {
        // Otherwise, add the new button
        return {
          ...prevData,
          buttons: [...prevData.buttons, newButton],
        };
      }
    });

    setInput_text("");
    setCurrent_btn_id(null);
    setShow_edit_btn(false);
    setUrl("")
  };

  const Handle_Edit_Button = (button) => {
    setCurrent_btn_id(button.id);
    setInput_text(button.title);
    setUrl(button.url)
    setShow_edit_btn(true);
  };

  const Handle_New_Button = () => {
    let no_of_buttons = button_data.buttons.length;
    if (button_data.type == "list" && no_of_buttons == 10) {
        return toast.error("You can not have more than 10 buttons in a list");
    } else if (button_data.type == "cta_url"  && no_of_buttons == 1) {
      return toast.error("You can not have more than 1 button in a Call to Action Button");
    }else if (button_data.type == "reply"  && no_of_buttons == 3) {
      return toast.error("You can not have more than 3 buttons in a reply Button");
    }

    setShow_edit_btn(true);
  };

  const Close_Edit_Button = () => {
    setShow_edit_btn(false);
    setCurrent_btn_id(null);
    setInput_text("");
  };

  const Handle_Button_Type_Change = (e) => {
    let value = e.target.value;

    if (button_data.buttons.length != 0) {
      toast.error("can not change the button type after buttons are being added");
      return toast.info("button type can not be mixed, you can delete the whole buttons, if you intend on changing the button type");
    }
    setButton_data((prevData) => ({
      ...prevData,
      type: value,
    }));

    setUrl(null)
    setInput_text(null)
  };

  const Handle_Button_Title_Change = (e) => {
    let value = e.target.value;
    const updatedButtonData = { ...button_data };
      updatedButtonData.title = value;  
    setButton_data(updatedButtonData);
  };

  const Handle_Delete_Button = () => {
    setButton_data((prevData) => {
      // Filter out the button with the specified ID
      const updatedButtons = prevData.buttons.filter((button) => button.id !== current_btn_id);
  
      return {
        ...prevData,
        buttons: updatedButtons,
      };
    });

    setShow_edit_btn(false)
  };


  return (
    <Modal title="Buttons" open={open} onCancel={onClose} onOk={onClose}>
      <Radio.Group value={button_data.type} onChange={Handle_Button_Type_Change}>
        <Radio value={"reply"}>Reply</Radio>

        <Radio value={"cta_url"}>Call to Action</Radio>
        <Radio value={"list"}>List</Radio>
      </Radio.Group>

      {button_data.type == "list" &&
       <Input placeholder="Button Title" value={button_data.button_title} onChange={Handle_Button_Title_Change} style={{width: "300px", marginTop: "20px"}} />
     }

      <div className="msgbtn-btncontainer">
        {button_data.buttons?.map((button) => {
          return (
            <button
              key={button.id}
              type="button"
              className="msgbtn-button button"
              onClick={() => {
                Handle_Edit_Button(button);
              }}
            >
              {button.title}
            </button>
          );
        })}

        <button type="button" className="msgbtn-button1 button" onClick={Handle_New_Button}>
          Add Button
        </button>
      </div>

      <Modal title="Edit Button" width="400px" open={show_edit_btn} onOk={Add_Button}
       footer={[<Button key="delete" style={{color: "#ff0000", borderColor: "#ff0000"}} onClick={Handle_Delete_Button}>Delete</Button>,  <Button key="ok" type="primary" onClick={Add_Button}>Ok</Button>]} 
       onCancel={Close_Edit_Button}>


        {button_data.type == "cta_url" ? (
          <>
             <span>display text</span>
            <Input value={input_text} onChange={(e) => setInput_text(e.target.value)} />
            <span>url</span>
            <Input value={url} onChange={(e) => setUrl(e.target.value)} />
          </>
        ) :(
          <Input value={input_text} onChange={(e) => setInput_text(e.target.value)} />
        ) } 
      </Modal>
    </Modal>
  );
};

export default Buttons;
