const {format_time_ago, convert_utc_to_month_date} = require("../utils/helper");
import "../views/contacts.css";

const Contact_detail = ({contact, handle_view_contact}) => {
  const variableElements = [];

  const Variables = () => {
    for (const key in contact.variables) {
      const value = contact.variables[key];

      variableElements.push(
        <div className="profile-variable" key={key}>
          <span className="profile-text28">
            <span>{key}: </span>
            <span className="profile-text30">{value}</span>
          </span>
        </div>
      );
    }
    return variableElements;
  };

  return (
    <div className="profile-contactdetail-body">
      <div className="profile-contactdetail">
        <div className="profile-closebutton-container">
          <svg viewBox="0 0 1024 1024" className="profile-icon" onClick={handle_view_contact}>
            <path d="M981.333 512c0-129.579-52.565-246.997-137.472-331.861s-202.283-137.472-331.861-137.472-246.997 52.565-331.861 137.472-137.472 202.283-137.472 331.861 52.565 246.997 137.472 331.861 202.283 137.472 331.861 137.472 246.997-52.565 331.861-137.472 137.472-202.283 137.472-331.861zM896 512c0 106.069-42.923 201.984-112.469 271.531s-165.461 112.469-271.531 112.469-201.984-42.923-271.531-112.469-112.469-165.461-112.469-271.531 42.923-201.984 112.469-271.531 165.461-112.469 271.531-112.469 201.984 42.923 271.531 112.469 112.469 165.461 112.469 271.531zM353.835 414.165l97.835 97.835-97.835 97.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l97.835-97.835 97.835 97.835c16.683 16.683 43.691 16.683 60.331 0s16.683-43.691 0-60.331l-97.835-97.835 97.835-97.835c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0l-97.835 97.835-97.835-97.835c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331z"></path>
          </svg>
        </div>
        <div className="profile-contactdetail-conteiner">
          <div className="profile-contactdetail-group">
            <div className="profile-contactdetail-left">
              <div className="profile-icon-container">
                <div className="profile-more-icon">
                  <svg viewBox="0 0 1024 1024" className="profile-icon02">
                    <path d="M597.333 512c0-23.552-9.6-44.928-25.003-60.331s-36.779-25.003-60.331-25.003-44.928 9.6-60.331 25.003-25.003 36.779-25.003 60.331 9.6 44.928 25.003 60.331 36.779 25.003 60.331 25.003 44.928-9.6 60.331-25.003 25.003-36.779 25.003-60.331zM597.333 213.333c0-23.552-9.6-44.928-25.003-60.331s-36.779-25.003-60.331-25.003-44.928 9.6-60.331 25.003-25.003 36.779-25.003 60.331 9.6 44.928 25.003 60.331 36.779 25.003 60.331 25.003 44.928-9.6 60.331-25.003 25.003-36.779 25.003-60.331zM597.333 810.667c0-23.552-9.6-44.928-25.003-60.331s-36.779-25.003-60.331-25.003-44.928 9.6-60.331 25.003-25.003 36.779-25.003 60.331 9.6 44.928 25.003 60.331 36.779 25.003 60.331 25.003 44.928-9.6 60.331-25.003 25.003-36.779 25.003-60.331z"></path>
                  </svg>
                </div>
                <img alt="image" src="/user-thumbnail-200w-200w.png" className="profile-image" />
                <div className="profile-subscribtion">
                  <svg viewBox="0 0 1024 1024" className="profile-icon04">
                    <path d="M426 726l384-384-60-62-324 324-152-152-60 60zM512 86q176 0 301 125t125 301-125 301-301 125-301-125-125-301 125-301 301-125z"></path>
                  </svg>
                  <span className="profile-text">Subscribed</span>
                </div>
              </div>
              <h1 className="profile-text01">{contact.variables.full_name}</h1>
              <div className="profile-detail">
                <div className="profile-detail-item">
                  <svg viewBox="0 0 1024 1024" className="profile-icon06">
                    <path d="M534 298v224l192 114-32 54-224-136v-256h64zM512 854q140 0 241-101t101-241-101-241-241-101-241 101-101 241 101 241 241 101zM512 86q176 0 301 125t125 301-125 301-301 125-301-125-125-301 125-301 301-125z"></path>
                  </svg>
                  <span>
                    <span className="profile-text03">Created On:</span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: " ",
                        }}
                      />
                    </span>
                    <span className="profile-text05">{convert_utc_to_month_date(contact.created_on)}</span>
                  </span>
                </div>
                <div className="profile-detail-item">
                  <svg viewBox="0 0 1024 1024" className="profile-icon06">
                    <path d="M534 298v224l192 114-32 54-224-136v-256h64zM512 854q140 0 241-101t101-241-101-241-241-101-241 101-101 241 101 241 241 101zM512 86q176 0 301 125t125 301-125 301-301 125-301-125-125-301 125-301 301-125z"></path>
                  </svg>
                  <span>
                    <span className="profile-text03">Last Messaged:</span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: " ",
                        }}
                      />
                    </span>
                    <span className="profile-text05">{convert_utc_to_month_date(contact.last_messaged)}</span>
                  </span>
                </div>
                <div className="profile-detail-item2">
                  <svg viewBox="0 0 1024 1024" className="profile-icon10">
                    <path d="M440.236 635.766c-13.31 0-26.616-5.076-36.77-15.23-95.134-95.136-95.134-249.934 0-345.070l192-192c46.088-46.086 107.36-71.466 172.534-71.466s126.448 25.38 172.536 71.464c95.132 95.136 95.132 249.934 0 345.070l-87.766 87.766c-20.308 20.308-53.23 20.308-73.54 0-20.306-20.306-20.306-53.232 0-73.54l87.766-87.766c54.584-54.586 54.584-143.404 0-197.99-26.442-26.442-61.6-41.004-98.996-41.004s-72.552 14.562-98.996 41.006l-192 191.998c-54.586 54.586-54.586 143.406 0 197.992 20.308 20.306 20.306 53.232 0 73.54-10.15 10.152-23.462 15.23-36.768 15.23z"></path>
                    <path d="M256 1012c-65.176 0-126.45-25.38-172.534-71.464-95.134-95.136-95.134-249.934 0-345.070l87.764-87.764c20.308-20.306 53.234-20.306 73.54 0 20.308 20.306 20.308 53.232 0 73.54l-87.764 87.764c-54.586 54.586-54.586 143.406 0 197.992 26.44 26.44 61.598 41.002 98.994 41.002s72.552-14.562 98.998-41.006l192-191.998c54.584-54.586 54.584-143.406 0-197.992-20.308-20.308-20.306-53.232 0-73.54 20.306-20.306 53.232-20.306 73.54 0.002 95.132 95.134 95.132 249.932 0.002 345.068l-192.002 192c-46.090 46.088-107.364 71.466-172.538 71.466z"></path>
                  </svg>
                  <span>
                    <span className="profile-text11">Channel:</span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: " ",
                        }}
                      />
                    </span>
                    <span className="profile-text13">{contact.channel}</span>
                  </span>
                </div>
                <div className="profile-container1">
                  <div className="profile-detail-item3">
                    <img alt="image" src="/whatsapp1.svg" className="profile-image1" />
                    <span>
                      <span className="profile-text15">Id:</span>
                      <span>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: " ",
                          }}
                        />
                      </span>
                      <span className="profile-text17">{contact.id}</span>
                    </span>
                  </div>
                </div>
              </div>
              <button type="button" className="profile-button button">
                Start Chat
              </button>
            </div>
            <div className="profile-contactdetail-right">
              <div className="profile-right-item">
                <span className="profile-text18">Contact Tags</span>
                <button type="button" className="profile-button1 button">
                  + Add Tags
                </button>
              </div>
              <div className="profile-right-item1">
                <span className="profile-text19">Subscribed to Sequence</span>
                <button type="button" className="profile-button2 button">
                  Subscribe
                </button>
              </div>
              <div className="profile-right-item2">
                <div className="profile-container2">
                  <span className="profile-text20">Source</span>
                </div>
                {/* <button type="button" className="profile-button3 button">
                <span>
                  <span className="profile-text22">Bot Name:</span>
                  <span> Bot1</span>
                </span>
              </button> */}
                <button type="button" className="profile-button4 button">
                  <span>
                    <span className="profile-text25">Bot Id:</span>
                    <span> d993jdiei83jdid83ijei393jk3</span>
                  </span>
                </button>
              </div>
              <div className="profile-right-item3">
                <div className="profile-head">
                  <span className="profile-text27">Custom Variables</span>
                  <button type="button" className="profile-button5 button">
                    + New Variable
                  </button>
                </div>
                <div className="profile-variables-container">
                  <Variables />
                </div>
              </div>

              {/* <button type="button" className="profile-button6 button">
                Hide All  &gt;
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact_detail;
