import {Select, Button} from "antd"

const Setting = ({chatbot, HandleSettingsSave, setChatbot, handleApiProviderChange, whatsapp_api_provider_options, create_loading}) => {
    return (
        <div className="chatbot-settingscontainer">
        {/* <div className="chatbot-header1">
          <div data-thq="thq-dropdown" className="chatbot-thq-dropdown list-item">
            <div data-thq="thq-dropdown-toggle" className="chatbot-dropdown-toggle">
              <Select value={chatbot.settings.api_provider} onChange={handleApiProviderChange} placeholder="Select api provider" options={whatsapp_api_provider_options} />
            </div>
          </div>
        </div> */}
        {/* {chatbot.settings.api_provider == "wapi" && (
          <div className="chatbot-wapi">
            <div className="chatbot-container30">
              <h4 className="chatbot-text25">Enter Your Api Credentials</h4>
            </div>
            <div className="chatbot-container31">
              <span>account_id</span>
              <div className="chatbot-container32">
                <input
                  value={chatbot.settings.account_id}
                  type="text"
                  placeholder="enter your account_id"
                  onChange={(e) => {
                    setChatbot({...chatbot, settings: {...chatbot.settings, account_id: e.target.value}});
                  }}
                  className="chatbot-textinput input"
                />
              </div>
            </div>
            <div className="chatbot-container33">
              <span>api_key</span>
              <div className="chatbot-container34">
                <input
                  value={chatbot.settings.api_key}
                  onChange={(e) => {
                    setChatbot({...chatbot, settings: {...chatbot.settings, api_key: e.target.value.trim()}});
                  }}
                  type="text"
                  placeholder="enter your api key"
                  className="chatbot-textinput1 input"
                />
              </div>
            </div>
            <div className="chatbot-container35">
              <div className="chatbot-container36">
                <Button loading={create_loading} className="chatbot-button2 button" onClick={HandleSettingsSave}>
                  Save
                </Button>
              </div>
            </div>
          </div>
        )} */}

        {/* {chatbot.settings.api_provider == "maytapi" && (
          <div className="chatbot-maytapi">
            <div className="chatbot-container37">
              <h4 className="chatbot-text28">Enter Your Api Credentials</h4>
            </div>
            <div className="chatbot-container38">
              <span>phone id</span>
              <div className="chatbot-container39">
                <input
                  value={chatbot.settings.phone_id}
                  type="text"
                  placeholder="enter your phone id"
                  onChange={(e) => {
                    setChatbot({...chatbot, settings: {...chatbot.settings, phone_id: e.target.value.trim()}});
                  }}
                  className="chatbot-textinput2 input"
                />
              </div>
            </div>
            <div className="chatbot-container40">
              <span>product id</span>
              <div className="chatbot-container41">
                <input
                  value={chatbot.settings.product_id}
                  type="text"
                  placeholder="enter your product id"
                  onChange={(e) => {
                    setChatbot({...chatbot, settings: {...chatbot.settings, product_id: e.target.value.trim()}});
                  }}
                  className="chatbot-textinput3 input"
                />
              </div>
            </div>
            <div className="chatbot-container42">
              <span>token</span>
              <div className="chatbot-container43">
                <input
                  value={chatbot.settings.token}
                  type="text"
                  placeholder="enter your token"
                  onChange={(e) => {
                    setChatbot({...chatbot, settings: {...chatbot.settings, token: e.target.value.trim()}});
                  }}
                  className="chatbot-textinput4 input"
                />
              </div>
            </div>
            <div className="chatbot-container44">
              <div className="chatbot-container45">
                <Button loading={create_loading} className="chatbot-button3 button" onClick={HandleSettingsSave}>
                  Save
                </Button>
              </div>
            </div>
          </div>
        )} */}


        
{chatbot.settings.api_provider == "whatsapp" && (
          <div className="chatbot-maytapi">
            <div className="chatbot-container37">
              <h4 className="chatbot-text28">Credentials</h4>
            </div>
            <div className="chatbot-container38">
              <span>Phone Number ID</span>
              <div className="chatbot-container39">
                <input
                  value={chatbot.settings.phone_number_id}
                  type="text"
                  placeholder="enter your phone id"
                  onChange={(e) => {
                    setChatbot({...chatbot, settings: {...chatbot.settings, phone_number_id: e.target.value.trim()}});
                  }}
                  className="chatbot-textinput2 input"
                />
              </div>
            </div>
            <div className="chatbot-container40">
              <span>Whatsapp Business Account ID</span>
              <div className="chatbot-container41">
                <input
                  value={chatbot.settings.waba_id}
                  type="text"
                  placeholder="enter your product id"
                  onChange={(e) => {
                    setChatbot({...chatbot, settings: {...chatbot.settings, waba_id: e.target.value.trim()}});
                  }}
                  className="chatbot-textinput3 input"
                />
              </div>
            </div>
            <div className="chatbot-container42">
              <span>System Access Token</span>
              <div className="chatbot-container43">
                <input
                  value={chatbot.settings.system_access_token}
                  type="text"
                  placeholder="enter your token"
                  onChange={(e) => {
                    setChatbot({...chatbot, settings: {...chatbot.settings, system_access_token: e.target.value.trim()}});
                  }}
                  className="chatbot-textinput4 input"
                />
              </div>
            </div>
            <div className="chatbot-container44">
              <div className="chatbot-container45">
                <Button loading={create_loading} className="chatbot-button3 button" onClick={HandleSettingsSave}>
                  Save
                </Button>
              </div>
            </div>
          </div>
        )}




        
        
{chatbot.settings.api_provider == "custom" && (
          <div className="chatbot-maytapi">
            <div className="chatbot-container37">
              <h4 className="chatbot-text28">Credentials</h4>
            </div>
          
            <div className="chatbot-container42">
              <span>Webhook</span>
              <div className="chatbot-container43">
                <input
                  value={chatbot.settings.system_access_token}
                  type="text"
                  placeholder="enter your webhook"
                  onChange={(e) => {
                    setChatbot({...chatbot, settings: {...chatbot.settings, webhook: e.target.value.trim()}});
                  }}
                  className="chatbot-textinput4 input"
                />
              </div>
            </div>
            <div className="chatbot-container44">
              <div className="chatbot-container45">
                <Button loading={create_loading} className="chatbot-button3 button" onClick={HandleSettingsSave}>
                  Save
                </Button>
              </div>
            </div>
          </div>
        )}



        {/* <div className="chatbot-webhook">
          <div className="chatbot-container46">
            <h4 className="chatbot-text32">Manage Webhook</h4>
          </div>
          <div className="chatbot-container47">
            <div className="chatbot-container48">
              <input
                type="text"
                value={chatbot.settings.webhook}
                onChange={(e) => {
                  setChatbot({...chatbot, settings: {...chatbot.settings, webhook: e.target.value.trim()}});
                }}
                placeholder="enter your webhook endpoint e.g (https://example.com/webhook)"
                className="chatbot-textinput5 input"
              />
            </div>
          </div>
          <div className="chatbot-container49">
            <div className="chatbot-container50">
              <Button loading={create_loading} className="chatbot-button4 button" onClick={HandleSettingsSave}>
                Save
              </Button>
            </div>
          </div>
        </div> */}
      </div>
    )
}

export default Setting