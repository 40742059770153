import {useState} from "react";

const Conversations = ({filter, setFilter, unread_conversations}) => {
  const handle_filter_change = (change) => {
    setFilter(change);
  };

  return (
    <div className="livechat-conversations">
      <div className="livechat-conversation-header">
        <h3 className="livechat-text01">Conversations</h3>
      </div>
      <div className={`${filter === "main" ? "livechat-conversation-item1" : "livechat-conversation-item"}`} onClick={() => handle_filter_change("main")}>
        <div className="livechat-container01">
          <svg viewBox="0 0 1024 1024" className="livechat-icon06">
            <path d="M512 64c282.77 0 512 186.25 512 416 0 229.752-229.23 416-512 416-27.156 0-53.81-1.734-79.824-5.044-109.978 109.978-241.25 129.7-368.176 132.596v-26.916c68.536-33.578 128-94.74 128-164.636 0-9.754-0.758-19.33-2.164-28.696-115.796-76.264-189.836-192.754-189.836-323.304 0-229.75 229.23-416 512-416z"></path>
          </svg>
          <span className="livechat-text02">Main</span>
        </div>
        <div className="livechat-container02">
          {unread_conversations > 0 && (
            <>
              <div className="livechat-container03"></div>
              <span className="livechat-text03">{unread_conversations}</span>
            </>
          )}
        </div>
      </div>
      <div className={`${filter === "unread" ? "livechat-conversation-item1" : "livechat-conversation-item"}`} onClick={() => handle_filter_change("unread")}>
        <div className="livechat-container04">
          <svg viewBox="0 0 1024 1024" className="livechat-icon08">
            <path d="M842.286 521.714l22.286 29.143c6.286 8 4.571 18.857-2.857 25.143-57.143 44.571-188.571 145.714-194.286 150.286-40.571 33.143-95.429 78.857-154.857 78.286h-1.143c-59.429 0-114.286-45.143-154.857-78.286-6.286-5.143-133.143-102.857-189.143-146.286-8-6.286-9.714-17.143-3.429-25.143l21.143-29.714c6.286-8.571 18.286-10.286 26.286-3.429 38.857 30.286 93.714 72.571 174.857 134.857 28.571 21.714 85.143 74.857 125.143 74.857h1.143c40 0 96.571-53.143 125.143-74.857 84-64.571 140-107.429 178.857-138.286 8-6.286 19.429-4.571 25.714 3.429zM950.857 932.571v-530.286c-57.143-53.143-48.571-48.571-313.143-253.143-28.571-22.286-85.143-76-125.143-76h-1.143c-40 0-96.571 53.714-125.143 76-264.571 204.571-256 200-313.143 253.143v530.286c0 9.714 8.571 18.286 18.286 18.286h841.143c9.714 0 18.286-8.571 18.286-18.286zM1024 402.286v530.286c0 50.286-41.143 91.429-91.429 91.429h-841.143c-50.286 0-91.429-41.143-91.429-91.429v-530.286c0-20.571 8.571-40 23.429-53.714 117.143-108.571 252-202.857 333.143-269.714 40-33.143 95.429-78.857 154.857-78.857h1.143c59.429 0 114.857 45.714 154.857 78.857 75.429 62.286 218.857 163.429 333.143 269.714 14.857 13.714 23.429 33.143 23.429 53.714z"></path>
          </svg>
          <span className="livechat-text04">Unread</span>
        </div>
        <div className="livechat-container05"></div>
      </div>
      <div className={`${filter === "important" ? "livechat-conversation-item1" : "livechat-conversation-item"}`} onClick={() => handle_filter_change("important")}>
        <div className="livechat-container07">
          <svg
            viewBox="0 0 1024 1024"
            style={{
              width: "16px",
              height: "16px",
              fill: "#585B5B",
              marginRight: "5px",
            }}
          >
            <path d="M512 938q-36 0-61-24t-25-60h172q0 34-26 59t-60 25zM554 512v-170h-84v170h84zM554 682v-84h-84v84h84zM768 682l86 86v42h-684v-42l86-86v-212q0-100 51-174t141-96v-30q0-26 18-45t46-19 46 19 18 45v30q90 22 141 96t51 174v212z"></path>
          </svg>

          <span className="livechat-text06">Important</span>
        </div>
        <div className="livechat-container08"></div>
      </div>

      <div className={`${filter === "done" ? "livechat-conversation-item1" : "livechat-conversation-item"}`} onClick={() => handle_filter_change("done")}>
        <div className="livechat-container07">
          <svg
            viewBox="0 0 1024 1024"
            style={{
              width: "16px",
              height: "16px",
              fill: "#585B5B",
              marginRight: "5px",
            }}
          >
            <path d="M426 726l282-282-60-60-222 220-88-88-60 60zM826 428q82 6 140 67t58 145q0 88-63 151t-151 63h-554q-106 0-181-75t-75-181q0-94 67-169t161-85q42-78 118-126t166-48q108 0 201 76t113 182z"></path>
          </svg>{" "}
          <span className="livechat-text06">Done</span>
        </div>
        <div className="livechat-container08"></div>
      </div>
    </div>
  );
};


export default Conversations;
