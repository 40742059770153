import React, {useState, useEffect} from "react";
import uuid from "react-uuid";
import {get_datas, create_data, create_data_item, delete_data_item, delete_data, rename_data} from "../../apis/index";
import {Spin, Modal, Button, Select, Input, Upload, message as toast, Popconfirm, Dropdown} from "antd";
import {LoadingOutlined, UploadOutlined} from "@ant-design/icons";
import {convert_utc_to_month_date, shortenFileName} from "../../utils/helper";
import Papa from "papaparse";
import PizZip from "pizzip";
import {DOMParser} from "@xmldom/xmldom";

function DataSource() {
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );

  useEffect(() => {
    const onLoad = async () => {
      setLoading(true);
      let result = await get_datas();
      console.log(result);
      if (result && result.data) {
        setDatas(result.data);
      }
      setLoading(false);
    };

    onLoad();
  }, []);

  const [datas, setDatas] = useState([]);
  const [data_index, setData_index] = useState(null);
  const [parent, setParent] = useState(true);
  const [loading, setLoading] = useState(false);
  const [modal_open, setModal_open] = useState(false);
  const [data_id, setData_id] = useState(null);
  const [rename_modal_show, setRename_modal_show] = useState(false)
  const [url, setUrl] = useState("")

  const [act_data, setAct_data] = useState(null);
  const [new_name, setNew_name] = useState("")

  const handleDeleteSingleData = async (data_item_id) => {
    if (data_index !== null) {
      const newData = [...datas];
      const dataIndex = newData[data_index].data_items.findIndex((item) => item.id === data_item_id);

      if (dataIndex !== -1) {
        setLoading(true);
        await delete_data_item(data_id, data_item_id);
        newData[data_index].data_items.splice(dataIndex, 1);
        setDatas(newData);
        setLoading(false);
      }
    }
  };

  const handleDeleteAll = () => {
    if (data_index !== null) {
      const newData = [...datas];
      newData[data_index].data_items = [];
      setDatas(newData);
    }
  };

  const addItem = () => {
    setModal_open(true);
    // if (selected !== null) {
    //   const newData = [...datas];
    //   const newItem = {
    //     key: uuid(),
    //     source: "https://www.50chats.com",
    //     content: "",
    //   };
    //   newData[selected].data_items.push(newItem);
    //   setDatas(newData);
    // }
  };

  const handleRow = (id) => {
    const dataIndex = datas.findIndex((item) => item.id == id);
    setData_id(id);
    setData_index(dataIndex);
    setParent(!parent);
  };

  const HandleNewDataSource = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    let new_data = JSON.stringify({name: "Untitled"});
    let response = await create_data(new_data);
    if (response) {
      response.total_items = 0;
      let all_datas = [...datas];
      all_datas.push(response);
      setDatas(all_datas);
    }
    setLoading(false);
  };

  const HandleCloseModal = () => {
    setModal_open(false);
  };

  const save_new_item = async (source, content="") => {
    setModal_open(false);
    setLoading(true);
    let payload = {source, content};
    let response = await create_data_item(data_id, JSON.stringify(payload));
    if (response) {
      payload.id = response.id;
      payload.last_modified = response.last_modified;
      let all_datas = [...datas];
      all_datas[data_index].data_items.push(payload);
    }
    setLoading(false)
    setUrl("")
  };

  const onError = () => {
    toast.error("something went wrong");
    setModal_open(false);
  };

  const handleCustomRequest = async ({file}) => {
    const {name} = file;
    const fileExtension = name.slice(((name.lastIndexOf(".") - 1) >>> 0) + 2).toLowerCase();

    if (fileExtension === "docx") {
      const reader = new FileReader();

      reader.onload = () => {
        const arrayBuffer = reader.result;
        extractDocxContent(arrayBuffer);
      };

      reader.readAsArrayBuffer(file);

      const extractDocxContent = (arrayBuffer) => {
        const zip = new PizZip(arrayBuffer);
        const contentXml = zip.file("word/document.xml").asText();

        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(contentXml, "text/xml");
        const textNodes = xmlDoc.getElementsByTagName("w:t");

        const textContent = Array.from(textNodes)
          .map((node) => node.textContent)
          .join(" ");

        save_new_item(name, textContent);
      };
    } else if (fileExtension === "csv") {
      const reader = new FileReader();

      reader.onload = (event) => {
        const content = event.target.result;

        Papa.parse(content, {
          header: true, // Set to true if the first row contains headers
          complete: (result) => {
            const data = result.data;
            // You can now work with the extracted CSV data in the 'data' array.
            save_new_item(name, data);
          },
          error: (error) => {
            console.error(error);
            onError();
          },
        });
      };

      reader.readAsText(file);
    } else {
      toast.error("File type not supported");
    }
  };

  const Handle_Data_Group_Delete = async () => {
    const dataIndex = datas.findIndex((item) => item.id == act_data.id);
    await delete_data(act_data.id)

    let new_datas = [...datas]

    new_datas.splice(dataIndex, 1);
    setDatas(new_datas)
  };
  

  const Handle_Data_Group_Rename = () => {
    let old_name = act_data.name
    setNew_name(old_name)
    setRename_modal_show(true)
  };

  const Handle_Rename = async () => {
    let payload = JSON.stringify({name: new_name})
    setLoading(true)
    let response = await rename_data(act_data.id, payload)
    setLoading(false) 
    if(response){
      toast.success("name changed")
    }
    const dataIndex = datas.findIndex((item) => item.id == act_data.id);
    datas[dataIndex].name = new_name
    setRename_modal_show(false)
  }

  const more_buttons = [
    {
      key: "rename",
      label: <a onClick={Handle_Data_Group_Rename}>Rename</a>,
    },
    {
      key: "delete",
      label: (
        <Popconfirm title="Delete Data Group" description="Are you sure you want to delete the selected Data Group?" onConfirm={Handle_Data_Group_Delete} okText="Yes" cancelText="No">
          <a>Delete</a>
        </Popconfirm>
      ),
      danger: true,
    },
  ];

  const Handle_Drop_Down_Click = (data) => {
    setAct_data(data)
  };

  return (
    <>
      {parent ? (
        <div className="data-datagroup">
          <h1 className="data-text">Data Group</h1>
          <div className="data-table">
            <div className="data-row">
              <div className="data-rowitem"></div>
              <div className="data-rowitem01">
                <span className="data-text01">Name</span>
              </div>
              <div className="data-rowitem02">
                <span className="data-text02">Last Modified</span>
              </div>
              <div className="data-rowitem03">
                <span className="data-text03">Total Items</span>
              </div>
              <div className="data-container1"></div>
            </div>

            {datas.map((value, ind) => (
              <div className="data-row1" key={value.id}>
                <div className="data-rowitem04">
                  <Dropdown
                    menu={{
                      items: more_buttons,
                    }}
                    onClick={() => Handle_Drop_Down_Click(value)}
                    trigger={["click"]}
                  >
                    <svg viewBox="0 0 1024 1024" className="data-icon">
                      <path d="M597.333 512c0-23.552-9.6-44.928-25.003-60.331s-36.779-25.003-60.331-25.003-44.928 9.6-60.331 25.003-25.003 36.779-25.003 60.331 9.6 44.928 25.003 60.331 36.779 25.003 60.331 25.003 44.928-9.6 60.331-25.003 25.003-36.779 25.003-60.331zM597.333 213.333c0-23.552-9.6-44.928-25.003-60.331s-36.779-25.003-60.331-25.003-44.928 9.6-60.331 25.003-25.003 36.779-25.003 60.331 9.6 44.928 25.003 60.331 36.779 25.003 60.331 25.003 44.928-9.6 60.331-25.003 25.003-36.779 25.003-60.331zM597.333 810.667c0-23.552-9.6-44.928-25.003-60.331s-36.779-25.003-60.331-25.003-44.928 9.6-60.331 25.003-25.003 36.779-25.003 60.331 9.6 44.928 25.003 60.331 36.779 25.003 60.331 25.003 44.928-9.6 60.331-25.003 25.003-36.779 25.003-60.331z"></path>
                    </svg>
                  </Dropdown>
                </div>

                <div className="data-rowitem05" onClick={() => handleRow(value.id)}>
                  <span className="data-text04">{value.name}</span>
                </div>
                <div className="data-rowitem06" onClick={() => handleRow(value.id)}>
                  <span className="data-text05">{convert_utc_to_month_date(value.last_modified)}</span>
                </div>
                <div className="data-rowitem07" onClick={() => handleRow(value.id)}>
                  <span className="data-text06">{value.data_items.length}</span>
                </div>
                <div className="data-container2" onClick={() => handleRow(value.id)}>
                  <svg viewBox="0 0 1024 1024" className="data-icon2">
                    <path d="M366 708l196-196-196-196 60-60 256 256-256 256z"></path>
                  </svg>
                </div>
              </div>
            ))}
          </div>
          <div style={{display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "auto"}}>{loading && <Spin size="large" indicator={antIcon} />}</div>

          <div className="data-container3" onClick={HandleNewDataSource}>
            <span className="data-text07">+ New Data Group</span>
          </div>
        </div>
      ) : (
        <div className="data-dataitems">
          <h1 className="data-text08">
            <span style={{cursor: "pointer"}} onClick={() => setParent(true)}>
              Data Group
            </span>{" "}
            &gt; <span>Data 1</span>
          </h1>
          <div className="data-table1">
            <div className="data-row2">
              <div className="data-rowitem08">
                <span className="data-text09">Source</span>
              </div>
              <div className="data-rowitem09">
                <span className="data-text10">Character Count</span>
              </div>
              <div className="data-rowitem10">
                <span className="data-text11" onClick={handleDeleteAll}>
                  Delete all
                </span>
              </div>
            </div>

            {datas[data_index]?.data_items.map((data) => (
              <div className="data-row3" key={data.id}>
                <div className="data-rowitem11">
                  <span className="data-text12">{data.source}</span>
                </div>
                <div className="data-rowitem12">
                  <span className="data-text13">{data.content.length}</span>
                </div>
                <div className="data-rowitem13">
                  <svg viewBox="0 0 1024 1024" className="data-icon4" onClick={() => handleDeleteSingleData(data.id)}>
                    <path d="M662 170h148v86h-596v-86h148l44-42h212zM342 384v426h340v-426h-340zM256 810v-512h512v512q0 34-26 60t-60 26h-340q-34 0-60-26t-26-60z"></path>
                  </svg>
                </div>
              </div>
            ))}
          </div>
          <div style={{display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "auto"}}>{loading && <Spin size="large" indicator={antIcon} />}</div>

          <div className="data-container4" onClick={addItem}>
            <span className="data-text14">+ New Data Item</span>
          </div>
        </div>
      )}
      <Modal button={null} open={modal_open} onCancel={HandleCloseModal} title="Data Item" onOk={() => save_new_item(url, "")}>
        <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
          <Input value={url} onChange={(e) => setUrl(e.target.value)} placeholder="Enter a website url" />
          <span style={{marginBottom: "10px", marginTop: "10px"}}>or</span>

          <Upload customRequest={handleCustomRequest} showUploadList={false} accept=".docx">
            <Button icon={<UploadOutlined />}>Upload a Docxfile</Button>
          </Upload>
        </div>
      </Modal>

      <Modal style={{marginBottom: "40px"}} open={rename_modal_show} onOk={Handle_Rename} onCancel={() => setRename_modal_show(false)} title="Rename Data Group" >
          <Input value={new_name} onChange={(e) => setNew_name(e.target.value)} placeholder="Enter a new name" />
      </Modal>
    </>
  );
}

export default DataSource;
