import React, {useState} from "react";
import {Select, Button, Modal, Input, message as toast} from "antd";
import {update_bot} from "../../../apis/index";
import { local } from "d3";
import { useRecoilState } from 'recoil';
import { flow_data_state } from '../../../atom';
const {Option} = Select;

const CustomSelect = ({variables, value, onChange, setVariables, width = "100%", show_create = true}) => {
  const [validation, setValidation] = useState("string");
  const [flow_data, setFlow_data] = useRecoilState(flow_data_state);
  const bot_id = flow_data.bot_id
  const reformatted_variables = variables.filter(variable => !variable.is_system_variable)

  const validation_options = [
    {value: "string", label: "String"},
    {value: "number", label: "Number"},
    {value: "name", label: "Name"},
    {value: "date", label: "Date"},
    {value: "datetime", label: "Date and time"},
    {value: "email", label: "Email"},
    {value: "phone", label: "Phone"},
    {value: "url", label: "Url"},
    {value: "geolocation", label: "Geolocation"},
    {value: "image", label: "Image"},
  ];

  const buttonStyle = {
    backgroundColor: "white",
    color: "#14A9FF",
    border: "1px solid",
    width: "100%",
    marginTop: "5px",
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [new_variable_name, setNew_variable_name] = useState("");

  const handleButtonClick = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    console.log("these are ", validation_options);
    if (!new_variable_name.trim()) {
      return toast.error("variable name must not be empty");
    }
    const isVariableExists = variables.some((variable) => variable.name === new_variable_name);
    if (isVariableExists) {
      return toast.error("variable already exists");
    }

    let new_variable = {name: new_variable_name.trim(), type: validation};
    let updated_variables = [...variables, new_variable];
    update_bot(bot_id, JSON.stringify({variables: updated_variables}));
    setVariables(updated_variables);
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Select
        style={{width: width}}
        value={value}
        placeholder={"Select Variable"}
        onChange={(value) => {
          onChange(value);
        }}
        dropdownRender={(menu) => (
          <div>
            {menu}

            {show_create && (
              <Button style={buttonStyle} type="primary" onClick={handleButtonClick}>
                + New Variable
              </Button>
            )}
          </div>
        )}
      >
        {reformatted_variables?.map((option) => (
          <Option key={option.name} value={option.name}>
            {option.name}
          </Option>
        ))}
      </Select>

      <Modal title="Create Variable" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <Input
          placeholder="Variable name"
          style={{marginBottom: 15}}
          onChange={(e) => {
            let input_name = e.target.value;
            if (!input_name) {
              return;
            }
            setNew_variable_name(input_name);
          }}
        />
        <Select
          style={{width: "100%", marginTop: "1rem"}}
          defaultValue={validation}
          options={validation_options}
          onChange={(data) => {
            setValidation(data);
          }}
        />
      </Modal>
    </>
  );
};

export default CustomSelect;
