import React, {useState, useEffect} from "react";
import "./broadcast.css";
import {delete_flow, run_broadcast, get_broadcasts, copy_flow} from "../../../apis/index";
import Loading from "../../../components/loading";
import {utc_to_date_time_formatter} from "../../../utils/helper"
import {MoreOutlined, DeleteOutlined, CopyOutlined} from "@ant-design/icons"
import { Popconfirm, Dropdown, message as toast } from "antd";
import {useHistory} from "react-router-dom";


const Broadcast = ({loading, bot_id, broadcasts, setBroadcasts, create_new_broadcast, setLoading, edit_broadcast}) => {
  const history = useHistory()

//   const [broadcasts, setBroadcasts] = useState({});
//   const [loading, setLoading] = useState(true);

  if (loading) {
    return <Loading type="circle" />;
  }

  const Empty_message = ({message}) => {
    return (
      <div className="broadcast-emptybroadcast">
        <span className="broadcast-text16">{message}</span>
      </div>
    );
  };


  const calculate_sent_read  = (sent, read) => {
    let no_sent = sent.length
    let no_read = read.length

    let percentage_read = no_read/no_sent * 100
    let rounded_percentage = Math.round(percentage_read * 100) / 100; // Round to two decimal places

    return {no_sent, no_read, percentage_read: rounded_percentage}
  }

  const delete_broadcast = async (broadcast) => {
    let response = await delete_flow(broadcast.id)
    setLoading(true)
    if (response && response.status === "success") {
      let res = await get_broadcasts(bot_id);
      if (res && res.data) {
        setBroadcasts(res.data);
      }
    }
    setLoading(false)
    
  }


  const handle_run_broadcast = async (broadcast) => {
    let response = await run_broadcast(broadcast.id);
    setLoading(true)
    if (response && response.status === "success") {
      let res = await get_broadcasts(bot_id);
      if (res && res.data) {
        setBroadcasts(res.data);
      }
    }
    setLoading(false)
};


const copy_broadcast = async (id) => {
  let response = await copy_flow(id)
  setLoading(true)
  if (response && response.status === "success") {
    let res = await get_broadcasts(bot_id);
    if (res && res.data) {
      setBroadcasts(res.data);
    }
  }
  setLoading(false)
}


  const more_draft_buttons = (broadcast) => {
  const buttons = [
    {
      key: 'run',
      label: (
        
        <a onClick={() => handle_run_broadcast(broadcast)}>
          Run
        </a>
      ),
    },
    {
      key: 'edit',
      label: (
        
        <a onClick={() => edit_broadcast(broadcast)}>
          Edit
        </a>
      ),
    },

    {
      key: 'delete',
      label: (

        <Popconfirm
        title="Delete Broadcast"
        description="Are you sure you want to delete the selected broadcast?"
        onConfirm={() => delete_broadcast(broadcast)} // Fixed
        okText="Yes"
        cancelText="No"
      >
        <a>
          Delete
        </a>
        
        </Popconfirm>

      ),
      danger: true
    },
  ]

    return buttons
  }


  const Handle_Broadcast_Click = (broadcast) => {
    const to = `/${bot_id}/flowchart/${broadcast.id}`;
    history.push(to);
  };




  return (
    <>
      {/* ==================== Draft Broad Cast ================= */}

      <h1 className="broadcast-draft">Drafts</h1>
      {broadcasts.drafts.length == 0 ? (
        <div className="broadcast-createnewbroadcast" onClick={create_new_broadcast}>
          <span className="broadcast-text17">Create New Broadcast</span>
        </div>
      ) : (
        <div className="broadcast-draftscontainer">
          <div className="broadcast-flowitem">
            <div className="broadcast-namelabel">
              <span className="broadcast-text">Name</span>
            </div>
            <div className="broadcast-lastmodifiedlabel">
              <span className="broadcast-text01">Last modified</span>
            </div>
          </div>

          {broadcasts.drafts.map((broadcast) => (
            <div key={broadcast.id} className="broadcast-flowitem1">
              <div className="broadcast-namecontainer" onClick={() => Handle_Broadcast_Click(broadcast)}>
                <span className="broadcast-text02">{broadcast.name}</span>
              </div>
              <div className="broadcast-lastmodified" onClick={() => Handle_Broadcast_Click(broadcast)}>
                <span className="broadcast-text03">{utc_to_date_time_formatter(broadcast.last_modified)}</span>
              </div>

              <div className="broadcast-actioncontainer">
                <Dropdown
                  menu={{
                    items: more_draft_buttons(broadcast),
                  }}
                  trigger={["click"]}
                >
                  <MoreOutlined style={{fontSize: "30px"}} onClick={(e) => e.preventDefault()} />
                </Dropdown>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* ==================== Scheduled Broad Cast ================= */}
      <h1 className="broadcast-scheduled">Scheduled</h1>

      {broadcasts.scheduled.length == 0 ? (
        <Empty_message message="You have no scheduled broadcast" />
      ) : (
        <div className="broadcast-scheduledcontainer">
          <div className="broadcast-flowitem2">
            <div className="broadcast-namelabel1">
              <span className="broadcast-text04">Name</span>
            </div>
            <div className="broadcast-lastmodifiedlabel1">
              <span className="broadcast-text05">Scheduled date</span>
            </div>
          </div>

          {broadcasts.scheduled.map((broadcast) => (
            <div key={broadcast.id} className="broadcast-flowitem3">
              <div className="broadcast-namecontainer1" onClick={() => Handle_Broadcast_Click(broadcast)}>
                <span className="broadcast-text06">{broadcast.name}</span>
              </div>
              <div className="broadcast-lastmodified1" onClick={() => Handle_Broadcast_Click(broadcast)}>
                <span className="broadcast-text07">{utc_to_date_time_formatter(broadcast.scheduled_date)}</span>
              </div>
              <div className="broadcast-actioncontainer1">
                <Popconfirm
                  title="Delete Broadcast"
                  description="Are you sure you want to delete the selected broadcast?"
                  onConfirm={() => delete_broadcast(broadcast)} // Fixed
                  okText="Yes"
                  cancelText="No"
                >
                  <DeleteOutlined style={{color: "#E14747"}} onClick={(e) => e.preventDefault()} />
                </Popconfirm>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* ==================== Broadcast History ================= */}
      <h1 className="broadcast-history">History</h1>

      {broadcasts.history.length == 0 ? (
        <Empty_message message="You have no broadcast history" />
      ) : (
        <div className="broadcast-historycontainer">
          <div className="broadcast-flowitem4">
            <div className="broadcast-namelabel2">
              <span className="broadcast-text08">Name</span>
            </div>
            <div className="broadcast-lastmodifiedlabel2">
              <span className="broadcast-text09">run date</span>
            </div>
            <div className="broadcast-lastmodifiedlabel3">
              <span className="broadcast-text10">sent</span>
            </div>
            <div className="broadcast-lastmodifiedlabel4">
              <span className="broadcast-text11">read</span>
            </div>
          </div>

          {broadcasts.history.map((broadcast) => (
            <div key={broadcast.id} className="broadcast-flowitem5">
              <div className="broadcast-namecontainer2">
                <span className="broadcast-text12">{broadcast.name}</span>
              </div>
              <div className="broadcast-lastmodified2">
                <span className="broadcast-text13">{utc_to_date_time_formatter(broadcast.run_date)}</span>
              </div>

              <div className="broadcast-lastmodifiedlabel5">
                <span className="broadcast-text14">{calculate_sent_read(broadcast.sent, broadcast.read).no_read}</span>
              </div>
              <div className="broadcast-lastmodifiedlabel6">
                <span className="broadcast-text15">
                  {calculate_sent_read(broadcast.sent, broadcast.read).no_read} of {calculate_sent_read(broadcast.sent, broadcast.read).no_sent} ({calculate_sent_read(broadcast.sent, broadcast.read).percentage_read}%)
                </span>
              </div>

              <div className="broadcast-actioncontainer2">
              <CopyOutlined onClick={() => copy_broadcast(broadcast.id)} />
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default Broadcast;
