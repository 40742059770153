import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './signup.css';
import {signup_validator} from "../utils/validator"
import {signup} from "../apis/index"
import {message as toast} from 'antd'
import {Button, Spin} from "antd"
import {getTimezone} from "../utils/helper"
import { useHistory } from 'react-router-dom';


const Signup = (props) => {
  const history = useHistory();

  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    confirm_password: '',
  });

  const [loading, setLoading] = useState(false)


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Access the form data inside the `formData` state and perform further actions, such as sending the data to a server.
    setLoading(true)
    formData.timezone = getTimezone()

    const {error, value} = signup_validator.validate(formData)
    if(value.password !== value.confirm_password){
      setLoading(false)
      return toast.error("Passwords do not match")
    }

    if(error){
      console.log(error)
      setLoading(false)
      return toast.error(error.details[0].message)
    }
    delete value.confirm_password
    let response = await signup(JSON.stringify(value))
    setLoading(false)
    // window.location.href = `/verifyemail?user_id=${response.user_id}&email=${formData.email}`

    // Reset the form after submission if needed
    // setFormData({
    //   first_name: '',
    //   last_name: '',
    //   email: '',
    //   password: '',
    //   confirm_password: '',
    // });
  };

  return (
    <div className="signup-container">
      <Helmet>
        <title>signup - 50Chats</title>
        <meta property="og:title" content="signup - wapi" />
      </Helmet>
      <div className="signup-container1">
        <h2 className="signup-text">50Chats</h2>
        <Link to="/login" className="signup-navlink button">
          Login
        </Link>
      </div>
      <div className="signup-container2">
        <div className="signup-container3">
          <div className="signup-container4">
            <h3>Signup</h3>
          </div>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              required
              name="first_name"
              value={formData.first_name}
              onChange={handleChange}
              placeholder="First Name"
              autoComplete="off"
              className="signup-textinput input"
            />
            <input
              type="text"
              required
              name="last_name"
              value={formData.last_name}
              onChange={handleChange}
              placeholder="Last Name"
              autoComplete="off"
              className="signup-textinput1 input"
            />
            <input
              type="email"
              required
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Email address"
              autoComplete="off"
              className="signup-textinput2 input"
            />
            <input
              type="password"
              required
              name="password"
              value={formData.password}
              onChange={handleChange}
              placeholder="Password"
              autoComplete="off"
              className="signup-textinput3 input"
            />
            <input
              type="password"
              required
              name="confirm_password"
              value={formData.confirm_password}
              onChange={handleChange}
              placeholder="Confirm Password"
              autoComplete="off"
              className="signup-textinput4 input"
            />
            <div className="signup-container5">
              <span style={{marginRight: 10}}>Already have an account? </span>
              <Link to="/login" className="login-text3">
                Click here to login
              </Link>
            </div>
            <Button loading={loading} onClick={handleSubmit} type="primary">
              Signup
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Signup;
