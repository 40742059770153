import {Button, Input, message as toast, Modal, Select, Progress, Space, Tag, InputNumber, Switch, Radio} from "antd";
import React, {useEffect, useState, useRef} from "react";
import {CloseOutlined} from "@ant-design/icons";
import {sleep} from "../../../utils/helper";
import AWS from "aws-sdk";
import {S3AccessKey, S3AccessKeySecret, S3BucketName, azure_container_name, azure_storage_name} from "../../../constants";
import {get_upload_url, delete_file} from "../../../apis/index"
import CustomSelect from "../buttons/custom_select";
import FloatingButton from "../buttons/FloatingButton";
import Select_variable from "../../component/buttons/select_variable";
import {useRecoilState} from "recoil";
import {flow_data_state} from "../../../atom";
import Buttons from "../message_drawer_component/buttons";
import "./messagedrawer.css"
import Template_message from "../message_drawer_component/template_message"
import File_Uploader from "../../../components/file_upload"

const MessageDrawer = ({node, onNodeChange, onNodeDelete, loading, variables, setVariables, bot_id}) => {
  const [flow_data, setFlow_data] = useRecoilState(flow_data_state);
  const [message_type, setMessage_type] = useState("regular");

  const textareaRef = useRef();

  const HandleTextAreaBlur = () => {
    setTimeout(() => {
      setShow_float(false);
    }, 1000);
  };

  const [show_float, setShow_float] = useState(false);
  const [show_var_picker, setShow_var_picker] = useState(false);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus();
    }

    setMessage(node.data.message);
    if(node.data.message_type){
      setMessage_type(node.data.message_type)
    }

    setButton_data(node.data.button_data);


    setSave_as(node.data.save_as);
    setInput(node.data.input);
    if (node.data.validation) {
      setValidation(node.data.validation);
    }

    setValidation_fallback(node.data.validation_fallback);
    if (node.data.file) {
      setFile(node.data.file);
    }

    setMax_retry(node.data.max_retry);

    if (node.data.expires_in) {
      setExpires_in(node.data.expires_in);
    }
  }, [node.id]);

  const [message, setMessage] = useState("");
  const [max_retry, setMax_retry] = useState(5);
  const [buttons, setButtons] = useState([]);
  const [input, setInput] = useState(false);
  const [validation, setValidation] = useState("string");
  const [save_as, setSave_as] = useState("");
  const [validation_fallback, setValidation_fallback] = useState("Invalid response");
  const [urlmodal, setUrlmodal] = useState(false);
  const [isUrlVarOpen, setIsUrlVarOpen] = useState(false);
  const [showAddvariable, setShowAddvariable] = useState(false);
  const fileInputRef = useRef(null);
  const [file, setFile] = useState({});
  const [ismediainputopen, setMediainputopen] = useState(false);
  const [uploading, setUploading] = useState(null);
  const [show_buttons, setShow_buttons] = useState(false);
  const [button_data, setButton_data] = useState({
    type: "reply",
    buttons: []
  });
  const [expires_in, setExpires_in] = useState({value: 0, unit: "never"});


  const s3 = new AWS.S3({
    accessKeyId: S3AccessKey,
    secretAccessKey: S3AccessKeySecret,
    region: "us-east-1",
  });

  const validation_options = [
    {value: "string", label: "String"},
    {value: "number", label: "Number"},
    {value: "date", label: "Date"},
    {value: "datetime", label: "Date and time"},
    {value: "email", label: "Email"},
    {value: "phone", label: "Phone"},
    {value: "url", label: "Url"},
    {value: "geolocation", label: "Geolocation"},
    {value: "image", label: "Image"},
  ];

  const expires_in_unit_options = [
    {value: "never", label: "Never"},
    {value: "second", label: "Seconds"},
    {value: "minute", label: "Minutes"},
    {value: "hour", label: "Hours"},
    {value: "day", label: "Days"},
  ];


  const handleFileSelect = async (e) => {
    let selectedFile = e.target.files[0];
    if (selectedFile) {
      setUploading(1);
      let objKey = bot_id + "/" + selectedFile.name;
  
      const response = await get_upload_url(objKey)
  
      const uploadUrl = response.upload_url
  
  
      // Perform the file upload using the obtained upload link
      try {
        const uploadResponse = await fetch(uploadUrl, {
          method: 'PUT',
          body: selectedFile,
           headers: {
    'Content-Type': selectedFile.type,
    'x-ms-blob-type': 'BlockBlob',
    'x-ms-date': new Date().toUTCString(), // Include the current date
  },
          onprogress: (event) => {
            if (event.lengthComputable) {
              const percentage = Math.round((event.loaded / event.total) * 100);
              setUploading(percentage);
            }
          },

        });
  
        if (uploadResponse.ok) {
          const fileUrl = `https://${azure_storage_name}.blob.core.windows.net/${azure_container_name}/${objKey}`;
          let fileObject = { name: selectedFile.name, media_url: fileUrl };
          setFile(fileObject);
          setUploading(null);
        } else {
          console.error('Error uploading file:', uploadResponse.statusText);
          setUploading(null);
        }
      } catch (error) {
        console.error('Error uploading file:', error);
        setUploading(null);
      }
    }
  };





  const handleFileRemove = () => {
    // Assuming 'file' is the state variable where you store the file object
    if (!file.media_url.includes(azure_storage_name)) {
      setFile({}); // Clear the 'file' state variable
      const updatedNode = {
        ...node,
        data: {message_type, message, button_data, input, save_as, validation, validation_fallback, file, max_retry, expires_in},
      };
      onNodeChange(updatedNode);
    } else {
      setUploading(50);
      delete_file(file.media_url)

      const updatedNode = {
        ...node,
        data: {message_type, message, button_data, input, save_as, validation, validation_fallback, file, max_retry, expires_in},
      };

      onNodeChange(updatedNode);
      setUploading(100);
      setUploading(null);
      setFile({}); // Clear the 'file' state variable
    }
  };

  const handleFileUpload = () => {
    fileInputRef.current.click();
  };

  const handleInput = (e) => {
    const text = e.target.value;
    setMessage(text);
  };


  useEffect(() => {
    const updatedNode = {
      ...node,
      data: {message_type, message, button_data, input, save_as, validation, validation_fallback, file, max_retry, expires_in},
    };

    console.log(updatedNode)
    onNodeChange(updatedNode);
  }, [message_type, message, button_data, input, save_as, validation, validation_fallback, file, max_retry, expires_in]);



  const handleInsertClick = (selectedValue) => {
    const textarea = document.getElementById("myTextarea");
    const {selectionStart, selectionEnd, value} = textarea;
    const newValue = value.substring(0, selectionStart) + "{{" + selectedValue + "}}" + value.substring(selectionEnd);
    textarea.value = newValue;
    setMessage(newValue);
    textarea.setSelectionRange(selectionStart + selectedValue.length, selectionStart + selectedValue.length);
    setShow_var_picker(false);
  };

  const HandleUrlInput = (selectedValue) => {
    const textarea = document.getElementById("urlinput");
    const {selectionStart, selectionEnd, value} = textarea;
    const newValue = value.substring(0, selectionStart) + "{{" + selectedValue + "}}" + value.substring(selectionEnd);
    textarea.value = newValue;
    setFile({media_url: newValue});
    textarea.setSelectionRange(selectionStart + selectedValue.length, selectionStart + selectedValue.length);
    setShowAddvariable(false);
    setIsUrlVarOpen(false);
  };

  const HandleTextAreaFocus = () => {
    console.log("text area focused");
    setShow_float(true);
  };

  const Handle_Float_Button_Pick = () => {
    setShow_var_picker(true);
  };

  const HandleMessageTypeChange = (value) => {
    console.log("this is the new message type", value);
    if (value == true) {
      setMessage_type("regular");
    } else {
      setMessage_type("template");
    }
  };


  const on_template_select = (template_name) => {
    setMessage(template_name)
  }



  return (
    <div>
      {message_type == "regular" ? (
        <Switch style={{width: "100%", backgroundColor: "#705CF6", marginBottom: "20px"}} value={message_type} on onChange={HandleMessageTypeChange} checkedChildren="regular" unCheckedChildren="template" defaultChecked />
      ) : (
        <Switch style={{width: "100%", backgroundColor: "#14A9FF", marginBottom: "20px"}} onChange={HandleMessageTypeChange} value={message_type} checkedChildren="regular" unCheckedChildren="template" defaultChecked />
      )}

      {message_type == "template"? (
        <Template_message bot_id={bot_id} onChange={on_template_select}/>
      ):(
      <>
        <div style={{marginBottom: 20, display: "flex", justifyContent: "space-between", alignItems: "center", flexDirection: "row"}}>
        {file.media_url ? (
          <span style={{color: "red", cursor: "pointer"}} onClick={handleFileRemove}>
            Remove attachment
          </span>
        ) : (
          <span
            style={{color: "blue", cursor: "pointer"}}
            onClick={() => {
              setMediainputopen(true);
            }}
          >
            Add an attachment
          </span>
        )}

        {uploading && <Progress type="circle" percent={uploading} width={30} />}
      </div>
      

     
      {ismediainputopen && !file.media_url && (
        <>
          <div style={{width: "100%", marginBottom: 5, display: "flex", justifyContent: "flex-end", paddingRight: 5}}>
            <svg
              onClick={() => setMediainputopen(false)}
              viewBox="0 0 1024 1024"
              style={{
                width: "20px",
                height: "20px",
                fill: "red",
                cursor: "pointer",
              }}
            >
              <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
            </svg>
          </div>
          <div className="testingpage-container1">
            <div className="testingpage-container2">
              <svg viewBox="0 0 877.7142857142857 1024" className="testingpage-icon">
                <path d="M585.143 786.286v-91.429c0-10.286-8-18.286-18.286-18.286h-54.857v-292.571c0-10.286-8-18.286-18.286-18.286h-182.857c-10.286 0-18.286 8-18.286 18.286v91.429c0 10.286 8 18.286 18.286 18.286h54.857v182.857h-54.857c-10.286 0-18.286 8-18.286 18.286v91.429c0 10.286 8 18.286 18.286 18.286h256c10.286 0 18.286-8 18.286-18.286zM512 274.286v-91.429c0-10.286-8-18.286-18.286-18.286h-109.714c-10.286 0-18.286 8-18.286 18.286v91.429c0 10.286 8 18.286 18.286 18.286h109.714c10.286 0 18.286-8 18.286-18.286zM877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"></path>
              </svg>
              <span className="testingpage-text">url can also be variables</span>
            </div>

            <div className="testingpage-container3">
              <File_Uploader onChange={handleFileSelect}>
                <span className="testingpage-text1">Upload attachment</span>
              </File_Uploader>

              <span className="testingpage-text2">or</span>
              <span
                className="testingpage-text3"
                onClick={() => {
                  setUrlmodal(!urlmodal);
                }}
              >
                insert url
              </span>
            </div>
          </div>
        </>
      )}


      {file.media_url && (
        <div
          className="testingpage-filecontainer"
          onClick={() => {
            setUrlmodal(true);
          }}
        >
          <div className="testingpage-fiileheader">
            <span className="testingpage-text">Attachment</span>
          </div>
          <div className="testingpage-filebody">
            <span className="testingpage-text1">{file.media_url}</span>
          </div>
        </div>
      )}

      <div style={{display: "flex", flexDirection: "column"}}>
        {/* ========= this is the textarea directly below ==========  */}
        <Input.TextArea id="myTextarea" style={{color: "black", width: "100%"}} onFocus={HandleTextAreaFocus} onBlur={HandleTextAreaBlur} ref={textareaRef} autoSize={{minRows: 5, maxRows: 18}} value={message} onChange={handleInput} autoFocus placeholder="(leave blank if you plan to send an attachment alone)" />

          <span style={{cursor: "pointer", marginLeft: "auto", color: "#0074F0", marginBottom: 20}} onClick={Handle_Float_Button_Pick}>
            + add variable
          </span>

        <Select_variable open={show_var_picker} onClose={() => setShow_var_picker(false)} variables={variables} onChange={handleInsertClick} />
      </div>

      {showAddvariable && (
        <div style={{marginTop: "32px", marginBottom: "30px"}}>
          <Select placeholder="Select Variable" defaultOpen showSearch onChange={handleInsertClick} style={{width: "100%"}}>
            {variables.map((option) => (
              <Select.Option key={option.name} value={option.name}>
                {option.name}
              </Select.Option>
            ))}
          </Select>
        </div>
      )}

          <Button type="primary" style={{width: "100%"}} onClick={() => setShow_buttons(true)}>
            Manage Buttons
          </Button>
          <Buttons open={show_buttons} onClose={() => setShow_buttons(false)} button_data={button_data} setButton_data={setButton_data} />
    

      {/*  ============================ Do not worry about the stuffs below (for accepting input both regular and template message ) */}

      <div style={{backgroundColor: "#edeff2", marginTop: 20, marginBottom: 20, width: "100%", borderRadius: 8, padding: 10, display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center"}}>
        <div style={{width: "100%", height: "auto", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center"}}>
          <svg
            viewBox="0 0 1170.2857142857142 1024"
            style={{width: 40, height: 30, cursor: "pointer", fill: input ? "#008EF9" : "#a9a9af"}}
            onClick={() => {
              if (flow_data.type === "Plugin") {
                return toast.error("You can't accept input in a plugin");
              }
              if (input == "yes") {
                setInput("no");
              } else {
                setInput("yes");
              }
            }}
          >
            {input == "yes" ? (
              <path d="M0 512c0-201.714 164-365.714 365.714-365.714h438.857c201.714 0 365.714 164 365.714 365.714s-164 365.714-365.714 365.714h-438.857c-201.714 0-365.714-164-365.714-365.714zM804.571 804.571c161.143 0 292.571-131.429 292.571-292.571s-131.429-292.571-292.571-292.571-292.571 131.429-292.571 292.571 131.429 292.571 292.571 292.571z"></path>
            ) : (
              <path d="M658.286 512c0-161.143-131.429-292.571-292.571-292.571s-292.571 131.429-292.571 292.571 131.429 292.571 292.571 292.571 292.571-131.429 292.571-292.571zM1097.143 512c0-161.143-131.429-292.571-292.571-292.571h-220.571c89.143 66.857 147.429 173.143 147.429 292.571s-58.286 225.714-147.429 292.571h220.571c161.143 0 292.571-131.429 292.571-292.571zM1170.286 512c0 201.714-164 365.714-365.714 365.714h-438.857c-201.714 0-365.714-164-365.714-365.714s164-365.714 365.714-365.714h438.857c201.714 0 365.714 164 365.714 365.714z"></path>
            )}
          </svg>

          <span style={{marginLeft: 10, marginBottom: 3, fontSize: 18}}>Wait for user input</span>
        </div>

        {input == "yes" && (
          <>
            <div style={{width: "100%", height: "auto", marginBottom: 20}}>
              <h3 style={{color: "#585B5B"}}>Validate input as</h3>
              <Select value={validation} onChange={setValidation} style={{width: "100%"}} placeholder="select validation" options={validation_options} />
            </div>

            <div style={{width: "100%", height: "auto", marginBottom: 20}}>
              <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                <h3 style={{color: "#585B5B"}}>Save input as</h3>
              </div>
              <CustomSelect value={save_as} onChange={(value) => setSave_as(value)} bot_id={bot_id} variables={variables} setVariables={setVariables} />{" "}
            </div>

            <div style={{width: "100%", height: "auto", marginBottom: 20}}>
              <h4 style={{color: "#585B5B"}}>Message on Validation Failed</h4>

              <Input.TextArea
                rows={5}
                value={validation_fallback}
                onChange={(e) => {
                  e.preventDefault();
                  setValidation_fallback(e.target.value);
                }}
                placeholder="Incorrect message"
              />
            </div>

            <div style={{display: "flex", width: "100%"}}>
              <div style={{width: "100%", height: "auto", marginBottom: 20}}>
                <h4 style={{color: "#585B5B"}}>Max Retries</h4>
                <InputNumber
                  value={max_retry}
                  onChange={(e) => {
                    if (e < 0) {
                      return;
                    }
                    setMax_retry(e);
                  }}
                />
              </div>

              <div style={{width: "100%", height: "auto", marginBottom: 20}}>
                <h4 style={{color: "#585B5B"}}>Input Expires</h4>
                <div style={{display: "flex"}}>
                  {expires_in.unit !== "never" && (
                    <InputNumber
                      value={expires_in.value}
                      onChange={(e) => {
                        if (e < 0) {
                          return;
                        }
                        setExpires_in({...expires_in, value: e});
                      }}
                    />
                  )}

                  <Select
                    style={{width: "100%", marginTop: "1rem"}}
                    defaultValue={expires_in.unit}
                    options={expires_in_unit_options}
                    onChange={(e) => {
                      setExpires_in({...expires_in, unit: e});
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      <Modal
        title="Enter Url (Can also be variables)"
        open={urlmodal}
        onOk={() => {
          setUrlmodal(!urlmodal);
        }}
        onCancel={() => {
          setUrlmodal(!urlmodal);
        }}
      >
        <Input
          placeholder="https://example.com/house.png"
          id="urlinput"
          value={file.media_url}
          style={{marginBottom: 15}}
          onChange={(e) => {
            let value = e.target.value;
            setFile({media_url: value});
          }}
        />

        <FloatingButton onClick={() => setIsUrlVarOpen(true)} />
        <Select_variable open={isUrlVarOpen} onClose={() => setIsUrlVarOpen(false)} variables={variables} onChange={HandleUrlInput} />
      </Modal>
      </>
      )}
    </div>
  );
};

export default MessageDrawer;
