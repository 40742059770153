import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";
import Sidebar from "../components/sidebar";
import {v4 as uuidv4} from "uuid";
import Flow from "../components/automations/flow/flow";
import Trigger from "../components/automations/trigger/trigger";
import Setting from "../components/automations/setting/setting";
import Trash from "../components/automations/trash/trash";
import Plugin from "../components/automations/plugin/plugin";
import Broadcast from "../components/automations/broadcast/broadcast"
import {message as toast, Select, Button, Popconfirm, Modal, Form, Input, DatePicker} from "antd";
import { CopyOutlined } from "@ant-design/icons";
import {get_bot, create_node, update_bot, delete_node, delete_flow, get_broadcasts, run_broadcast, recover_flow, delete_flow_permanently} from "../apis/index";
import "./chatbot.css";
import dayjs from "dayjs";

import Sequence from "../components/automations/sequence/sequence";

const whatsapp_api_provider_options = [
  {label: "Wapi", value: "wapi"},
  {label: "Whatsapp", value: "whatsapp"},
];

const condition_options = [
  {label: "Message Contains", value: "message contains"},
  {label: "Message is", value: "message is"},
  {label: "Welcome Message", value: "welcome message"},
  {label: "Default Reply", value: "default reply"},
];

const Chatbot = (props) => {
  const {bot_id} = props.match.params;
  const [create_loading, setCreate_loading] = useState(false);
  const [delete_loading, setDelete_loading] = useState(false);
  const [chatbot, setChatbot] = useState({settings: {}, flows: [], triggers: [], sequences: [], plugins: []});
  const [current_node, setCurrent_node] = useState({name: ""});
  const [webhook_modal, setWebhook_modal] = useState(false)
  const [webhook, setWebhook] = useState({})


  // broadcasts stuff here =======================================
  const [broadcasts, setBroadcasts] = useState({});
  const [broadcast_loading, setBroadcast_loading] = useState(true);
  const [broadcast, setBroadcast] = useState({})
  const [creating_broadcast, setCreating_broadcast] = useState(false);

  useEffect(() => {
    const onLoad = async () => {
      let response = await get_broadcasts(bot_id);
      if (response && response.data) {
        console.log("broad cast whwn fetched", response.data)
        setBroadcasts(response.data);
      }
      setBroadcast_loading(false);
    };

    onLoad();
  }, []);


  const handle_create_broadcast_click = () => {
    setCreating_broadcast(true)
  }


  const create_new_broadcast = async () => {

    if(!broadcast.name){
      return toast.error("broadcast name is required")
    }

    if(!broadcast.schedule_type){
      return toast.error("please select broadcast type")
    }

    // if(broadcast.type ){
    //   return toast.error("please select broadcast type")
    // }

    setCreating_broadcast(false)

    let data = {...broadcast}
   
    if(!broadcast.id){
      data.id =  `broadcast-${uuidv4().replace(/-/g, "")}`
    }

    data.sent = []
    data.read = []
    data.status = "draft"
    data.type = "Broadcast"
    data.bot_id = bot_id
    data["last_modified"] = new Date().toISOString();


    let response = await create_node(JSON.stringify(data));

    setCreate_loading(false);

    if(!response){
      return
    }

    const existingIndex = broadcasts.drafts.findIndex((item) => item.id === data.id);
    let updated_broadcasts = {...broadcasts}

    if (existingIndex !== -1) {
      updated_broadcasts.drafts[existingIndex] = data;
    } else {
      updated_broadcasts.drafts.push(data);
    }

    setBroadcasts(updated_broadcasts)
    setBroadcast({})
  }


  const edit_broadcast = (selected_broadcast) => {
    console.log("Selected broad cast in edit", selected_broadcast)

    setBroadcast(selected_broadcast)
    setCreating_broadcast(true)
  }

  const cancel_create_broadcast = () => {
    setCreating_broadcast(false)
    setBroadcast({})
  }



  useEffect(() => {
    on_load_get_bot(bot_id);
  }, []);

  const [trash_selectall, setTrash_selectall] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [tab, setTab] = useState("triggers"); // sequence, triggers, settings, trash
  const form_item_style = {marginBottom: "15px"};

  function getSelectedFlowsCount() {
    const selectedFlows = chatbot.flows.filter((flow) => flow.selected);
    return selectedFlows.length;
  }

  const getSelectedTrashCount = () => {
    const selectedFlows = chatbot.trash.filter((trash) => trash.selected);
    return selectedFlows.length;
  };


  const HandleRecoverFlow = async () => {
    setCreate_loading(true);
    let selected_flows = chatbot.trash.filter((trash) => trash.selected).map((trash) => trash.id);
    let payload = JSON.stringify({ids: selected_flows});
    let response = await recover_flow(payload);
    if (!response) {
      setCreate_loading(false);
      return;
    }

    const updatedTrash = chatbot.trash.filter((trash) => !selected_flows.includes(trash.id));
    setChatbot({...chatbot, trash: updatedTrash});
    setCreate_loading(false);
  };

  const HandleDeleteTrashItems = async () => {
    setDelete_loading(true);
    let selected_flows = chatbot.trash.filter((trash) => trash.selected).map((trash) => trash.id);
    let payload = JSON.stringify({ids: selected_flows});
    let response = await delete_flow_permanently(payload);
    if (!response) {
      setDelete_loading(false);
      return;
    }

    const updatedTrash = chatbot.trash.filter((trash) => !selected_flows.includes(trash.id));
    setChatbot({...chatbot, trash: updatedTrash});
    setDelete_loading(false);
  };

  const HandleCreateNode = async () => {

    let new_node = {};
    if(tab == "broadcast"){
      handle_create_broadcast_click()
      return
    }

    setCreate_loading(true);

    
     if (tab == "flows") {
      new_node["type"] = "Flow";
    } else if (tab == "sequence") {
      new_node["type"] = "Sequence";
      new_node['items'] = []
    } else if (tab == "plugin") {
      new_node["type"] = "Plugin";
    } else if (tab == "triggers"){
      new_node["type"] = "Trigger";
      new_node["isActive"] = true;
    }

    new_node["name"] = "Untitled";

    new_node.bot_id = bot_id;
    new_node["last_modified"] = new Date().toISOString();
    new_node["id"] = `${new_node.type.toLowerCase()}-${uuidv4().replace(/-/g, "")}`;
    let response = await create_node(JSON.stringify(new_node));
    if (response) {
      toast.success(`${new_node.type} Created`);
      on_load_get_bot(bot_id);
    }
    setCreate_loading(false);
  };


  const HandleEditNode = async (node) => {
    // get the node with the same id as node_id from chatbot.flows
    setCurrent_node(node);
    var element = document.getElementsByClassName("chatbot-container51")[0];
    if (element) {
      element.style.display = "flex";
    }
  };

  const HandleEditSave = async () => {
    setCreate_loading(true);
    let response = await create_node(JSON.stringify(current_node));
    setCurrent_node({name: ""});
    if (response) {
      toast.success(`updated`);
      on_load_get_bot(chatbot._id);
      var element = document.getElementsByClassName("chatbot-container51")[0];
      if (element) {
        element.style.display = "none";
      }
    }
    setCreate_loading(false);
  };

  const handleApiProviderChange = (value) => {
    setChatbot({...chatbot, settings: {...chatbot.settings, api_provider: value}});
  };


  const handleTrashSelect = (id) => {
    chatbot.trash = chatbot.trash.map((trash) => (trash.id === id ? {...trash, selected: !trash.selected} : trash));

    setChatbot({...chatbot});
  };

  const handleTrashSelectAll = () => {
    const updatedSelectAll = !trash_selectall;
    setTrash_selectall(updatedSelectAll);

    chatbot.trash = chatbot.trash.map((trash) => ({
      ...trash,
      selected: updatedSelectAll,
    }));
    setChatbot({...chatbot});
  };

  const HandleSettingsSave = async () => {
    setCreate_loading(true);
    if (chatbot.settings.api_provider == "wapi") {
      delete chatbot.settings.token;
      delete chatbot.settings.product_id;
      delete chatbot.settings.phone_id;
    } else {
      delete chatbot.settings.api_key;
      delete chatbot.settings.account_id;
    }
    let update_response = await update_bot(chatbot._id, JSON.stringify(chatbot));
    if (update_response) {
      toast.success("Settings Updated");
    }

    if(update_response && update_response.webhook){
      setWebhook(update_response.webhook)
      setWebhook_modal(true)
    }
    setCreate_loading(false);
  };

  const on_load_get_bot = async (bot_id) => {
    let bot = await get_bot(bot_id);
    if (bot) {
      setChatbot(bot);
    }
  };

  const HandleNavClick = (tab) => {
    setTab(tab);
  };

  const HandleWebhookModalClose = () => {
    setWebhook_modal(false)
  }

  const HandleCopy = (value) => {
    const tempInput = document.createElement('input');
    tempInput.value = value;
    document.body.appendChild(tempInput);

    // Select and copy the value from the temporary input element
    tempInput.select();
    document.execCommand('copy');

    // Remove the temporary input element
    document.body.removeChild(tempInput);
    toast.success("Copied to Clipboard")
  };


  const components = {
    triggers: <Trigger chatbot={chatbot} delete_node={delete_node} create_node={create_node} setChatbot={setChatbot} HandleEditNode={HandleEditNode} />,

    plugin: <Plugin getSelectedFlowsCount={getSelectedFlowsCount}  HandleEditNode={HandleEditNode}chatbot={chatbot}  setChatbot={setChatbot} bot_id={bot_id} />,

    settings: <Setting chatbot={chatbot} HandleSettingsSave={HandleSettingsSave} setChatbot={setChatbot} handleApiProviderChange={handleApiProviderChange} whatsapp_api_provider_options={whatsapp_api_provider_options} create_loading={create_loading} />,

    trash: <Trash delete_loading={delete_loading} getSelectedTrashCount={getSelectedTrashCount} chatbot={chatbot} HandleRecoverFlow={HandleRecoverFlow} handleTrashSelect={handleTrashSelect} trash_selectall={trash_selectall} handleTrashSelectAll={handleTrashSelectAll} create_loading={create_loading} HandleDeleteTrashItems={HandleDeleteTrashItems} />,
 
    flows: <Flow getSelectedFlowsCount={getSelectedFlowsCount} HandleEditNode={HandleEditNode} setChatbot={setChatbot} chatbot={chatbot} bot_id={bot_id} />,

    sequence : <Sequence bot_id={bot_id} setTab={setTab} chatbot={chatbot} delete_node={delete_node} create_node={create_node} setChatbot={setChatbot} HandleEditNode={HandleEditNode} HandleCreateNode={HandleCreateNode} />,
 
    broadcast: <Broadcast edit_broadcast={edit_broadcast} create_new_broadcast={handle_create_broadcast_click} loading={broadcast_loading} setLoading={setBroadcast_loading} broadcasts={broadcasts} setBroadcasts={setBroadcasts} bot_id={bot_id} />
  };


  return (
    <div className="chatbot-container">
      <Helmet>
        <title>chatbot - Chatbot Builder</title>
        <meta property="og:title" content="chatbot - Chatbot Builder" />
      </Helmet>
      <div className="chatbot-container01">
        <Sidebar></Sidebar>

        <div className="chatbot-container02">
          <div className="chatbot-header">
            <div className="chatbot-container03">
              <div className="chatbot-container04">
                <h3>Chatbots</h3>
              </div>

              {tab != "settings" && (
                <div className="chatbot-container05">
                  <Button loading={create_loading} className="chatbot-button button" onClick={HandleCreateNode}>
                    <span>
                      <span>New {tab == "flows" ? "Flow" : tab == "triggers" ? "Trigger" : tab == "plugin" ? "Plugin" : tab == "broadcast" ? "Broadcast" : "Sequence"}</span>
                      <br></br>
                    </span>
                  </Button>
                </div>
              )}
            </div>
          </div>
          <div className="chatbot-body">
            <div className="chatbot-subheading">
              <div className="chatbot-container06">
                <div className="chatbot-container07">
                  <img alt="image" src="/user-thumbnail-200w-200w.png" className="chatbot-image" />
                  <h1>({chatbot.name})</h1>
                </div>
              </div>
              <div className="chatbot-container08">
                <div className="chatbot-container09">
                  <div
                    className={`${tab === "triggers" ? "flow_trigger_Setting" : ""} chatbot-container10`}
                    onClick={() => {
                      HandleNavClick("triggers");
                    }}
                  >
                    <h4 className="chatbot-text07">Triggers</h4>
                  </div>

                  <div
                    className={`${tab === "flows" ? "flow_trigger_Setting" : ""} chatbot-container10`}
                    onClick={() => {
                      HandleNavClick("flows");
                    }}
                  >
                    <h4 className="chatbot-text05">Flows</h4>
                  </div>

                  <div
                    className={`${tab === "plugin" ? "flow_trigger_Setting" : ""} chatbot-container10`}
                    onClick={() => {
                      HandleNavClick("plugin");
                    }}
                  >
                    <h4 className="chatbot-text05">Plugins</h4>
                  </div>

                  <div
                    className={`${tab === "sequence" ? "flow_trigger_Setting" : ""} chatbot-container10`}
                    onClick={() => {
                      HandleNavClick("sequence");
                    }}
                  >
                    <h4 className="chatbot-text05">Sequences</h4>
                  </div>

                  <div
                    className={`${tab === "broadcast" ? "flow_trigger_Setting" : ""} chatbot-container10`}
                    onClick={() => {
                      HandleNavClick("broadcast");
                    }}
                  >
                    <h4 className="chatbot-text05">Broadcast</h4>
                  </div>

                  <div
                    className={`${tab === "settings" ? "flow_trigger_Setting" : ""} chatbot-container10`}
                    onClick={() => {
                      HandleNavClick("settings");
                    }}
                  >
                    <h4 className="chatbot-text08">Settings</h4>
                  </div>
                </div>
                <div
                  className={`${tab === "trash" ? "flow_trigger_Setting" : ""} chatbot-container10`}
                  onClick={() => {
                    HandleNavClick("trash");
                  }}
                >
                  <h4 className="chatbot-text09">Trash</h4>
                </div>
              </div>
            </div>

            <div className="chatbot-body1">{components[tab]}</div>
          </div>
        </div>
      </div>

      <div className="chatbot-container51">
        <div className="chatbot-newbotwidget">
          <div className="chatbot-container52">
            <div className="chatbot-container53">
              <h3 className="chatbot-text33">Edit {tab == "flows" ? "Flow" : "Trigger"}</h3>
            </div>
            <div className="chatbot-container54">
              <svg
                viewBox="0 0 1024 1024"
                className="chatbot-icon20"
                onClick={() => {
                  document.getElementsByClassName("chatbot-container51")[0].style.display = "none";
                }}
              >
                <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
              </svg>
            </div>
          </div>
          <div className="chatbot-container55">
            <input
              type="text"
              value={current_node.name}
              onChange={(e) => {
                setCurrent_node({...current_node, name: e.target.value});
              }}
              placeholder={tab == "flows" ? "Flow name" : "Trigger Name"}
              className="chatbot-textinput6 input"
            />
          </div>

          {tab == "triggers" && (
            <div className="chatbot-container56">
              <div className="chatbot-container57">
                <div data-thq="thq-dropdown" className="chatbot-thq-dropdown1 list-item">
                  <Select
                    value={current_node.trigger}
                    style={{width: "100%"}}
                    onChange={(value) => {
                      if (value === "welcome message" || value === "default reply") {
                        delete current_node.keywords;
                      }

                      setCurrent_node({...current_node, trigger: value});
                    }}
                    placeholder="Select Trigger"
                    options={condition_options}
                  />
                </div>
              </div>
              {(current_node.trigger === "message contains" || current_node.trigger === "message is") && (
                <input
                  type="text"
                  value={current_node.keywords}
                  onChange={(e) => {
                    setCurrent_node({...current_node, keywords: e.target.value.trim().toLowerCase()});
                  }}
                  placeholder="keywords, comma-separated values"
                  className="chatbot-textinput7 input"
                />
              )}

              <div className="chatbot-container57" style={{marginTop: "20px", display: "flex", flexDirection: "row", alignItems: "center"}}>
                <div style={{width: "50%", display: "flex", flexDirection: "row", alignItems: "center"}}>
                  <span>Target Flow</span>
                </div>
                <div style={{width: "50%", height: "100%"}}>
                  <Select
                    value={current_node.target_id}
                    onChange={(value) => {
                      setCurrent_node({...current_node, target_id: value});
                    }}
                    placeholder="Select target flow"
                    options={chatbot.flows.map((flow) => ({
                      label: flow.name, // Replace 'customLabel' with the property name you want to use as the label
                      value: flow.id, // Replace 'customValue' with the property name you want to use as the value
                    }))}
                  />{" "}
                </div>
              </div>
            </div>
          )}

          <div className="chatbot-container58">
            <button
              className="chatbot-button5 button"
              onClick={() => {
                document.getElementsByClassName("chatbot-container51")[0].style.display = "none";
              }}
            >
              Cancel
            </button>
            <Button loading={create_loading} className="chatbot-button6 button" onClick={HandleEditSave}>
              Save
            </Button>
          </div>
        </div>
      </div>

      <Modal title="Webhook Subscription" open={webhook_modal} onOk={HandleWebhookModalClose} onCancel={HandleWebhookModalClose}>
        <Form>
          <Form.Item label="Callback URL" style={form_item_style}>
            <Input value={webhook.url} type="text" addonAfter={<CopyOutlined style={{cursor: "pointer"}} onClick={() => HandleCopy(webhook.url)} />} />
          </Form.Item>

          <Form.Item label="Verify token" style={form_item_style}>
            <Input value={webhook.verify_token} type="text" addonAfter={<CopyOutlined style={{cursor: "pointer"}} onClick={() => HandleCopy(webhook.verify_token)} />} />
          </Form.Item>
        </Form>
      </Modal>

      <Modal title="New Broadcast" okText="Save" open={creating_broadcast} onOk={create_new_broadcast} onCancel={cancel_create_broadcast}>
        <Form>
          <Form.Item label="Broadcast Name" style={form_item_style}>
            <Input
              value={broadcast.name}
              type="text"
              onChange={(e) => {
                let updated_broadcast = {...broadcast};
                updated_broadcast.name = e.target.value;
                setBroadcast(updated_broadcast);
              }}
            />
          </Form.Item>

          <Form.Item label="Type" style={form_item_style}>
            {/* immediately or later */}
            <Select
            value={broadcast.schedule_type}
              onSelect={(value) => {
                let updated_broadcast = {...broadcast};
                updated_broadcast.schedule_type = value;
                setBroadcast(updated_broadcast);
              }}
              options={[
                {label: "Immediately", value: "immediately"},
                {label: "Schedule later", value: "later"},
              ]}
            />
          </Form.Item>

          {broadcast.schedule_type == "later" && (
            <Form.Item label="Schedule date" style={form_item_style}>
              <DatePicker
              value={dayjs(broadcast.scheduled_date, { format: "YYYY-MM-DD HH:mm" })} 
                showTime={{
                  format: "HH:mm",
                }}
                format="YYYY-MM-DD HH:mm"
                onChange={(value, dateString) => {
                  let new_broadcast = {...broadcast}
                  new_broadcast.scheduled_date = dateString
                  console.log(new_broadcast)
                  setBroadcast(new_broadcast)
                }}
                onOk={(value) => {
                  console.log("onOk: ", value);
                }}
              />
            </Form.Item>
          )}
        </Form>
      </Modal>
    </div>
  );
};

export default Chatbot;
