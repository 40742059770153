import React, {useState, useEffect} from "react";
import {Helmet} from "react-helmet";
import {Select} from "antd";
import Sidebar from "../components/sidebar";
import ChatbotItem from "../components/chatbot";
import Loading from "../components/loading";
import {message as toast, Spin} from "antd";
import "./chatbots.css";
import {base_url} from "../constants";
import {update_bot} from "../apis/index";
import {Form, Input, Modal, Button} from "antd";
import {Handle} from "reactflow";
import { CopyOutlined } from "@ant-design/icons";

const Chatbots = (props) => {
  const supported_channels = [
    {label: "Whatsapp", value: "whatsapp"},
    {label: "Dialogflow", value: "dialogflow"},
    {label: "Custom", value: "custom"},
  ];

  const whatsapp_api_providers = [
    {label: "Whatsapp (Cloud Api)", value: "whatsapp"},
    {label: "Wapi", value: "wapi"},
  ];

  const [chatbots, setChatbots] = useState([]);
  const [channel, setChannel] = useState(null);
  const [setting, setSetting] = useState({});
  const [newBotName, setNewBotName] = useState("New Bot");
  const [loading, setLoading] = useState(false);
  const [form_open, setForm_open] = useState(false);
  const [webhook, setWebhook] = useState({})
  const [webhook_modal, setWebhook_modal] = useState(false)

  const form_item_style = {marginBottom: "15px"};

  const HandleChannelChange = (channel) => {
    if (channel == "whatsapp") {
      setSetting({...setting, api_provider: "whatsapp"});
    }else if(channel == "dialogflow"){
      setSetting({...setting, api_provider: "dialogflow"});
    }else{
      setSetting({...setting, api_provider: "custom"})
    }
    setChannel(channel);
  };

  useEffect(() => {
    const onLoad = async () => {
      setLoading(true);
      await get_chatbots();
      setLoading(false);
    };

    onLoad();
  }, []);

  const validate_api_provider_requirements = () => {
    const requirements = {
      wapi: ["account_id", "api_key"],
      twilio: ["phone_number", "account_sid", "auth_token"],
      whatsapp: ["phone_number_id", "waba_id", "system_access_token"],
      dialogflow: []
    };
    let settings = setting;

    const apiProvider = settings.api_provider;
    const requiredFields = requirements[apiProvider];

    if (!requiredFields && requiredFields.length != 0) {
      toast.error("Invalid API provider");
      return false;
    }

    const missingFields = requiredFields.filter((field) => !settings[field]);

    if (missingFields.length > 0) {
      // Handle the case where required fields are missing.
      toast.error(`Missing required fields: ${missingFields.join(", ")}`);
      return false;
    }

    return true;
  };

  const get_chatbots = async () => {
    try {
      const response = await fetch(base_url + "/bot", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          access_token: localStorage.getItem("access_token"),
        },
      });

      if (!response.ok) {
        const errorResponse = await response.json(payload);
        const errorMessage = errorResponse.message;
        return toast.error(errorMessage);
      }

      const result = await response.json();
      if (result.data) {
        setChatbots(result.data);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleCreateNewBot = async (urgency) => {
    if(urgency == "now"){
      let validation_passed = validate_api_provider_requirements();
      if (!validation_passed) {
        return;
      }
    }

    if (!newBotName) {
      return toast.error("Please enter a bot name");
    }

    if (!channel) {
      return toast.error("Please select a bot channel");
    }


    let payload = {name: newBotName, channel: channel, last_modified: new Date().toISOString(), isActive: true, settings: setting};
    try {
      const response = await fetch(base_url + "/bot", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          access_token: localStorage.getItem("access_token"),
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorResponse = await response.json(payload);
        const errorMessage = errorResponse.message;
        return toast.error(errorMessage);
      }

      const result = await response.json();
      payload._id = result._id;
      var element = document.getElementsByClassName("chatbots-formnewbot")[0];
      if (element) {
        element.style.display = "none";
      }
      setChatbots([...chatbots, payload]);
      setForm_open(false);

      if(result.webhook){
        setWebhook(result.webhook)
        setWebhook_modal(true)
      }
      return toast.success("Bot created");
    } catch (error) {
      toast.error(error.message);
    }
  };

  const NewBotFormOpen = async (e) => {
    setForm_open(true);
  };

  const Handle_Form_Close = async (e) => {
    setForm_open(false);
  };
  
  const HandleWebhookModalClose = () => {
    setWebhook_modal(false)
  }

  const HandleCopy = (value) => {
    const tempInput = document.createElement('input');
    tempInput.value = value;
    document.body.appendChild(tempInput);

    // Select and copy the value from the temporary input element
    tempInput.select();
    document.execCommand('copy');

    // Remove the temporary input element
    document.body.removeChild(tempInput);
    toast.success("Copied to Clipboard")
  };


  return (
    <div className="chatbots-container">
      <Helmet>
        <title>chatbots - Chatbot Builder</title>
        <meta property="og:title" content="chatbots - Chatbot Builder" />
      </Helmet>
      <div className="chatbots-container01">
        <Sidebar></Sidebar>
        <div className="chatbots-mainbody">
          <div className="chatbots-navbar">
            <div className="chatbots-container02">
              <div className="chatbots-container03">
                <h3>Chatbots</h3>
              </div>
              <div className="chatbots-container04">
                <button className="chatbots-button button" onClick={NewBotFormOpen}>
                  <span>
                    <span>Create New Bot</span>
                    <br></br>
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div className="chatbots-body">
            <div className="chatbots-chatbotcontainer">
              <div className="chatbots-chatbotheader">
                <div className="chatbots-container05">
                  <span className="chatbots-text04">Avatar</span>
                </div>
                <div className="chatbots-container06">
                  <span className="chatbots-text05">Name</span>
                </div>
                <div className="chatbots-container07">
                  <span className="chatbots-text06">Channel</span>
                </div>
                {/* <div className="chatbots-container08">
                  <span className="chatbots-text07">Messages Sent</span>
                </div> */}
                <div className="chatbots-container09">
                  <span className="chatbots-text08">Last Modified</span>
                </div>
                {/* <div className="chatbots-container10">
                  <span className="chatbots-text09">Status</span>
                </div> */}
              </div>
              {loading ? (
                <Loading />
              ) : (
                <>
                  {chatbots.map((chatbot) => (
                    <ChatbotItem key={chatbot._id} chatbot={chatbot} chatbots={chatbots} update_bot={update_bot} setChatbots={setChatbots} />
                  ))}
                </>
              )}
            </div>
          </div>
        </div>

        <Modal
          title={"Create New Bot"}
          footer={[
            <Button key="later" onClick={() => handleCreateNewBot("later")}>
              Setup later
            </Button>,
            <Button key="submit" type="primary" loading={loading} onClick={() => handleCreateNewBot("now")}>
              Save
            </Button>,
          ]}
          open={form_open}
          centered={true}
          okText={"save"}
          onCancel={Handle_Form_Close}
          onOk={handleCreateNewBot}
        >
          <Form layout="vertical">
            <Form.Item label="Name" style={form_item_style}>
              <Input value={newBotName} onChange={(e) => setNewBotName(e.target.value)} type="text" placeholder="Enter the bot name" />
            </Form.Item>
            <Form.Item label="Channel" style={form_item_style}>
              <Select value={channel} onSelect={HandleChannelChange} style={{width: "100%"}} placeholder="Select a channel" options={supported_channels} />
            </Form.Item>
            {channel == "whatsapp" && (
              // <>
              //   <Form.Item label="Api Provider" style={form_item_style}>
              //     <Select value={setting.api_provider} onChange={(value) => setSetting({...setting, api_provider: value})} style={{width: "100%"}} placeholder="Select Api Provider" options={whatsapp_api_providers} />
              //   </Form.Item>

              //   {setting.api_provider === "wapi" && (
              //     <>
              //       <Form.Item label="Account Id" style={form_item_style}>
              //         <Input value={setting.account_id} onChange={(e) => setSetting({...setting, account_id: e.target.value})} type="text" placeholder="account_id" />
              //       </Form.Item>

              //       <Form.Item label="Api Key" style={form_item_style}>
              //         <Input value={setting.api_key} onChange={(e) => setSetting({...setting, api_key: e.target.value})} type="text" placeholder="api_key" />
              //       </Form.Item>
              //     </>
              //   )}

              //   {setting.api_provider === "twilio" && (
              //     <>
              //       <Form.Item label="Phone Number" style={form_item_style}>
              //         <Input value={setting.phone_number} onChange={(e) => setSetting({...setting, phone_number: e.target.value})} type="text" placeholder="Phone Number" />
              //       </Form.Item>

              //       <Form.Item label="Account SID" style={form_item_style}>
              //         <Input value={setting.account_id} onChange={(e) => setSetting({...setting, account_sid: e.target.value})} type="text" placeholder="Account SID" />
              //       </Form.Item>

              //       <Form.Item label="Auth Token" style={form_item_style}>
              //         <Input value={setting.auth_token} onChange={(e) => setSetting({...setting, auth_token: e.target.value})} type="text" placeholder="Auth Token" />
              //       </Form.Item>
              //     </>
              //   )}

              //   {setting.api_provider === "whatsapp" && (
              //     <>
              //       <Form.Item label="Phone Number" style={form_item_style}>
              //         <Input value={setting.phone_number} onChange={(e) => setSetting({...setting, phone_number: e.target.value})} type="text" placeholder="Phone Number" />
              //       </Form.Item>

              //       <Form.Item label="Account SID" style={form_item_style}>
              //         <Input value={setting.account_id} onChange={(e) => setSetting({...setting, account_sid: e.target.value})} type="text" placeholder="Account SID" />
              //       </Form.Item>

              //       <Form.Item label="Auth Token" style={form_item_style}>
              //         <Input value={setting.auth_token} onChange={(e) => setSetting({...setting, auth_token: e.target.value})} type="text" placeholder="Auth Token" />
              //       </Form.Item>
              //     </>
              //   )}
              // </>

              <>
                <Form.Item label="Phone Number ID" style={form_item_style}>
                  <Input value={setting.phone_number_id} onChange={(e) => setSetting({...setting, phone_number_id: e.target.value})} type="text" placeholder="Phone Number ID" />
                </Form.Item>

                <Form.Item label="Whatsapp Business Account ID" style={form_item_style}>
                  <Input value={setting.waba_id} onChange={(e) => setSetting({...setting, waba_id: e.target.value})} type="text" placeholder="Whatsapp Business Account ID" />
                </Form.Item>

                <Form.Item label="System Access Token" style={form_item_style}>
                  <Input value={setting.system_access_token} onChange={(e) => setSetting({...setting, system_access_token: e.target.value})} type="text" placeholder="System Access Token" />
                </Form.Item>
              </>
            )}

            {channel == "custom" && (
              <Form.Item label="Webhook" style={form_item_style}>
                <Input value={setting.webhook} onChange={(e) => setSetting({...setting, webhook: e.target.value})} type="text" placeholder="webhook" />
              </Form.Item>
            )}


          </Form>
        </Modal>

        <Modal title="Webhook Subscription" open={webhook_modal} onOk={HandleWebhookModalClose} onCancel={HandleWebhookModalClose}>
          <Form>
            <Form.Item label="Callback URL" style={form_item_style}>
              <Input value={webhook.url} type="text" addonAfter={<CopyOutlined style={{cursor: "pointer"}} onClick={() => HandleCopy(webhook.url)} />} />
            </Form.Item>

            <Form.Item label="Verify token" style={form_item_style}>
              <Input value={webhook.verify_token} type="text" addonAfter={<CopyOutlined style={{cursor: "pointer"}} onClick={() => HandleCopy(webhook.verify_token)} />} />
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </div>
  );
};

export default Chatbots;
