import Sequence_item from "./sequence_item";
import {convert_utc_to_month_date} from "../../../utils/helper";
import {message as toast, Dropdown} from "antd";
import {MoreOutlined} from "@ant-design/icons";
import {useState, useEffect} from "react";

const Sequence = ({chatbot, delete_node, create_node, setChatbot, HandleEditNode, HandleCreateNode, setTab, bot_id}) => {
  const [sequence, setSequence] = useState(null);
  const [items, setItems] = useState([])

  
  useEffect(() => {
    if(sequence){
      setItems(sequence.items)
    }
  }, [sequence]);

  useEffect(() => {
    if(!sequence){
      return
    }
    const sequenceIndex = chatbot.sequences.findIndex(seq => seq.id === sequence.id);

    if (sequenceIndex !== -1) {

      const updatedChatbot = { ...chatbot };
      sequence.items = items
      create_node(JSON.stringify(sequence))
      updatedChatbot.sequences[sequenceIndex] = sequence;

      setChatbot(updatedChatbot);
    }

  }, [items]);



  const more_buttons = [
    {
      label: "Edit",
      key: "edit",
    },
    {
      label: "Delete",
      key: "delete",
      danger: true,
    },
  ];


  const Handle_More_Clicked =async ({key}, sequence)=> {
    if (key == "edit") {
      HandleEditNode(sequence);
    }
    if (key == "delete") {
      const confirmDelete = window.confirm("Are you sure you want to delete the selected trigger?");
      if (!confirmDelete) {
        return;
      }
      let id_to_be_deleted = trigger.id;
      let response = await delete_node(id_to_be_deleted);
      if (response) {
        toast.success("Trigger deleted successfully");
        chatbot.sequences = chatbot.sequences.filter((trigger) => trigger.id !== id_to_be_deleted);
        setChatbot({...chatbot});
      }
    }
  };

  
  const HandleSequenceItemClick = async (sequence) => {
    console.log(sequence);
    setSequence(sequence);
  };

  const Navigate_Home = () => {
    setSequence(null);
  };

  let to = "/kdkdk";

  return (
    <>
      {sequence ? (
        <div>
          {" "}
          <div className="sequence-header">
            <h2 className="sequence-text" onClick={Navigate_Home}>
              Sequences
            </h2>
            <h2 className="sequence-text1">/</h2>
            <h2 className="sequence-text2">{sequence.name}</h2>
          </div>
          <Sequence_item sequence={items} setSequence={setItems} chatbot={chatbot} HandleCreateNode={HandleCreateNode} bot_id={bot_id}/>
        </div>
      ) : (
        <div className="chatbot-triggercontainer">
          <div className="chatbot-triggeritem">
            <div className="chatbot-container23">
              <span className="chatbot-text16">Status</span>
            </div>
            <div className="chatbot-container24">
              <span className="chatbot-text17">Name</span>
            </div>
            <div className="chatbot-container25">
              {/* <span className="chatbot-text18">Subscribers</span> */}
            </div>
          </div>

          {chatbot.sequences.length == 0 && (
            <div className="chatbot-emptytrigger">
              <span className="chatbot-text19">No Data</span>
            </div>
          )}

          {chatbot.sequences.map((sequence) => (
            <div className="chatbot-triggeritem1">
              <div
                className="chatbot-container26"
                onClick={(e) => {
                  e.preventDefault();
                  let isActive = sequence.isActive;
                  sequence.isActive = !isActive;
                  create_node(JSON.stringify(sequence));
                  setSequence(sequence);
                  setChatbot({...chatbot});
                }}
              >
                {sequence.isActive ? (
                  <svg viewBox="0 0 1170.2857142857142 1024" className="chatbot-icon12">
                    <path d="M0 512c0-201.714 164-365.714 365.714-365.714h438.857c201.714 0 365.714 164 365.714 365.714s-164 365.714-365.714 365.714h-438.857c-201.714 0-365.714-164-365.714-365.714zM804.571 804.571c161.143 0 292.571-131.429 292.571-292.571s-131.429-292.571-292.571-292.571-292.571 131.429-292.571 292.571 131.429 292.571 292.571 292.571z"></path>
                  </svg>
                ) : (
                  <svg viewBox="0 0 1170.2857142857142 1024" className="chatbot-icon10">
                    <path d="M658.286 512c0-161.143-131.429-292.571-292.571-292.571s-292.571 131.429-292.571 292.571 131.429 292.571 292.571 292.571 292.571-131.429 292.571-292.571zM1097.143 512c0-161.143-131.429-292.571-292.571-292.571h-220.571c89.143 66.857 147.429 173.143 147.429 292.571s-58.286 225.714-147.429 292.571h220.571c161.143 0 292.571-131.429 292.571-292.571zM1170.286 512c0 201.714-164 365.714-365.714 365.714h-438.857c-201.714 0-365.714-164-365.714-365.714s164-365.714 365.714-365.714h438.857c201.714 0 365.714 164 365.714 365.714z"></path>
                  </svg>
                )}
              </div>
              <div className="chatbot-container27" style={{cursor: "pointer"}} onClick={() => HandleSequenceItemClick(sequence)}>
                <span className="chatbot-text20">{sequence.name}</span>
              </div>
              <div className="chatbot-container28" style={{cursor: "pointer"}} onClick={() => HandleSequenceItemClick(sequence)}>
                {/* <span className="chatbot-text21">5 </span> */}
              </div>

              <div className="chatbot-container29">
                <Dropdown
                  menu={{
                    items: more_buttons,
                    onClick: (event) => {
                      // Your logic here
                      Handle_More_Clicked(event, sequence);
                    },                  }}
                >
                  <MoreOutlined style={{fontSize: "30px"}} onClick={(e) => e.preventDefault()} />
                </Dropdown>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default Sequence;
