import Loading from "../../../components/loading";

const Response = ({test_response, loading}) => {
  return (
    <div className="api-responsecontainer">
      <div className="api-bodyheader1">
        <span className="api-text16">
          <span>
            Status:
            <span
              dangerouslySetInnerHTML={{
                __html: " ",
              }}
            />
          </span>
          <span className="api-text18">{test_response.status_code}</span>
          <br></br>
        </span>
      </div>
      <div className="api-jsonbodycontainer">{loading ? <Loading type={"circle"} /> : <pre>{test_response.data}</pre>}</div>
    </div>
  );
};

export default Response;
