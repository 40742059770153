import React, { useRef } from "react";

const File_Uploader = ({ onChange, children }) => {
    const fileInputRef = useRef(null);

    const handleFileSelect = (e) => {
        if (onChange) {
            onChange(e);
        }
    };

    const triggerFileInputClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    return (
        <>
            <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileSelect}
            />
            {React.cloneElement(children, { onClick: triggerFileInputClick })}
        </>
    );
};

export default File_Uploader;
