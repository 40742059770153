import {Link} from "react-router-dom";
import {convert_utc_to_month_date} from "../../../utils/helper"

const FlowItem = ({flow, HandleEditNode, handleFlowSelect, bot_id}) => {
  const to = `/${bot_id}/flowchart/${flow.id}`;
  return (
    <div className="chatbot-flowitem1">
      <div className="chatbot-container19">
        <input type="checkbox" onChange={() => handleFlowSelect(flow.id)} checked={flow.selected} className="chatbot-checkbox1" />
      </div>
      <Link to={to} className="chatbot-container20">
        <span className="chatbot-text14">{flow.name}</span>
      </Link>
      <Link to={to} className="chatbot-container21">
        <span className="chatbot-text15">{convert_utc_to_month_date(flow.last_modified)}</span>
      </Link>
      <div className="chatbot-container22">
        <svg
          viewBox="0 0 1024 1024"
          className="chatbot-icon08"
          onClick={() => {
            HandleEditNode(flow);
          }}
        >
          <path d="M884 300l-78 78-160-160 78-78q12-12 30-12t30 12l100 100q12 12 12 30t-12 30zM128 736l472-472 160 160-472 472h-160v-160z"></path>
        </svg>
      </div>
    </div>
  );
};

export default FlowItem;
