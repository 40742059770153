import {abbreviate_last_messaged_time} from "../../utils/helper";
import Loading from "../../components/loading";

const Contacts = ({contacts, loading, handle_conversation_click}) => {

  return (
    <div className="livechat-chat-section">
      <div className="livechat-chat-header">
        <div className="livechat-search-bar">
          <input type="checkbox" className="livechat-checkbox" />
          <input type="text" placeholder="search by name" className="livechat-textinput" />
        </div>
        {/* <div className="livechat-search-filter">
          <svg viewBox="0 0 804.5714285714286 1024" className="livechat-icon12">
            <path d="M801.714 168.571c5.714 13.714 2.857 29.714-8 40l-281.714 281.714v424c0 14.857-9.143 28-22.286 33.714-4.571 1.714-9.714 2.857-14.286 2.857-9.714 0-18.857-3.429-25.714-10.857l-146.286-146.286c-6.857-6.857-10.857-16-10.857-25.714v-277.714l-281.714-281.714c-10.857-10.286-13.714-26.286-8-40 5.714-13.143 18.857-22.286 33.714-22.286h731.429c14.857 0 28 9.143 33.714 22.286z"></path>
          </svg>
          <img alt="image" src="/sort-200h.png" className="livechat-image03" />
        </div> */}
      </div>

      {loading ? (
        <Loading />
      ) : (
        <div className="livechat-chat-container">
          {contacts.map((contact) => (
            <div
              key={contact._id}
              className="livechat-chat-item"
              onClick={() => {
                handle_conversation_click(contact);
              }}
            >
              <input type="checkbox" className="livechat-checkbox01" />
              <img alt="image" src="/user-thumbnail-200w-200w.png" className="livechat-image04" />
              <div className="livechat-msg-cont">
                <span className={`${contact.is_read === false ? "livechat-fullname-unread" : "livechat-text12"}`} >  {contact.variables && contact.variables.full_name ? contact.variables.full_name : ""}</span>
                <span  className={`${contact.is_read === false ? "livechat-message-unread" : "livechat-text13"}`}>{contact.variables?.last_contact_message ?? ""}</span>
              </div>
              <div className="livechat-time-cont">
                <span className="livechat-text14">{abbreviate_last_messaged_time(contact.last_messaged)}</span>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Contacts;
