import React from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const Loading = ({ type = "default", size = "large" }) => {
  if (type === "circle") {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "none"
        }}
      >
        <Spin indicator={antIcon} />
      </div>
    );
  } else {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "none"
        }}
      >
        <Spin size={size} />
      </div>
    );
  }
};

export default Loading;
