import {Input} from "antd";
import {useCallback, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Handle, Position, useStore, Panel} from "reactflow";
import {bindActionCreators} from "redux";
import {flowActions} from "../store/actions";
import CustomHandle from "./customEdge/CustomHandle";
import "./FlowNodes.css";

const connectionNodeIdSelector = (state) => state.connectionNodeId;

const FlowMessageNode = ({id, data, isConnectable}) => {
  const handleStyleLeft = {
    top: 18,
    width: 20,
    height: 20,
    left: -10,
    borderColor: "white",
    borderWidth: "3.5px",
    boxShadow: "0 8px 8px rgba(0,0,0,.1)",
    backgroundColor: "rgb(56, 135, 255)",
    zIndex: 2,
  };

  const handleStyleRight = {
    top: 18,
    width: 20,
    height: 20,
    right: -10,
    borderColor: "white",
    borderWidth: "3.5px",
    boxShadow: "0 8px 8px rgba(0,0,0,.1)",
    backgroundColor: "rgb(56, 135, 255)",
  };

  const handleStyleOption = {
    width: 20,
    height: 20,
    right: -10,
    borderColor: "white",
    borderWidth: "3.5px",
    boxShadow: "0 8px 8px rgba(0,0,0,.1)",
    backgroundColor: "green",
  };

  const dispatch = useDispatch();
  const flowState = useSelector((state) => state.flow);
  const {activeNodeId} = flowState;

  const {onMouseEnter, onMouseLeave, onHandleDragEnd, onDeleteNode} = bindActionCreators(flowActions, dispatch);

  const connectionNodeId = useStore(connectionNodeIdSelector);
  const isTarget = connectionNodeId && connectionNodeId !== id;
  const [hover, setHover] = useState(false);
  const handleMouseEnter = () => {
    setHover(true);
  };

  const handleMouseLeave = () => {
    setHover(false);
  };

  const HandleDuplicate = useCallback((e) => {
    e.click_type = "duplicate";
  });

  const HandleDelete = (e) => {
    e.click_type = "delete";
  };

  return (
    <div style={{flex: 1}} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <div
        style={{
          borderWidth: "2px",
          borderColor: isTarget && hover ? "rgb(56, 135, 255)" : "transparent",
          width: "400px",
        }}
        className="message-node"
      >
        <div className="message-node-header">
          <p>Message</p>
        </div>
        <div className="message-node-container">
          {data.file && data.file.media_url && (
            <div className="testingpage-filecontainer">
              <div className="testingpage-fiileheader">
                <span className="testingpage-text">Attachment</span>
              </div>
              <div className="testingpage-filebody">
                <span className="testingpage-text1">{data.file.media_url}</span>
              </div>
            </div>
          )}
              
              {data.message_type == "template" && (
              <div className="testingpage-filebody">
              <span className="testingpage-text1">Template: </span>
            </div>
              )}

          <Input.TextArea placeholder="Your message" value={data?.message !== "" ? data.message : ""} className="message-node-input" autoSize={{minRows: 2, maxRows: 18}} readOnly={true} style={{marginBottom: "1rem", cursor: "pointer"}} />

          {data.input == "yes" && (
            <>
              <div className="message-option">
                <span style={{color: "#9e34eb"}}>Waiting for response</span>
              </div>

              {data.expires_in && data.expires_in.unit !== "never" && data.expires_in.value != 0 && (
                <div className="api-option-list">
                  <div className="api-option">
                    <Panel className="norespond-box">
                      <p>no response</p>
                      <Handle type="source" position={Position.Right} id={`no_response`} style={{...handleStyleOption, backgroundColor: "red"}} isConnectable={isConnectable} />
                    </Panel>
                  </div>
                </div>
              )}
            </>
          )}

          {data.button_data?.buttons?.map((button, index) => (
            <div key={button.id} className="message-option">
              <Panel className="message-option-box">
                <p>{button.title}</p>
                <Handle type="source" position={Position.Right} id={button.id} style={handleStyleOption} isConnectable={isConnectable} />
              </Panel>
            </div>
          ))}

          <Handle
            type="target"
            position={Position.Left}
            id="z"
            style={{
              position: "absolute",
              bottom: 0,
              width: "100%",
              height: "100%",
              zIndex: isTarget ? 50 : -1,
              backgroundColor: "transparent",
              borderRadius: 0,
              opacity: 0,
            }}
            isConnectable={isConnectable}
          />
        </div>

        <Handle type="target" position={Position.Left} id="a" style={handleStyleLeft} isConnectable={isConnectable} />
        <Handle type="source" position={Position.Right} style={handleStyleRight} id="b" isConnectable={isConnectable} />
      </div>

      {hover && (
        <div className="bottomaction-button-container">
          <div className="bottomaction-btns">
            <div className="bottomaction-delete" onClick={HandleDelete}>
              <svg viewBox="0 0 1024 1024" className="bottomaction-icon">
                <path d="M128 320v640c0 35.2 28.8 64 64 64h576c35.2 0 64-28.8 64-64v-640h-704zM320 896h-64v-448h64v448zM448 896h-64v-448h64v448zM576 896h-64v-448h64v448zM704 896h-64v-448h64v448z"></path>
                <path d="M848 128h-208v-80c0-26.4-21.6-48-48-48h-224c-26.4 0-48 21.6-48 48v80h-208c-26.4 0-48 21.6-48 48v80h832v-80c0-26.4-21.6-48-48-48zM576 128h-192v-63.198h192v63.198z"></path>
              </svg>
            </div>
            <div className="bottomaction-copy" onClick={HandleDuplicate}>
              <svg viewBox="0 0 1024 1024" className="bottomaction-icon3">
                <path d="M810 896v-598h-468v598h468zM810 214q34 0 60 25t26 59v598q0 34-26 60t-60 26h-468q-34 0-60-26t-26-60v-598q0-34 26-59t60-25h468zM682 42v86h-512v598h-84v-598q0-34 25-60t59-26h512z"></path>
              </svg>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FlowMessageNode;
