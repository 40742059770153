import React from "react";
import "./LeftMenu.css";

import Message from "../assets/icons8-chat-message-100.png";
import Flow from "../assets/icons8-tree-structure-90.png";
import Action from "../assets/icons8-sorting-arrows-horizontal-100.png";
import Filter from "../assets/icons8-high-indicator-filter-96.png";
import Api from "../assets/icons8-api-90.png";
import Pause from "../assets/icons8-wall-clock-96.png";
import Random from "../assets/icons8-shuffle-100.png";
import Chatgpt from "../assets/chatgpt-logo.png";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { mapActions } from "../store/actions";
import { useRecoilState } from 'recoil';
import { flow_data_state } from '../../atom';

const LeftMenu = ({ onNodeAdd, reactFlowWrapper = { reactFlowWrapper }, project = { project } }) => {
  const dispatch = useDispatch();
  const { onAddNode } = bindActionCreators(mapActions, dispatch);
  const [flow_data, setFlow_data] = useRecoilState(flow_data_state);

  const handleDragStart = (event) => {
    event.dataTransfer.setData("text/plain", ""); // Required for drag events to work in some browsers
  };

  const handleDragEnd = (event, type) => {
    const { top, left } = reactFlowWrapper.current.getBoundingClientRect();

    let position = project({ x: event.clientX - left - 75, y: event.clientY - top })
    const payload = {
      type,
      position,
    };

    onNodeAdd(payload);
  };

  const handleNodeDragEnd = (event, type) => {
    handleDragEnd(event, type);
  };

  let nodeButtons;

  if(flow_data?.type == "Plugin"){
    nodeButtons = [
      { type: "message", icon: Message, label: "Message" },
      { type: "action", icon: Action, label: "Action" },
      { type: "condition", icon: Filter, label: "Condition" },
      { type: "api", icon: Api, label: "API" },
      { type: "chatgpt", icon: Chatgpt, label: "Chatgpt" }
        ];
  }else{
   nodeButtons = [
      { type: "message", icon: Message, label: "Message" },
      { type: "flow", icon: Flow, label: "Flow" },
      { type: "action", icon: Action, label: "Action" },
      { type: "condition", icon: Filter, label: "Condition" },
      { type: "api", icon: Api, label: "API" },
      { type: "chatgpt", icon: Chatgpt, label: "Chatgpt" },
      { type: "delay", icon: Pause, label: "Delay" }
    ];
  }



  return (
    <div className="left-menu-container">
      {nodeButtons.map((button) => (
        <div
          key={button.type}
          className="node-btn"
          draggable="true"
          onDragStart={handleDragStart}
          onDragEnd={(event) => handleNodeDragEnd(event, button.type)}
        >
          <img src={button.icon} alt={button.label} />
          <p>{button.label}</p>
        </div>
      ))}
    </div>
  );
};

export default LeftMenu;
