const whitelisted_paths  = ['/', '/login', '/signup', '/verifyemail', '/changepassword'];
// const base_url = "http://localhost:5000"
const base_url = "https://api.50chats.live"
const S3AccessKey = 'AKIA55ZUKDMVNBROTIHO'
const S3AccessKeySecret = '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'
const S3BucketName = '50chats'

const azure_storage_name = '50chats'
const azure_container_name = 'maincontainer'



export {
    whitelisted_paths,
    base_url,
    S3AccessKey,
    S3AccessKeySecret,
    S3BucketName,
    azure_container_name,
    azure_storage_name
}

