import { Input } from "antd";
import { useCallback, useState } from "react";
import { Handle, Position, useStore } from "reactflow";
import "./FlowNodes.css";

const connectionNodeIdSelector = (state) => state.connectionNodeId;

const FlowStarterNode = ({ id, data, isConnectable }) => {

  const handleStyleRight = {
    width: 20,
    height: 20,
    right: 10,
    borderColor: "white",
    borderWidth: "3.5px",
    boxShadow: "0 8px 8px rgba(0,0,0,.1)",
    backgroundColor: "rgb(56, 135, 255)",
  };

  const connectionNodeId = useStore(connectionNodeIdSelector);
  const isTarget = connectionNodeId && connectionNodeId !== id;
  const [hover, setHover] = useState(false);
  const handleMouseEnter = () => {
    setHover(true);
  };

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={() => setHover(false)}
      style={{
        width: 200,
        height: 50,
        alignItems: "center",
        display: 'flex',
        flexDirection: 'row',
        borderRadius: 20,
        padding: 10,
        backgroundColor: '#438fe0',
        borderColor: isTarget && hover ? "rgb(56, 135, 255)" : "transparent",
      }}
    >
        <span style={{color: 'white', fontSize: 20}}>Start</span>
        
      <Handle
        type="source"
        position={Position.Right}
        id="b"
        style={handleStyleRight}
        isConnectable={isConnectable}
      />
    </div>
  );
};

export default FlowStarterNode;
