import React, { useState, useEffect } from 'react'

function Notifications() {
      
    const [socialCheck, setSocialCheck] = useState({"messanger":true,"sms":false,"email":true,"mobile":true,"telegram":false})
    const [notifyCheck, setNotifyCheck] = useState({"message":true,"conversation":false,"assigned":true})
    const [liveChat, setLiveChat] = useState(false)
    
    return (
    <div className='flex flex-col'>
      {/* <div className='flex md:flex-row flex-col md:gap-0 gap-4 justify-between items-center min-h-[10vh] bg-white border border-gray-300 p-6 rounded-md'>
        <h2 className='text-[1em] font-semibold'>'Notify Assignees' Action</h2>
        <div className='flex flex-col md:items-start items-center justify-center md:w-[25vw] w-[50vw] gap-2 my-4'>
            <div className="flex items-center justify-center">
                <input id="checkbox-1" aria-describedby="checkbox-1" type="checkbox" className="bg-gray-50 border-gray-300 focus:ring-3 focus:ring-blue-300 h-4 w-4 rounded" checked={socialCheck["messanger"]} onChange={() => setSocialCheck(socialCheck => ({...socialCheck,messanger:!socialCheck["messanger"]}))}/>
                <label htmlFor="checkbox-1" className="font-semibold ml-3 text-gray-900 text-[0.9em]">Messanger</label>
            </div>
            <div className="flex items-center justify-center">
                <input id="checkbox-2" aria-describedby="checkbox-1" type="checkbox" className="bg-gray-50 border-gray-300 focus:ring-3 focus:ring-blue-300 h-4 w-4 rounded" checked={socialCheck["sms"]} onChange={() => setSocialCheck(socialCheck => ({...socialCheck,sms:!socialCheck["sms"]}))}/>
                <label htmlFor="checkbox-2" className="font-semibold ml-3 text-gray-900 text-[0.9em]">SMS</label>
            </div>
            <div className="flex items-center justify-center">
                <input id="checkbox-3" aria-describedby="checkbox-1" type="checkbox" className="bg-gray-50 border-gray-300 focus:ring-3 focus:ring-blue-300 h-4 w-4 rounded" checked={socialCheck["email"]} onChange={() => setSocialCheck(socialCheck => ({...socialCheck,email:!socialCheck["email"]}))}/>
                <label htmlFor="checkbox-3" className="font-semibold ml-3 text-gray-900 text-[0.9em]">Email</label>
            </div>
            <div className="flex items-center justify-center">
                <input id="checkbox-4" aria-describedby="checkbox-1" type="checkbox" className="bg-gray-50 border-gray-300 focus:ring-3 focus:ring-blue-300 h-4 w-4 rounded" checked={socialCheck["mobile"]} onChange={() => setSocialCheck(socialCheck => ({...socialCheck,mobile:!socialCheck["mobile"]}))}/>
                <label htmlFor="checkbox-4" className="font-semibold ml-3 text-gray-900 text-[0.9em]">Mobile Push Notification</label>
            </div>
            <div className="flex items-center justify-center">
                <input id="checkbox-5" aria-describedby="checkbox-1" type="checkbox" className="bg-gray-50 border-gray-300 focus:ring-3 focus:ring-blue-300 h-4 w-4 rounded" checked={socialCheck["telegram"]} onChange={() => setSocialCheck(socialCheck => ({...socialCheck,telegram:!socialCheck["telegram"]}))}/>
                <label htmlFor="checkbox-5" className="font-semibold ml-3 text-gray-900 text-[0.9em]">Telegram</label>
            </div>
        </div>
        <h3 className='md:w-[30%] w-[70%] text-[0.8em]'>Enable to let 50chats send you notifications when a contact performs a specific action in your broadcasts, welcome messages, opt-in messages, etc.</h3>
      </div> */}
      {/* <div className='flex md:flex-row flex-col justify-between items-center min-h-[10vh] bg-white p-6 border border-gray-300 rounded-md'>
        <h2 className='text-[1em] font-semibold'>Live Chat Desktop Notifications</h2>
        <div className='flex flex-col md:items-start items-center justify-center md:w-[25vw] w-[50vw] gap-2 my-4'>
            <h2>Notify me when</h2>
            <div className="flex items-center justify-center">
                <input id="message" aria-describedby="checkbox-1" type="checkbox" className="bg-gray-50 border-gray-300 focus:ring-3 focus:ring-blue-300 h-4 w-4 rounded" checked={notifyCheck["message"]} onChange={() => setNotifyCheck(notifyCheck => ({...notifyCheck,message:!notifyCheck["message"]}))}/>
                <label htmlFor="message" className="font-semibold ml-3 text-gray-900 text-[0.9em]">I get a new message from a conversation assigned to me</label>
            </div>
            <div className="flex items-center justify-center">
                <input id="conversation" aria-describedby="checkbox-1" type="checkbox" className="bg-gray-50 border-gray-300 focus:ring-3 focus:ring-blue-300 h-4 w-4 rounded" checked={notifyCheck["conversation"]} onChange={() => setNotifyCheck(notifyCheck => ({...notifyCheck,conversation:!notifyCheck["conversation"]}))}/>
                <label htmlFor="conversation" className="font-semibold ml-3 text-gray-900 text-[0.9em]">There is a new conversation in unassigned folder</label>
            </div>
            <div className="flex items-center justify-center">
                <input id="assigned" aria-describedby="checkbox-1" type="checkbox" className="bg-gray-50 border-gray-300 focus:ring-3 focus:ring-blue-300 h-4 w-4 rounded" checked={notifyCheck["assigned"]} onChange={() => setNotifyCheck(notifyCheck => ({...notifyCheck,assigned:!notifyCheck["assigned"]}))}/>
                <label htmlFor="assigned" className="font-semibold ml-3 text-gray-900 text-[0.9em]">A Conversation is assigned to me</label>
            </div>
        </div>
        <h3 className='md:w-[30%] w-[70%] text-[0.8em]'>Enable instant popup notifications on your desktop about new messages and assigned conversations. If you don’t see the notifications, check your system settings if notifications are on.</h3>
      </div> */}
      <div className='flex md:flex-row flex-col justify-between items-center min-h-[10vh] bg-white p-6 border border-gray-300 rounded-md'>
        <h2 className='text-[1em] font-semibold'>Live Chat Channel Notifications</h2>
        <div className='flex flex-col md:items-start items-center justify-center md:w-[25vw] w-[50vw] gap-2 my-4'>
            <h2>Notify me when</h2>
            <div className="flex items-center justify-center">
                <input id="livechat" aria-describedby="checkbox-1" type="checkbox" className="bg-gray-50 border-gray-300 focus:ring-3 focus:ring-blue-300 h-4 w-4 rounded" checked={liveChat} onChange={() => setLiveChat(!liveChat)}/>
                <label htmlFor="livechat" className="font-semibold ml-3 text-gray-900 text-[0.9em]">A Conversation is assigned to me</label>
            </div>
        </div>
        <h3 className='md:w-[30%] w-[70%] text-[0.8em]'>Live Chat notifications to help you support your audience and track leads in channels connected below. E.g. Mail, SMS, Telegram.</h3>

      </div>
    </div>
  )
}

export default Notifications