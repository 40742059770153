import {convert_utc_to_month_date} from "../../../utils/helper"

const Subscription_plan = ({subscription, setSubscription}) => {
  const product_text = `${subscription.products.messages} messages, ${subscription.products.bots} Bots, ${subscription.products.teams} Teams`;


  return (
    <>
      <div className="settings-sub-body">
        <div className="settings-container2">
          <h1 className="settings-heading1">Free Plan</h1>
          <a className="settings-heading2" href="/#pricing" target="_blank">
            Compare Plans
          </a>
        </div>
        <div className="settings-table">
          <div className="settings-table-row">
            <div className="settings-table-column">
              <span className="settings-text05">Product</span>
            </div>
            <div className="settings-table-column01">
              <span className="settings-text06">Details</span>
            </div>
            <div className="settings-table-column02">
              <span className="settings-text07">Price</span>
            </div>
            <div className="settings-table-column03">
              <span className="settings-text08">Renews On</span>
            </div>
          </div>
          <div className="settings-table-row1">
            <div className="settings-table-column04">
              <span className="settings-text09">{subscription.plan}</span>
            </div>
            <div className="settings-table-column05">
              <span className="settings-text10">{product_text}</span>
            </div>
            <div className="settings-table-column06">
              <span className="settings-text11">${subscription.amount}</span>
            </div>
            <div className="settings-table-column07">
            <span className="settings-text11">{convert_utc_to_month_date(subscription.expires_on)}</span>

            </div>
          </div>
          {/* <div className="settings-table-row2">
              <div className="settings-table-column08">
                <span className="settings-text12">Inbox</span>
              </div>
              <div className="settings-table-column09">
                <span className="settings-text13">Livechat Seats</span>
              </div>
              <div className="settings-table-column10">
                <span className="settings-text14">$10</span>
              </div>
              <div className="settings-table-column11">
                <svg viewBox="0 0 1170.2857142857142 1024" className="settings-icon08">
                  <path d="M658.286 512c0-161.143-131.429-292.571-292.571-292.571s-292.571 131.429-292.571 292.571 131.429 292.571 292.571 292.571 292.571-131.429 292.571-292.571zM1097.143 512c0-161.143-131.429-292.571-292.571-292.571h-220.571c89.143 66.857 147.429 173.143 147.429 292.571s-58.286 225.714-147.429 292.571h220.571c161.143 0 292.571-131.429 292.571-292.571zM1170.286 512c0 201.714-164 365.714-365.714 365.714h-438.857c-201.714 0-365.714-164-365.714-365.714s164-365.714 365.714-365.714h438.857c201.714 0 365.714 164 365.714 365.714z"></path>
                </svg>
                <svg viewBox="0 0 1170.2857142857142 1024" className="settings-icon10">
                  <path d="M0 512c0-201.714 164-365.714 365.714-365.714h438.857c201.714 0 365.714 164 365.714 365.714s-164 365.714-365.714 365.714h-438.857c-201.714 0-365.714-164-365.714-365.714zM804.571 804.571c161.143 0 292.571-131.429 292.571-292.571s-131.429-292.571-292.571-292.571-292.571 131.429-292.571 292.571 131.429 292.571 292.571 292.571z"></path>
                </svg>
              </div>
            </div> */}
        </div>
      </div>
    </>
  );
};

export default Subscription_plan;
