import React, {useEffect} from "react";
import ReactDOM from "react-dom";
import {BrowserRouter as Router, Route} from "react-router-dom";
import {check_if_is_authenticated} from "./utils/helper";
import "./style.css";
import Signup from "./views/signup";
import Contacts from "./views/contacts";
import Chatbot from "./views/chatbot";
import Login from "./views/login";
import Changepassword from "./views/changepassword";
import Chatbots from "./views/chatbots";
import Verifyemail from "./views/verifyemail";
import Home from "./views/home";
import FlowChart from "./views/flowchart";
import Livechat from "./views/livechat";
// import Settings from './views/Settings.jsx'
import Help from "./components/Help/Help";
import Settings from "./views/settings";
import Dashboard from "./components/Home/Dashboard";
import {RecoilRoot} from "recoil";
import { Analytics } from '@vercel/analytics/react';


const App = () => {
  useEffect(() => {
    check_if_is_authenticated();
  }, []);

  return (
    <Router>
      <div>
        <RecoilRoot>
          <Route component={Signup} exact path="/signup" />
          <Route component={Contacts} exact path="/contacts" />
          <Route component={Chatbot} exact path="/chatbot/:bot_id" />
          <Route component={FlowChart} exact path="/:bot_id/flowchart/:flow_id" />
          <Route component={Login} exact path="/login" />
          <Route component={Changepassword} exact path="/changepassword" />
          <Route component={Chatbots} exact path="/chatbots" />
          <Route component={Verifyemail} exact path="/verifyemail" />
          <Route component={Home} exact path="/" />
          <Route component={Livechat} exact path="/livechat" />
          <Route component={Dashboard} exact path="/home" />
          <Route component={Help} exact path="/help" />
          <Route component={Settings} exact path="/settings" />
        </RecoilRoot>
      </div>
      <Analytics />
    </Router>
  );
};

ReactDOM.render(<App />, document.getElementById("app"));
