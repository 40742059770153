import {Button, DatePicker, Input, Radio, Select, Space, TimePicker, InputNumber} from "antd";
import React, {useState, useEffect} from "react";
// import moment from "moment";

const PauseDrawer = ({onNodeChange, node, onNodeDelete, loading}) => {
  // const [durType, setDurType] = useState("Delay");
  const [value, setValue] = useState(0);
  const [unit, setUnit] = useState("minutes");

  useEffect(() => {
    if (node.data.unit) {
      setUnit(node.data.unit);
    }

    if (node.data.value) {
      setValue(node.data.value);
    }
  }, [node.id]);

  // const [flow, setFlow] = useState("In inactive");

  // const handleDurChange = (e) => {
  //   setDurType(e.target.value);
  // };

  // const handleFlowChange = (e) => {
  //   setFlow(e.target.value);
  // };


  useEffect(() => {
    const updatedNode = {
      ...node,
      data: {
        unit,
        value,
      },
    };
    onNodeChange(updatedNode);
}, [unit, value]);


  return (
    <div>
      <h3 style={{marginBottom: 20}}>Enter the delay duration</h3>
      {/* <Radio.Group
        value={durType}
        onChange={handleDurChange}
        style={{ marginBottom: "16px" }}
      >
        <Space direction="vertical">
          <Radio value={"Delay"}>Delay</Radio>
          <Radio value={"Until date"}>Until date</Radio>
          <Radio value={"Until time"}>Until time</Radio>
        </Space>
      </Radio.Group> */}
      {/* {durType === "Delay" && ( */}
      <div style={{display: "flex", columnGap: "8px"}}>
        <InputNumber
          style={{width: "25%"}}
          value={value}
          onChange={(e) => {
            if (e < 0) {
              return;
            }
            setValue(e);
          }}
        />
        <Select
          value={unit}
          onChange={(e) => setUnit(e)}
          style={{width: "35%"}}
          options={[
            {
              value: "seconds",
              label: "seconds",
            },
            {
              value: "minutes",
              label: "minutes",
            },
            {
              value: "hours",
              label: "hours",
            },
            {
              value: "days",
              label: "days",
            },
          ]}
        />
      </div>
      {/* )} */}
      {/* {durType === "Until date" && (
        <div>
          <DatePicker onChange={(e) => setDate(e.$d)} />
        </div>
      )}
      {durType === "Until time" && (
        <div>
          <TimePicker onChange={(e) => setTime(e.$d)} />
        </div>
      )} */}

      {/* <h3 style={{ marginTop: "3rem" }}>Continue flow</h3>
      <Radio.Group value={flow} onChange={handleFlowChange}>
        <Space direction="vertical">
          <Radio value={"In inactive"}>In inactive</Radio>
          <div>
            <Radio value={"Always"}>Always</Radio>
            <p style={{ marginLeft: "1.5rem", opacity: 0.7, fontSize: 12 }}>
              Will trigger even if the user navigates to another chain
            </p>
          </div>
        </Space>
      </Radio.Group> */}

     
    </div>
  );
};

export default PauseDrawer;
