const {format_time_ago, convert_utc_to_month_date} = require("../utils/helper");

const ContactItem = ({contact, Handle_Select, handle_view_contact}) => {
  return (
    <div className="contacts-contactitem2" onClick={() => handle_view_contact(contact)}>
      <div className="contacts-container15">
        <input checked={contact.selected} onChange={Handle_Select} type="checkbox" className="contacts-checkbox1" />
      </div>
      <div className="contacts-container16">
        <img alt="image" src="/user-thumbnail-200w-200w.png" className="contacts-image" />
      </div>
      <div className="contacts-container17">
        <span className="contacts-text09">{contact?.variables?.full_name}</span>
      </div>
      <div className="contacts-container18">
        <span className="contacts-text10">{contact.channel}</span>
      </div>
      <div className="contacts-container19">
        <span className="contacts-text11">{format_time_ago(contact.variables?.last_contact_message_time ?? "")}</span>
      </div>
      <div className="contacts-container20">
        <span className="contacts-text12">{convert_utc_to_month_date(contact?.created_on)}</span>
      </div>
      <div className="contacts-container21">
        <span className="contacts-text13">{contact.status}</span>
      </div>
    </div>
  );
};

export default ContactItem;

