import React, {useState, useEffect} from "react";
import {get_settings, update_settings} from "../../apis/index";
import {Spin, message as toast} from "antd";
import {LoadingOutlined} from "@ant-design/icons";

function Integration() {
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );



  const [setting, setSetting] = useState({});
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const onLoad = async () => {
      let result = await get_settings();
      if (result && result.data) {
        setSetting(result.data);
      }
    };

    onLoad();
  }, []);

  const HandleApiKeyChange = async (e) => {
    let api_key = e.target.value;
    const updatedSetting = {...setting};
    let chatgpt = {api_key: api_key};
    updatedSetting.integrations.chatgpt = chatgpt;
    setSetting(updatedSetting);
  };

  const HandleConnect = async (e) => {
    setLoading(true)
    let payload = {integrations: setting.integrations};
    await update_settings(JSON.stringify(payload));
    setLoading(false)
  };

  return (
    <div className="flex flex-col w-[100%]">
      <div className="flex md:flex-row flex-col justify-between items-start min-h-[10vh] bg-white border p-6 border-gray-300 rounded-md overflow-auto">
        <h2 className="text-[1em] md:w-[30%] w-[70%] font-semibold flex gap-2">
          Connect <img className="w-6 h-6" src="/assets/image 20.png" />
          ChatGPT Account
        </h2>
        <div className="flex flex-col md:w-[80%] items-start justify-center gap-2 my-4">
          <h2 className="font-semibold text-[1em] my-2 flex gap-2 items-center">
            <span className="w-8 h-8 py-1 text-center rounded-full border">1</span>
            Connect ChatGPT ACcount
          </h2>
          <h2 className="font-semibold text-[1em] my-2 flex gap-2 items-center">
            <span className="w-8 h-8 py-1 text-center rounded-full border">2</span>
            Enter Api Key
          </h2>
          <input placeholder="Enter ChatGPT API Key" value={setting.integrations?.chatgpt?.api_key} onChange={HandleApiKeyChange} type="text" className="outline-none border-gray-300 p-2 px-6 text-[1em] border rounded-md" />
          {loading && <Spin size="large" indicator={antIcon} />}
          <button className="p-4 my-2 rounded-md text-white bg-[#705CF6] text-[1.em] font-semibold" onClick={HandleConnect}>
            Connect ChatGPT Account
          </button>
        </div>
      </div>
    </div>
  );
}

export default Integration;
