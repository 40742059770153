import {Button, Input, Modal, Select, message as toast, Popconfirm, Popover} from "antd";
import CustomSelect from "../buttons/custom_select";
import Convert_Object_To_TreeData from "./response_mapping_component";
import {useState} from "react"
import {DownOutlined} from "@ant-design/icons";
import {Tree} from "antd";

const Response_mapping = ({mappings, setMappings, variables, bot_id, setVariables, test_response}) => {
  const [showFloats, setShowFloats] = useState(Array(mappings.length).fill(false));
  const treeData = Convert_Object_To_TreeData(test_response)

  const handleModalClose = (index) => {
    const updatedShowFloats = [...showFloats];
    updatedShowFloats[index] = false;
    setShowFloats(updatedShowFloats);
  };

  const handleModalShow = (index) => {
    const updatedShowFloats = [...showFloats];
    updatedShowFloats[index] = true;
    setShowFloats(updatedShowFloats);
  };




  function hasDuplicateKeys(objects) {
    const keySet = [];
    for (const object of objects) {
      if (keySet.includes(object.key)) {
        return true;
      }
      keySet.push(object.key);
    }
    console.log(keySet);

    return false; // No duplicate keys found
  }


  const add_new_mapping = () => {
    if (hasDuplicateKeys(mappings)) {
      return toast.error("Key already exists in mappings");
    }

    const allMappingsValid = mappings.every((mapping) => mapping.key.trim() !== "" && mapping.value.trim() !== "");
    if (!allMappingsValid) {
      return toast.error("All mappings must have a valid key and value");
    }

    const mapping = {key: "", value: ""};
    setMappings([...mappings, mapping]);
  };

  const delete_mapping = (index) => {
    const updatedMappings = [...mappings];
    updatedMappings.splice(index, 1);
    setMappings(updatedMappings);
  };

  const onMappingValueChange = (value, index) => {
    const updatedMappings = [...mappings];
    updatedMappings[index].value = value;
    setMappings(updatedMappings);
  };

  const onMappingKeyChange = (value, index) => {
    const updatedMappings = [...mappings];
    updatedMappings[index].key = value;
    setMappings(updatedMappings);
  };





  const handleSelectHeaderFocus = (index) => {
    handleModalShow(index)
  }

  const handleBlur = (index) => {
    handleModalClose(index)
  }

  const onSelectMapping = (selectedKeys, info, index) => {
    const updatedMappings = [...mappings];
    updatedMappings[index].key = selectedKeys[0];
    setMappings(updatedMappings);
  };

  const treeContent = (index) => (
    <Tree
      showLine
      switcherIcon={<DownOutlined />}
      onSelect={(selectedKeys, info) => onSelectMapping(selectedKeys, info, index)}
      treeData={treeData}
    />
  );

  return (
    <div className="api-saveresponse">
     
      <div className="api-mappinglabel">
        <div className="api-left">
          <span className="api-text20">Json path</span>
        </div>
        <div className="api-right">
          <span className="api-text21">Select Variable</span>
        </div>
      </div>



      {mappings.map((mapping, index) => (
        <div className="api-mapping">
          <div className="api-jsonpath">
            <Popover content={treeContent(index)} placement="topLeft" >
              <Input
                placeholder="$['some_variable']"
                type="text"
                value={mapping.key}
                onChange={(e) => {
                  onMappingKeyChange(e.target.value, index);
                }}
   
              />
            </Popover>
          </div>

          <div className="api-variablename">
            <CustomSelect
              size={530}
              is_open={true}
              bot_id={bot_id}
              setVariables={setVariables}
              variables={variables}
              value={mapping.value}
              onChange={(value) => {
                onMappingValueChange(value, index);
              }}
            />
          </div>
          <svg
            viewBox="0 0 1024 1024"
            className="api-icon6"
            onClick={() => {
              delete_mapping(index);
            }}
          >
            <path d="M128 320v640c0 35.2 28.8 64 64 64h576c35.2 0 64-28.8 64-64v-640h-704zM320 896h-64v-448h64v448zM448 896h-64v-448h64v448zM576 896h-64v-448h64v448zM704 896h-64v-448h64v448z"></path>
            <path d="M848 128h-208v-80c0-26.4-21.6-48-48-48h-224c-26.4 0-48 21.6-48 48v80h-208c-26.4 0-48 21.6-48 48v80h832v-80c0-26.4-21.6-48-48-48zM576 128h-192v-63.198h192v63.198z"></path>
          </svg>
        </div>
      ))}

      <button type="button" className="api-button2 button" onClick={add_new_mapping}>
        + New Mapping
      </button>
    </div>
  );
};

export default Response_mapping;
