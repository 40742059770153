import React, {useState, useEffect} from "react";
import {message as toast, Checkbox} from "antd";
import {create_node} from "../../../apis/index";
import "./plugin.css";
import { useRecoilState } from 'recoil';
import { flow_data_state } from '../../../atom';

const PluginInitiateDrawer = ({onNodeChange, node}) => {
  const [flow_data, setFlow_data] = useRecoilState(flow_data_state);
  const [name, setName] = useState(flow_data.name);
  const flow_id = flow_data.id;
  const [description, setDescription] = useState(flow_data.description);
  const [mappings, setMappings] = useState([]);
  const validation_pattern = /^[a-zA-Z0-9_-]{1,64}$/;


  useEffect(() => {
    if(node.data.params){
      setMappings(node.data.params)
    }
  }, [node.id]);

  
  useEffect(() => {
    const updatedNode = {
      ...node,
      data: {params: mappings},
    };
    onNodeChange(updatedNode);
}, [mappings]);



  function hasDuplicateKeys(objects) {
    const keySet = [];
    for (const object of objects) {
      if (keySet.includes(object.key)) {
        return true;
      }
      keySet.push(object.key);
    }
    console.log(keySet);

    return false; // No duplicate keys found
  }

  const add_new_mapping = () => {
    if (hasDuplicateKeys(mappings)) {
      return toast.error("param already exists");
    }

    const allMappingsValid = mappings.every((mapping) => mapping.key.trim() !== "");
    if (!allMappingsValid) {
      return toast.error("params must not be empty");
    }

    const mapping = {required: true, key: "", type: "string", description: ""};
    setMappings([...mappings, mapping]);
  };

  const delete_mapping = (index) => {
    const updatedMappings = [...mappings];
    updatedMappings.splice(index, 1);
    setMappings(updatedMappings);
  };

  const onMappingKeyChange = (value, index) => {
    const updatedMappings = [...mappings];
    updatedMappings[index].key = value;
    setMappings(updatedMappings);
  };

  const onMappingTypeChange = (value, index) => {
    const updatedMappings = [...mappings];
    updatedMappings[index].type = value;
    setMappings(updatedMappings);
  };

  const onMappingDescriptionChange = (value, index) => {
    const updatedMappings = [...mappings];
    updatedMappings[index].description = value;
    setMappings(updatedMappings);
  };

  const onMappingRequiredChange = (value, index) => {
    const updatedMappings = [...mappings];
    updatedMappings[index].required = value;
    setMappings(updatedMappings);
  };

  const handleInputBlur = () => {
    if (!validation_pattern.test(name)) {
      return toast.error("name must contain only letters, numbers, underscores, and hyphens and be between 1 and 64 characters.");
    }
    create_node(JSON.stringify({id: flow_id, name, description}));
  };

  return (
    <>
      <div className="plugin-plugindetail">
        <div className="plugin-param-label">
          <div className="plugin-pluginname">
            <span className="plugin-text01">Plugin Name</span>
            <span className="plugin-text02">(*required*)</span>
          </div>
          <div className="plugin-plugindescription">
            <span className="plugin-text03">Plugin Description</span>
            <span className="plugin-text04">(*required*)</span>
          </div>
        </div>
        <div className="plugin-param-body">
          <div className="plugin-name">
            <input
              type="text"
              value={name}
              onChange={(e) => {
                let value = e.target.value;
                setName(value);
              }}
              className="plugin-textinput input"
              onBlur={handleInputBlur}
            />
          </div>
          <div className="plugin-description">
            <input
              type="text"
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
              className="plugin-textinput1 input"
              onBlur={handleInputBlur}
            />
          </div>
        </div>
      </div>
      <div className="plugin-group">
        <div className="plugin-param-label1">
          <div className="plugin-requiredlabel">
            <span className="plugin-text05">Required</span>
          </div>
          <div className="plugin-namelabel">
            <span className="plugin-text06">Param </span>
          </div>
          <div className="plugin-typelabel">
            <span className="plugin-text07">Type</span>
          </div>
          <div className="plugin-descriptionlabel">
            <span className="plugin-text08">Description</span>
          </div>
        </div>

        {mappings.map((mapping, index) => (
          <div className="plugin-param-body1">
            <div className="plugin-required">
              <Checkbox checked={mapping.required} onChange={(e) => onMappingRequiredChange(e.target.checked, index)}></Checkbox>
            </div>
            <div className="plugin-name1">
              <input value={mapping.key} onChange={(e) => onMappingKeyChange(e.target.value, index)} type="text" className="plugin-textinput2 input" />
            </div>
            <div className="plugin-type">
              <input value={mapping.type} type="text" onChange={(e) => onMappingTypeChange(e.target.value, index)} className="plugin-textinput3 input" />
            </div>
            <div className="plugin-description1">
              <input value={mapping.description} onChange={(e) => onMappingDescriptionChange(e.target.value, index)} type="text" className="plugin-textinput4 input" />
            </div>
            <svg
              viewBox="0 0 1024 1024"
              className="plugin-icon"
              onClick={() => {
                delete_mapping(index);
              }}
            >
              <path d="M128 320v640c0 35.2 28.8 64 64 64h576c35.2 0 64-28.8 64-64v-640h-704zM320 896h-64v-448h64v448zM448 896h-64v-448h64v448zM576 896h-64v-448h64v448zM704 896h-64v-448h64v448z"></path>
              <path d="M848 128h-208v-80c0-26.4-21.6-48-48-48h-224c-26.4 0-48 21.6-48 48v80h-208c-26.4 0-48 21.6-48 48v80h832v-80c0-26.4-21.6-48-48-48zM576 128h-192v-63.198h192v63.198z"></path>
            </svg>
          </div>
        ))}

        <button type="button" onClick={add_new_mapping} className="plugin-button1 button">
          + New Param
        </button>
      </div>
    </>
  );
};

export default PluginInitiateDrawer;
