import { Link } from 'react-router-dom'
import ReactGA from 'react-ga';
import React, { useEffect } from 'react';

import { Helmet } from 'react-helmet'

import './home.css'

const Home = (props) => {

    useEffect(() => {
      ReactGA.initialize('G-2KP4M6G98E');
  
      ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
  

  return (
    <div className="home-container">
      <Helmet>
        <title>50chats No Code AI Chatbot Builder</title>
        <meta name="google-site-verification" content="qKorPp0a_t2vxYzuZKh6Gnj2tuVUt4v3oeQ1qYOkM88" />
        <meta
          name="description"
          content="50chats helps effortlessly create powerful AI Powered chatbots with its intuitive drag-and-drop interface."
        />
        <meta
          property="og:title"
          content="50chats  No Code AI + Tradiional Chatbot Builder"
        />
        <meta
          property="og:description"
          content="50chats helps to create powerful no code AI Powered chatbots with its simple to use drag-and-drop interface."
        />
      </Helmet>
      <header data-role="Header" className="home-header">
        <h1 className="home-logo">50chats</h1>
        <div className="home-navbar-container">
          <nav className="home-navbar">
            <a href="#pricing" className="home-link">
              Pricing
            </a>
            <a href="#features" className="home-link01">
              Features
            </a>
            <a href="#faqs" className="home-link02">
              FAQs
            </a>
            <Link to="/help" className="home-navlink">
              Help
            </Link>
          </nav>
        </div>
        <div className="home-btn-group">
          <a href="/login" className="home-sign-in button">
            Sign In
          </a>
          <a href="/signup" className="home-register button">
            Sign Up
          </a>
        </div>
        <div data-role="BurgerMenu" className="home-burger-menu">
          <svg viewBox="0 0 1024 1024" className="home-icon">
            <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
          </svg>
        </div>
        <div data-role="MobileMenu" className="home-mobile-menu">
          <div className="home-nav">
            <div className="home-container1">
              <h1 className="home-logo1">50chats</h1>
              <div data-role="CloseMobileMenu" className="home-menu-close">
                <svg viewBox="0 0 1024 1024" className="home-icon02">
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                </svg>
              </div>
            </div>
          </div>
          <nav className="home-navbar1">
            <a href="#pricing" className="home-link03">
              Pricing
            </a>
            <a href="#features" className="home-link04">
              Features
            </a>
            <a href="#faqs" className="home-link05">
              FAQs
            </a>
            <Link to="/help" className="home-navlink1">
              Help
            </Link>
          </nav>
          <div className="home-btn-group1">
            <a href="/login" className="home-sign-in1 button">
              Sign In
            </a>
            <a href="/signup" className="home-register1 button">
              Sign Up
            </a>
          </div>
        </div>
      </header>
      <p className="home-offer">
        Sign up today and explore our full-featured no-code chatbot builder for
        free - No credit card required
      </p>
      <h1 className="home-text">
        <span>Supercharge Sales and Customer Engagement with</span>
        <br></br>
        <span>our No-Code Chatbot Builder for Business Growth</span>
        <br></br>
      </h1>
      <span className="home-text005">
        <span>
          50chats helps effortlessly create powerful chatbots with its intuitive
          drag-and-drop interface.
        </span>
        <br></br>
        <span>
          {' '}
          Customize every detail and revolutionize customer engagement.
        </span>
        <br></br>
        <span> Start achieving extraordinary results today!</span>
      </span>
      <a href="/signup" className="home-link06 button">
        Get Started
      </a>
      <h1 className="home-text011">
        <span>
          Unlock Business Success With Our
          <span
            dangerouslySetInnerHTML={{
              __html: ' ',
            }}
          />
        </span>
        <span className="home-text013">
          Powerful
          <span
            dangerouslySetInnerHTML={{
              __html: ' ',
            }}
          />
        </span>
        <br className="home-text014"></br>
        <span className="home-text015">Features</span>
        <span> and Drive Your Success to the Next Level!</span>
      </h1>
      <img
        alt="pastedImage"
        src="/external/pastedimage-df4.svg"
        className="home-underlinesvg"
      />
      <div className="home-mobilefeatures">
        <div className="home-featureitem">
          <div className="home-featureitemcard">
            <img
              alt="image"
              src="/icons8-webhook-64-200h-200h.png"
              className="home-image"
            />
            <h1 className="home-heading">Webhook Integration</h1>
            <span className="home-text017">
              With our webhook integration feature, you can seamlessly connect
              your chatbots with external systems and receive real-time
              notifications.
            </span>
          </div>
        </div>
        <div className="home-featureitem01">
          <div className="home-featureitemcard01">
            <img
              alt="image"
              src="/icons8-omnichannel-64-200h-200h.png"
              className="home-image01"
            />
            <h1 className="home-heading01">Multi-Channel Deployment</h1>
            <span className="home-text018">
              Deploy your chatbots across multiple channels, including websites,
              messaging apps, social media platforms, and more, to reach your
              audience wherever they are.
            </span>
          </div>
        </div>
        <div className="home-featureitem02">
          <div className="home-featureitemcard02">
            <img
              alt="image"
              src="/icons8-media-64-200h-200h.png"
              className="home-image02"
            />
            <h1 className="home-heading02">Rich Media</h1>
            <span className="home-text019">
              Enhance engagement by incorporating rich media elements such as
              images, videos, and carousels into your chatbot conversations.
            </span>
          </div>
        </div>
        <div className="home-featureitem03">
          <div className="home-featureitemcard03">
            <img
              alt="image"
              src="/icons8-web-analytics-64-200h-200h.png"
              className="home-image03"
            />
            <h1 className="home-heading03">Analytics and Insights</h1>
            <span className="home-text020">
              Gain valuable insights into chatbot performance, user behavior,
              and conversation analytics through comprehensive reporting and
              analytics features.
            </span>
          </div>
        </div>
        <div className="home-featureitem04">
          <div className="home-featureitemcard04">
            <img
              alt="image"
              src="/icons8-drag-and-drop-64-200h-200h.png"
              className="home-image04"
            />
            <h1 className="home-heading04">Drag and Drop Interface</h1>
            <span className="home-text021">
              Easily build chatbots without any coding knowledge using an
              intuitive and user-friendly drag-and-drop interface.
            </span>
          </div>
        </div>
        <div className="home-featureitem05">
          <div className="home-container2">
            <img
              alt="image"
              src="/icons8-settings-64-200h-200h.png"
              className="home-image05"
            />
            <h1 className="home-heading05">Connect Your Favourite Apps</h1>
            <span className="home-text022">
              Seamlessly integrate your chatbots with CRM systems, e-commerce
              platforms, ticketing systems, and other third-party tools to
              streamline workflows and enhance functionality.
            </span>
          </div>
        </div>
      </div>
      <div id="features" className="home-desktopfeatures">
        <div className="home-topmiddlefeatureitem">
          <div className="home-dummy-featureitem"></div>
          <div className="home-featureitem06">
            <div className="home-container3">
              <img
                alt="image"
                src="/icons8-settings-64-200h-200h.png"
                className="home-image06"
              />
              <h1 className="home-heading06">Connect Your Favourite Apps</h1>
              <span className="home-text023">
                Seamlessly integrate your chatbots with CRM systems, e-commerce
                platforms, ticketing systems, and other third-party tools to
                streamline workflows and enhance functionality.
              </span>
            </div>
          </div>
          <div className="home-dummy-featureitem1"></div>
        </div>
        <div className="home-top-features">
          <div className="home-featureitem07">
            <div className="home-featureitemcard05">
              <img
                alt="image"
                src="/icons8-drag-and-drop-64-200h-200h.png"
                className="home-image07"
              />
              <h1 className="home-heading07">Drag and Drop Interface</h1>
              <span className="home-text024">
                Easily build chatbots without any coding knowledge using an
                intuitive and user-friendly drag-and-drop interface.
              </span>
            </div>
          </div>
          <div className="home-dummy-featureitem2"></div>
          <div className="home-featureitem08">
            <div className="home-featureitemcard06">
              <img
                alt="image"
                src="/icons8-web-analytics-64-200h-200h.png"
                className="home-image08"
              />
              <h1 className="home-heading08">Analytics and Insights</h1>
              <span className="home-text025">
                Gain valuable insights into chatbot performance, user behavior,
                and conversation analytics through comprehensive reporting and
                analytics features.
              </span>
            </div>
          </div>
        </div>
        <div className="home-outersquare">
          <div className="home-top-container"></div>
          <div className="home-innercircle">
            <div className="home-biggerrectangle">
              <div className="home-smallerrectanglecontainer">
                <div className="home-smallerrectangle"></div>
              </div>
            </div>
          </div>
          <div className="home-bottom-container"></div>
        </div>
        <div className="home-bottom-features">
          <div className="home-featureitem09">
            <div className="home-featureitemcard07">
              <img
                alt="image"
                src="/icons8-media-64-200h-200h.png"
                className="home-image09"
              />
              <h1 className="home-heading09">Rich Media</h1>
              <span className="home-text026">
                Enhance engagement by incorporating rich media elements such as
                images, videos, and carousels into your chatbot conversations.
              </span>
            </div>
          </div>
          <div className="home-dummy-featureitem3"></div>
          <div className="home-featureitem10">
            <div className="home-featureitemcard08">
              <img
                alt="image"
                src="/icons8-omnichannel-64-200h-200h.png"
                className="home-image10"
              />
              <h1 className="home-heading10">Multi-Channel Deployment</h1>
              <span className="home-text027">
                Deploy your chatbots across multiple channels, including
                websites, messaging apps, social media platforms, and more, to
                reach your audience wherever they are.
              </span>
            </div>
          </div>
        </div>
        <div className="home-bottommiddlefeatureitem">
          <div className="home-dummy-featureitem4"></div>
          <div className="home-featureitem11">
            <div className="home-featureitemcard09">
              <img
                alt="image"
                src="/icons8-webhook-64-200h-200h.png"
                className="home-image11"
              />
              <h1 className="home-heading11">Webhook Integration</h1>
              <span className="home-text028">
                With our webhook integration feature, you can seamlessly connect
                your chatbots with external systems and receive real-time
                notifications.
              </span>
            </div>
          </div>
          <div className="home-dummy-featureitem5"></div>
        </div>
      </div>
      <div id="pricing" className="home-pricing">
        <h1 className="home-text029">
          <span>Upgrade your business with our paid plans for</span>
          <br></br>
          <span>Enhanced Features and Performance</span>
          <br></br>
          <br></br>
        </h1>
        <img
          alt="pastedImage"
          src="/external/pastedimage-df4.svg"
          className="home-underlinesvg1"
        />
        <h1 className="home-text029">
          <span>Renews Every Month</span>
          <br></br>
          <br></br>
        </h1>
        <div className="home-plans">
          <div className="home-planitem">
            <h1 className="home-text035">Free</h1>
            <h1 className="home-text036">$0</h1>
            <a
              href="/signup"
              target="_blank"
              rel="noreferrer noopener"
              className="home-link07 button"
            >
              Get Started
            </a>
            <div className="home-features">
              <div className="home-featureitem12">
                <img
                  alt="pastedImage"
                  src="/external/pastedimage-97oi-200h.png"
                  className="home-pasted-image"
                />
                <span className="home-text037">1 Bot</span>
              </div>
              <div className="home-featureitem13">
                <img
                  alt="pastedImage"
                  src="/external/pastedimage-97oi-200h.png"
                  className="home-pasted-image01"
                />
                <span className="home-text038">200 Messages</span>
              </div>
              <div className="home-featureitem14">
                <img
                  alt="pastedImage"
                  src="/external/pastedimage-97oi-200h.png"
                  className="home-pasted-image02"
                />
                <span className="home-text039">1 Team Member</span>
              </div>
              <div className="home-featureitem15">
                <img
                  alt="pastedImage"
                  src="/external/pastedimage-97oi-200h.png"
                  className="home-pasted-image03"
                />
                <span className="home-text040">Unlimited Keywords</span>
              </div>
              <div className="home-featureitem16">
                <img
                  alt="pastedImage"
                  src="/external/pastedimage-97oi-200h.png"
                  className="home-pasted-image04"
                />
                <span className="home-text041">Unlimited Flows</span>
              </div>
              <div className="home-featureitem17">
                <img
                  alt="pastedImage"
                  src="/external/pastedimage-97oi-200h.png"
                  className="home-pasted-image05"
                />
                <span className="home-text042">Unlimited Sequence</span>
              </div>
              <div className="home-featureitem18">
                <img
                  alt="pastedImage"
                  src="/external/pastedimage-97oi-200h.png"
                  className="home-pasted-image06"
                />
                <span className="home-text043">Unlimited Integrations</span>
              </div>
              <div className="home-featureitem19">
                <img
                  alt="pastedImage"
                  src="/external/pastedimage-97oi-200h.png"
                  className="home-pasted-image07"
                />
                <span className="home-text044">Unlimited Plugins</span>
              </div>
            </div>
          </div>
          <div className="home-planitem1">
            <h1 className="home-text045">Standard</h1>
            <h1 className="home-text046">$15</h1>
            <a
              href="/signup"
              target="_blank"
              rel="noreferrer noopener"
              className="home-link08 button"
            >
              Get Started
            </a>
            <div className="home-features1">
              <div className="home-featureitem20">
                <img
                  alt="pastedImage"
                  src="/external/pastedimage-97oi-200h.png"
                  className="home-pasted-image08"
                />
                <span className="home-text047">1 Bot</span>
              </div>
              <div className="home-featureitem21">
                <img
                  alt="pastedImage"
                  src="/external/pastedimage-97oi-200h.png"
                  className="home-pasted-image09"
                />
                <span className="home-text048">1000 Messages</span>
              </div>
              <div className="home-featureitem22">
                <img
                  alt="pastedImage"
                  src="/external/pastedimage-97oi-200h.png"
                  className="home-pasted-image10"
                />
                <span className="home-text049">3 Team Member</span>
              </div>
              <div className="home-featureitem23">
                <img
                  alt="pastedImage"
                  src="/external/pastedimage-97oi-200h.png"
                  className="home-pasted-image11"
                />
                <span className="home-text050">Unlimited Keywords</span>
              </div>
              <div className="home-featureitem24">
                <img
                  alt="pastedImage"
                  src="/external/pastedimage-97oi-200h.png"
                  className="home-pasted-image12"
                />
                <span className="home-text051">Unlimited Flows</span>
              </div>
              <div className="home-featureitem25">
                <img
                  alt="pastedImage"
                  src="/external/pastedimage-97oi-200h.png"
                  className="home-pasted-image13"
                />
                <span className="home-text052">Unlimited Sequence</span>
              </div>
              <div className="home-featureitem26">
                <img
                  alt="pastedImage"
                  src="/external/pastedimage-97oi-200h.png"
                  className="home-pasted-image14"
                />
                <span className="home-text053">Unlimited Integrations</span>
              </div>
              <div className="home-featureitem27">
                <img
                  alt="pastedImage"
                  src="/external/pastedimage-97oi-200h.png"
                  className="home-pasted-image15"
                />
                <span className="home-text054">Unlimited Plugins</span>
              </div>
            </div>
          </div>
          <div className="home-planitem2">
            <h1 className="home-text055">Business</h1>
            <h1 className="home-text056">$30</h1>
            <a
              href="/signup"
              target="_blank"
              rel="noreferrer noopener"
              className="home-link09 button"
            >
              Get Started
            </a>
            <div className="home-features2">
              <div className="home-featureitem28">
                <img
                  alt="pastedImage"
                  src="/external/pastedimage-97oi-200h.png"
                  className="home-pasted-image16"
                />
                <span className="home-text057">1 Bot</span>
              </div>
              <div className="home-featureitem29">
                <img
                  alt="pastedImage"
                  src="/external/pastedimage-97oi-200h.png"
                  className="home-pasted-image17"
                />
                <span className="home-text058">2000 Messages</span>
              </div>
              <div className="home-featureitem30">
                <img
                  alt="pastedImage"
                  src="/external/pastedimage-97oi-200h.png"
                  className="home-pasted-image18"
                />
                <span className="home-text059">5 Team Member</span>
              </div>
              <div className="home-featureitem31">
                <img
                  alt="pastedImage"
                  src="/external/pastedimage-97oi-200h.png"
                  className="home-pasted-image19"
                />
                <span className="home-text060">Unlimited Keywords</span>
              </div>
              <div className="home-featureitem32">
                <img
                  alt="pastedImage"
                  src="/external/pastedimage-97oi-200h.png"
                  className="home-pasted-image20"
                />
                <span className="home-text061">Unlimited Flows</span>
              </div>
              <div className="home-featureitem33">
                <img
                  alt="pastedImage"
                  src="/external/pastedimage-97oi-200h.png"
                  className="home-pasted-image21"
                />
                <span className="home-text062">Unlimited Sequence</span>
              </div>
              <div className="home-featureitem34">
                <img
                  alt="pastedImage"
                  src="/external/pastedimage-97oi-200h.png"
                  className="home-pasted-image22"
                />
                <span className="home-text063">Unlimited Integrations</span>
              </div>
              <div className="home-featureitem35">
                <img
                  alt="pastedImage"
                  src="/external/pastedimage-97oi-200h.png"
                  className="home-pasted-image23"
                />
                <span className="home-text064">Unlimited Plugins</span>
              </div>
            </div>
          </div>
          <div className="home-planitem3">
            <h1 className="home-text065">Enterprise</h1>
            <h1 className="home-text066">$150</h1>
            <a
              href="/signup"
              target="_blank"
              rel="noreferrer noopener"
              className="home-link10 button"
            >
              Get Started
            </a>
            <div className="home-features3">
              <div className="home-featureitem36">
                <span className="home-text067">1 Bot</span>
              </div>
              <div className="home-featureitem37">
                <span className="home-text068">Unlimited Messages</span>
              </div>
              <div className="home-featureitem38">
                <span className="home-text069">5 Team Member</span>
              </div>
              <div className="home-featureitem39">
                <span className="home-text070">Unlimited Keywords</span>
              </div>
              <div className="home-featureitem40">
                <span className="home-text071">Unlimited Flows</span>
              </div>
              <div className="home-featureitem41">
                <span className="home-text072">Unlimited Sequence</span>
              </div>
              <div className="home-featureitem42">
                <span className="home-text073">Unlimited Integrations</span>
              </div>
              <div className="home-featureitem43">
                <span className="home-text074">Unlimited Plugins</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="home-addons">
        <h1 className="home-text075">Add Ons</h1>
        <div className="home-container4"></div>
        <div className="home-plans1">
          <div className="home-planitem4">
            <div className="home-features4">
              <div className="home-featureitem44">
                <span className="home-text076">
                  <span className="home-text077">
                    $5
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <span className="home-text078">- For an Extra Bot</span>
                </span>
              </div>
              <div className="home-featureitem45">
                <span className="home-text079">
                  <span className="home-text080">
                    $5
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <span className="home-text081">
                    - For an Extra 1000 Messages
                  </span>
                </span>
              </div>
              <div className="home-featureitem46">
                <span className="home-text082">
                  <span className="home-text083">
                    $5
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <span className="home-text084">
                    - For an Extra Team Member
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="faqs" className="home-faqs-container">
        <div className="home-faqs">
          <h1 className="home-text085">Frequently Asked Questions</h1>
          <img
            alt="pastedImage"
            src="/external/pastedimage-df4.svg"
            className="home-underlinesvg2"
          />
          <div data-thq="thq-dropdown" className="home-thq-dropdown list-item">
            <div
              data-thq="thq-dropdown-toggle"
              className="home-dropdown-toggle"
            >
              <span className="home-text086">
                <span>
                  Can I integrate 50chats with my existing apps and systems?
                </span>
                <br></br>
              </span>
              <div
                data-thq="thq-dropdown-arrow"
                className="home-dropdown-arrow"
              >
                <svg viewBox="0 0 1024 1024" className="home-icon04">
                  <path d="M426 726v-428l214 214z"></path>
                </svg>
              </div>
            </div>
            <ul data-thq="thq-dropdown-list" className="home-dropdown-list">
              <li data-thq="thq-dropdown" className="home-dropdown list-item">
                <div
                  data-thq="thq-dropdown-toggle"
                  className="home-dropdown-toggle01"
                >
                  <span className="home-text089">Sub-menu Item</span>
                </div>
              </li>
              <li data-thq="thq-dropdown" className="home-dropdown1 list-item">
                <div
                  data-thq="thq-dropdown-toggle"
                  className="home-dropdown-toggle02"
                >
                  <span className="home-text090">Sub-menu Item</span>
                </div>
              </li>
              <li data-thq="thq-dropdown" className="home-dropdown2 list-item">
                <div
                  data-thq="thq-dropdown-toggle"
                  className="home-dropdown-toggle03"
                >
                  <span className="home-text091">Sub-menu Item</span>
                </div>
              </li>
            </ul>
          </div>
          <div data-thq="thq-dropdown" className="home-thq-dropdown1 list-item">
            <div
              data-thq="thq-dropdown-toggle"
              className="home-dropdown-toggle04"
            >
              <span className="home-text092">
                <span>
                  Can I integrate 50chats with my existing apps and systems?
                </span>
                <br></br>
              </span>
              <div
                data-thq="thq-dropdown-arrow"
                className="home-dropdown-arrow1"
              >
                <svg viewBox="0 0 1024 1024" className="home-icon06">
                  <path d="M426 726v-428l214 214z"></path>
                </svg>
              </div>
            </div>
            <ul data-thq="thq-dropdown-list" className="home-dropdown-list1">
              <li data-thq="thq-dropdown" className="home-dropdown3 list-item">
                <div
                  data-thq="thq-dropdown-toggle"
                  className="home-dropdown-toggle05"
                >
                  <span className="home-text095">Sub-menu Item</span>
                </div>
              </li>
              <li data-thq="thq-dropdown" className="home-dropdown4 list-item">
                <div
                  data-thq="thq-dropdown-toggle"
                  className="home-dropdown-toggle06"
                >
                  <span className="home-text096">Sub-menu Item</span>
                </div>
              </li>
              <li data-thq="thq-dropdown" className="home-dropdown5 list-item">
                <div
                  data-thq="thq-dropdown-toggle"
                  className="home-dropdown-toggle07"
                >
                  <span className="home-text097">Sub-menu Item</span>
                </div>
              </li>
            </ul>
          </div>
          <div data-thq="thq-dropdown" className="home-thq-dropdown2 list-item">
            <div
              data-thq="thq-dropdown-toggle"
              className="home-dropdown-toggle08"
            >
              <span className="home-text098">
                <span>
                  Can I integrate 50chats with my existing apps and systems?
                </span>
                <br></br>
              </span>
              <div
                data-thq="thq-dropdown-arrow"
                className="home-dropdown-arrow2"
              >
                <svg viewBox="0 0 1024 1024" className="home-icon08">
                  <path d="M426 726v-428l214 214z"></path>
                </svg>
              </div>
            </div>
            <ul data-thq="thq-dropdown-list" className="home-dropdown-list2">
              <li data-thq="thq-dropdown" className="home-dropdown6 list-item">
                <div
                  data-thq="thq-dropdown-toggle"
                  className="home-dropdown-toggle09"
                >
                  <span className="home-text101">Sub-menu Item</span>
                </div>
              </li>
              <li data-thq="thq-dropdown" className="home-dropdown7 list-item">
                <div
                  data-thq="thq-dropdown-toggle"
                  className="home-dropdown-toggle10"
                >
                  <span className="home-text102">Sub-menu Item</span>
                </div>
              </li>
              <li data-thq="thq-dropdown" className="home-dropdown8 list-item">
                <div
                  data-thq="thq-dropdown-toggle"
                  className="home-dropdown-toggle11"
                >
                  <span className="home-text103">Sub-menu Item</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="home-banner">
        <h1 className="home-text104">
          <span className="home-text105">
            Create your powerful chatbot for your business today
          </span>
          <br className="home-text106"></br>
          <br className="home-text107"></br>
        </h1>
        <span className="home-text108">
          <span>Effortlessly build your chatbot, no code required.</span>
          <br></br>
        </span>
        <a
          href="/signup"
          target="_blank"
          rel="noreferrer noopener"
          className="home-link11 button"
        >
          Get Started
        </a>
      </div>
      <div className="home-footer">
        <h1 className="home-logo2">50chats</h1>
        <h1 className="home-text111">
          <span>Upgrade your business with our paid plans for</span>
          <br></br>
          <span>Enhanced Features and Performance</span>
          <br></br>
          <br></br>
        </h1>
        <span className="home-text117">
          <span>
            Email us at
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <span className="home-text119">contact@50chats.com</span>
        </span>
        <span className="home-text120">
          Copyright © 2023 50chats. All Rights Reserved.
        </span>
        <span className="home-text121">Steksolutions Services</span>
      </div>
    </div>
  )
}

export default Home
