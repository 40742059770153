import React, {useEffect, useState} from "react";
import ContactItem from "../components/contact";
import Loading from "../components/loading";
import {Helmet} from "react-helmet";
import {get_contacts} from "../apis/index";
import Sidebar from "../components/sidebar";
import Contact_detail from "../components/contact_detail"
import "./contacts.css";
import { Modal } from "antd";

const Contacts = (props) => {
  const [contacts, setContacts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [view_contact, setView_contact] = useState(false)
  const [contact, setContact] = useState(null)
  const [selectAll, setSelectAll] = useState(false);


  const handle_view_contact = (contact) => {
    setContact(contact)
    setView_contact(!view_contact)
  }


  useEffect(() => {
    const onLoad = async () => {
      setLoading(true);
      let result = await get_contacts();
      if (result) {
        setContacts(result.data);
      }
      setLoading(false);
    };
    onLoad();
  }, []);

  const filteredContacts = contacts.filter(
    (contact) =>
      contact.variables &&
      contact.variables.full_name &&
      contact.variables.full_name.includes(searchQuery)
  );
  


  const Handle_Select_All = () => {
    const updatedSelectAll = !selectAll;
    setSelectAll(updatedSelectAll);

    let updated_contacts = contacts.map((contact) => ({
      ...contact,
      selected: updatedSelectAll,
    }));
    setContacts([...updated_contacts]);
  }



  const Handle_Select = (id) => {
    let updated_contacts = contacts.map((contact) => (contact._id === id ? {...contact, selected: !contact.selected} : contact));
    setContacts([...updated_contacts]);
  }

  
  function getSelectedFlowsCount() {
    let all_contacts = [...contacts]

    const selectedContacts = all_contacts.filter((contact) => contact.selected);
    return selectedContacts.length;
  }
  



  return (
    <div className="contacts-container">
      <Helmet>
        <title>contacts - 50Chats No-Code Chatbot Builder</title>
        <meta name="description" content="Empower your business with 50Chats, the No-Code Chatbot Builder. Create powerful chatbots effortlessly" />
        <meta property="og:title" content="contacts - 50Chats No-Code Chatbot Builder" />
        <meta property="og:description" content="Empower your business with 50Chats, the No-Code Chatbot Builder. Create powerful chatbots effortlessly" />
      </Helmet>
      <div className="contacts-container01">
          <Sidebar></Sidebar>
        <div className="contacts-contacts">
          <div className="contacts-header">
            <div className="contacts-container03">
              <div className="contacts-container04">
                <h3 className="contacts-text">Contacts</h3>
              </div>
              <div className="contacts-container05">
                <button className="contacts-button button">Create New Contact</button>
              </div>
            </div>
          </div>
          <div className="contacts-body">
            <div className="contacts-contactnavbar">
              <div className="contacts-container06">
                <input type="text" placeholder="Search by name" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} className="contacts-textinput input" />
              </div>
              <div className="contacts-container07">
                <span className="contacts-text01">{getSelectedFlowsCount()} selected</span>
                <button className="contacts-button1 button">Bulk Actions</button>
              </div>
            </div>
            <div className="contacts-contactitemcontainer">
              <div className="contacts-contactitem">
                <div className="contacts-container08">
                  <input checked={selectAll} onChange={Handle_Select_All} type="checkbox" className="contacts-checkbox" />
                </div>
                <div className="contacts-container09">
                  <span className="contacts-text02">Avatar</span>
                </div>
                <div className="contacts-container10">
                  <span className="contacts-text03">Name</span>
                </div>
                <div className="contacts-container11">
                  <span className="contacts-text04">Channel</span>
                </div>
                <div className="contacts-container12">
                  <span className="contacts-text05">Last Messaged</span>
                </div>
                <div className="contacts-container13">
                  <span className="contacts-text06">Created On</span>
                </div>
                <div className="contacts-container14">
                  <span className="contacts-text07">Status</span>
                </div>
              </div>
              <div className="contacts-contactitem1">
                <span className="contacts-text08">No Data</span>
              </div>
              {loading ? (
                <Loading />
              ) : (
                <>
                  {filteredContacts.map((contact) => (
                    <ContactItem Handle_Select={Handle_Select} key={contact._id} contact={contact} handle_view_contact = {handle_view_contact}/>
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="contacts-container22">
        <div className="contacts-newbotwidget">
          <div className="contacts-container23">
            <div className="contacts-container24">
              <h3 className="contacts-text14">New Contact</h3>
            </div>
            <div className="contacts-container25">
              <svg viewBox="0 0 1024 1024" className="contacts-icon">
                <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
              </svg>
            </div>
          </div>
          <div className="contacts-container26">
            <input type="text" placeholder="Enter the bot name" className="contacts-textinput1 input" />
            <div data-thq="thq-dropdown" className="contacts-thq-dropdown list-item">
              <ul data-thq="thq-dropdown-list" className="contacts-dropdown-list">
                <li data-thq="thq-dropdown" className="contacts-dropdown list-item">
                  <div data-thq="thq-dropdown-toggle" className="contacts-dropdown-toggle">
                    <span className="contacts-text15">Sub-menu Item</span>
                  </div>
                </li>
                <li data-thq="thq-dropdown" className="contacts-dropdown1 list-item">
                  <div data-thq="thq-dropdown-toggle" className="contacts-dropdown-toggle01">
                    <span className="contacts-text16">Sub-menu Item</span>
                  </div>
                </li>
                <li data-thq="thq-dropdown" className="contacts-dropdown2 list-item">
                  <div data-thq="thq-dropdown-toggle" className="contacts-dropdown-toggle02">
                    <span className="contacts-text17">Sub-menu Item</span>
                  </div>
                </li>
                <li data-thq="thq-dropdown" className="contacts-dropdown3 list-item">
                  <div data-thq="thq-dropdown-toggle" className="contacts-dropdown-toggle03">
                    <span className="contacts-text18">Sub-menu Item</span>
                  </div>
                </li>
                <li data-thq="thq-dropdown" className="contacts-dropdown4 list-item">
                  <div data-thq="thq-dropdown-toggle" className="contacts-dropdown-toggle04">
                    <span className="contacts-text19">Sub-menu Item</span>
                  </div>
                </li>
                <li data-thq="thq-dropdown" className="contacts-dropdown5 list-item">
                  <div data-thq="thq-dropdown-toggle" className="contacts-dropdown-toggle05">
                    <span className="contacts-text20">Sub-menu Item</span>
                  </div>
                </li>
              </ul>
            </div>
            <div className="contacts-container27">
              <div data-thq="thq-dropdown" className="contacts-thq-dropdown1 list-item">
                <div data-thq="thq-dropdown-toggle" className="contacts-dropdown-toggle06">
                  <span className="contacts-text21">Select Channel</span>
                  <div data-thq="thq-dropdown-arrow" className="contacts-dropdown-arrow">
                    <svg viewBox="0 0 1024 1024" className="contacts-icon2">
                      <path d="M426 726v-428l214 214z"></path>
                    </svg>
                  </div>
                </div>
                <ul data-thq="thq-dropdown-list" className="contacts-dropdown-list1">
                  <li data-thq="thq-dropdown" className="contacts-dropdown6 list-item">
                    <div data-thq="thq-dropdown-toggle" className="contacts-dropdown-toggle07">
                      <span className="contacts-text22">Telegram</span>
                    </div>
                  </li>
                  <li data-thq="thq-dropdown" className="contacts-dropdown7 list-item">
                    <div data-thq="thq-dropdown-toggle" className="contacts-dropdown-toggle08">
                      <span className="contacts-text23">Dialogflow</span>
                    </div>
                  </li>
                  <li data-thq="thq-dropdown" className="contacts-dropdown8 list-item">
                    <div data-thq="thq-dropdown-toggle" className="contacts-dropdown-toggle09">
                      <span className="contacts-text24">Whatsapp</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="contacts-container28">
              <button className="contacts-button2 button">Cancel</button>
              <button className="contacts-button3 button">Create</button>
            </div>
          </div>
        </div>
      </div>
     

     {view_contact && 
      <Contact_detail contact={contact} handle_view_contact={handle_view_contact} />
     }
    </div>
  );
};

export default Contacts;
