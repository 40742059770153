

const Cards = ({card}) => {

  
    return (
        <>
          <div className="settings-sub-body1">
            <div className="settings-head">
              <h1 className="settings-heading3">Payment method</h1>
              <h1 className="settings-heading4">Change how you pay for your plan</h1>
            </div>
            <div className="settings-card">
              <div className="settings-card-detail">
                {/* <div className="settings-card-logo">
                  <img alt="pastedImage" src="/external/pastedimage-qui-200h.png" className="settings-pasted-image2" />
                </div> */}
                {card && (
                <div className="settings-card-digits">
                <span className="settings-text20">{card.type} ending in {card.last_4digits}</span>
                <span className="settings-text21">Expiry {card.expiry}</span>
              </div>
                )}

              </div>
              {/* <button type="button" className="settings-edit-btn button">
                Edit
              </button> */}
            </div>
          </div>
        </>
    )
}


export default Cards