import { useRef } from "react";
import { ActionTypes } from "../constants/actionTypes";

const mapState = {
  nodeList: [
    {
      id: 0,
      x: 350,
      y: 50,
      width: 250,
      height: 150,
      startDot: { x: 0, y: 0 },
      endDot: { x: 0, y: 0 },
      lines: [],
      prevNodeId: [-1],
      activeNode: false,
      type: "Message",
      message: "Usually, we reply within one day!",
      buttons: [],
    },
  ],
  lineList: [],
  startDot: { x: 0, y: 0 },
  endDot: { x: 0, y: 0 },
  newLine: false,
  fromNode: { id: -1 },
  toNode: { id: -2 },
  isDragging: false,
  fromDot: "right",
  toDot: "left",
};

const HEIGHT = 150;
const WIDTH = 250;

const mapReducer = (state = mapState, action) => {
  if (action.type === ActionTypes.DOT_DRAG_START) {
    const { newPosition, event, node } = action.payload;
    const { x } = newPosition;
    const nodeX = node.x;
    let fromDotType;
    if (nodeX === x) {
      fromDotType = "left";
    } else {
      fromDotType = "right";
    }
    return {
      ...state,
      startDot: newPosition,
      isDragging: true,
      fromNode: node,
      newLine: false,
      fromDot: fromDotType,
    };
  } else if (action.type === ActionTypes.DOT_DRAG_MOVE) {
    const { snapPosition, event, node } = action.payload;
    const { nodeList, toNode } = state;

    let _toNode = toNode;
    let isOver = false;
    nodeList.forEach((item) => {
      const xPos = item.x + item.width;
      const yPos = item.y + item.height;
      const isMouseOver =
      snapPosition.x < xPos &&
      snapPosition.y < yPos &&
      snapPosition.x > item.x &&
      snapPosition.y > item.y;
      if (isMouseOver) {
        isOver = true;
        _toNode = item;
      } else {
        if (!isOver) {
          _toNode = { id: -2 };
        }
      }
    });
    return {
      ...state,
      endDot: snapPosition,
      toNode: _toNode,
    };
  } else if (action.type === ActionTypes.DOT_DRAG_END) {
    const {
      event,
      node,
      leftDot,
      rightDot,
      doneDot,
      errorDot,
      dragFrom,
      option,
    } = action.payload;
    const { nodeList, toNode } = state;
    const fromNode = node;
    const otherNodes = nodeList.filter((n) => n.id !== node.id);

    let startX, startY, endX, endY, id;
    if (leftDot.current) {
      leftDot?.current.x(node.x);
      leftDot?.current.y(node.y + 32);
      if (node.type === "Message") {
        id = `${fromNode.id}-${toNode.id}`;
        startX = fromNode.x + fromNode.width;
        startY = fromNode.y + fromNode.height - 32;
      } else if (node.type === "Random") {
        const { index, value } = option;
        id = `${fromNode.id}-${toNode.id}-${index}`;
        event.target.x(node.x + node.width - 20);
        event.target.y(node.y + 102 + index * 32);
        startX = fromNode.x + fromNode.width - 20;
        startY = fromNode.y + 102 + index * 32;
      } else if (node.type === "Filter") {
        id = `${fromNode.id}-${toNode.id}-${option.id}`;
        event.target.x(node.x + node.width - 20);
        event.target.y(node.y + 82 + option.id * 63);
        startX = fromNode.x + fromNode.width - 20;
        startY = fromNode.y + 82 + option.id * 63;
      } else {
        id = `${fromNode.id}-${toNode.id}`;
        startX = fromNode.x + fromNode.width;
        startY = fromNode.y + 32;
      }
      endX = toNode.x;
      endY = toNode.y + 32;
    }
    if (rightDot.current) {
      id = `${fromNode.id}-${toNode.id}`;
      rightDot?.current.x(node.x + node.width);
      if (node.type === "Message") {
        startX = fromNode.x + fromNode.width;
        startY = fromNode.y + fromNode.height - 32;
        rightDot?.current.y(node.y + node.height - 32);
      } else {
        startX = fromNode.x + fromNode.width;
        startY = fromNode.y + 32;
        rightDot?.current.y(node.y + 32);
      }
      endX = toNode.x;
      endY = toNode.y + 32;
    }
    if (doneDot.current || errorDot.current) {
      id = `${fromNode.id}-${toNode.id}`;
      doneDot?.current.x(node.x + (node.width - 20));
      doneDot?.current.y(node.y + (node.height - 50));
      errorDot?.current.x(node.x + (node.width - 20));
      errorDot?.current.y(node.y + (node.height - 22));
      if (dragFrom === "done") {
        startX = fromNode.x + (fromNode.width - 20);
        startY = fromNode.y + (fromNode.height - 48);
      }
      if (dragFrom === "error") {
        startX = fromNode.x + (fromNode.width - 20);
        startY = fromNode.y + (fromNode.height - 21);
      }
      endX = toNode.x;
      endY = toNode.y + 32;
    }
    const curvature = 50;
    const midX = (startX + endX) / 2 + curvature;
    const midY = (startY + endY) / 2 - curvature;
    const offsetX = Math.abs(startX - endX) / 2;
    const offsetY = Math.abs(startY - endY) / 2;
    const controlX = midX - offsetX;
    const controlY = midY + offsetY;
    const lineData = {
      id: id,
      index: dragFrom === "done" ? 0 : 1,
      startX,
      startY,
      controlX,
      startY,
      controlX,
      controlY,
      endY,
      endX,
      endY,
    };

    const otherToNodes = otherNodes.map((n) =>
      n.id === toNode.id ? { ...n, prevNodeId: [node.id, ...n.prevNodeId] } : n
    );

    let updatedNode;
    if (dragFrom === "right") {
      updatedNode = { ...node, lines: [lineData] };
    } else {
      updatedNode = { ...node, lines: [...node.lines, lineData] };
    }
    const updatedNodeList = [...otherToNodes, { ...updatedNode }];

    return {
      ...state,
      // lineList: [lineData, ...state.lineList],
      nodeList: updatedNodeList,
      isDragging: false,
      fromNode: node,
      toNode: toNode,
    };
  } else if (action.type === ActionTypes.NODE_DRAG_MOVE) {
    const { nodeList } = state;
    const { snapPosition, event, node } = action.payload;
    const filterNodes = nodeList.map((n) => {
      return n.id === node.id ? { ...n, ...snapPosition, activeNode: true } : n;
    });

    const currentFilterNode = filterNodes.filter((n) => n.id === node.id);
    const currentNode = currentFilterNode[0];

    const parentNodes = filterNodes.filter((n) =>
      currentNode.prevNodeId.includes(n.id)
    );
    let updatedLines = [];

    if (currentNode.lines.length > 0) {
      // current nodes has child nodes
      currentNode.lines.map((line) => {
        let startX, startY, endX, endY;

        if (node.type === "Message") {
          startX = currentNode.x + currentNode.width;
          startY = currentNode.y + currentNode.height - 32;
        } else if (node.type === "API") {
          startX = currentNode.x + currentNode.width - 20;
          if (line?.index === 0) {
            startY = currentNode.y + currentNode.height - 50;
          } else {
            startY = currentNode.y + currentNode.height - 22;
          }
        } else if (node.type === "Random") {
          const { id } = line;
          const oIndex = parseInt(id.split("-")[2]);

          startX = currentNode.x + currentNode.width - 20;
          startY = currentNode.y + 102 + oIndex * 32;
        } else if (node.type === "Filter") {
          const { id } = line;
          const oIndex = parseInt(id.split("-")[2]);

          startX = currentNode.x + currentNode.width - 20;
          startY = currentNode.y + 82 + oIndex * 63;
        } else {
          startX = currentNode.x + currentNode.width;
          startY = currentNode.y + 32;
        }
        const curvature = 50;
        endX = line.endX;
        endY = line.endY;
        const midX = (startX + endX) / 2 + curvature;
        const midY = (startY + endY) / 2 - curvature;
        const offsetX = Math.abs(startX - endX) / 2;
        const offsetY = Math.abs(startY - endY) / 2;
        const controlX = midX - offsetX;
        const controlY = midY + offsetY;

        const lineData = {
          ...line,
          startX,
          startY,
          controlX,
          startY,
          controlX,
          controlY,
          endY,
          endX,
          endY,
        };
        updatedLines.push(lineData);
      });
    }

    let updatedNodeList = [];
    let otherNodes = filterNodes.filter((n) => n.id !== node.id);

    const updatedNode = { ...currentNode, lines: updatedLines };
    updatedNodeList = [...otherNodes, { ...updatedNode }];

    if (parentNodes.length > 0 && currentNode.prevNodeId[0] !== -1) {
      // child node has a parent node
      parentNodes.map((p) => {
        const list = [];
        p.lines.map((line) => {
          let endX, endY;
          if (line.id === `${p.id}-${currentNode.id}`) {
            endX = currentNode.x;
            endY = currentNode.y + 32;
          } else if (p.type === "Random") {
            endX = line.endX;
            endY = line.endY;
            const { options } = p;
            options.map((o) => {
              if (line.id === `${p.id}-${currentNode.id}-${o.index}`) {
                endX = currentNode.x;
                endY = currentNode.y + 32;
              }
            });
          } else if (p.type === "Filter") {
            endX = line.endX;
            endY = line.endY;
            const { conditions } = p;
            conditions.map((o) => {
              if (line.id === `${p.id}-${currentNode.id}-${o.id}`) {
                endX = currentNode.x;
                endY = currentNode.y + 32;
              }
            });
          } else {
            endX = line.endX;
            endY = line.endY;
          }

          const curvature = 50;
          const startX = line.startX;
          const startY = line.startY;
          const midX = (startX + endX) / 2 + curvature;
          const midY = (startY + endY) / 2 - curvature;
          const offsetX = Math.abs(startX - endX) / 2;
          const offsetY = Math.abs(startY - endY) / 2;
          const controlX = midX - offsetX;
          const controlY = midY + offsetY;

          const lineData = {
            ...line,
            startX,
            startY,
            controlX,
            startY,
            controlX,
            controlY,
            endY,
            endX,
            endY,
          };
          list.push(lineData);
        });
        p.lines = list;
      });
      const otherPNodes = updatedNodeList.filter(
        (n) => parentNodes.indexOf(n.id) === -1
      );
      // const updatedPNode = { ...p, lines: parentLineList };
      updatedNodeList = [...otherPNodes];
    }
    return {
      ...state,
      nodeList: updatedNodeList,
      isDragging: true
    };
  } else if (action.type === ActionTypes.NODE_DRAG_END) {
    const { newPosition, event, node } = action.payload;
    const filterNodes = state.nodeList.map((n) =>
      n.id === node.id ? { ...n, ...newPosition, activeNode: false } : n
    );
    return {
      ...state,
      isDragging: false
    };
  } else if (action.type === ActionTypes.ON_TEMP_LINE_DRAW) {
    const { newPosition, event } = action.payload;
    return {
      ...state,
      endDot: newPosition,
    };
  } else if (action.type === ActionTypes.ON_NODE_ADD) {
    const { nodeList } = state;
    const nodeType = action.payload;
    let nodeHeight = 150;
    if (
      nodeType === "Pause" ||
      nodeType === "Action" ||
      nodeType === "Flow" ||
      nodeType === "API"
    ) {
      nodeHeight = 100;
    }

    let node;
    let message = "Your message!";
    const xPos = Math.random() * 500;
    const yPos = Math.random() * 500;
    node = {
      id: nodeList.length,
      x: xPos,
      y: yPos,
      width: 250,
      height: nodeHeight,
      startDot: { x: 0, y: 0 },
      endDot: { x: 0, y: 0 },
      prevNodeId: [-1],
      lines: [],
      activeNode: false,
      type: nodeType,
      message: message,
      buttons: [],
    };
    if (nodeType === "API") {
      node = {
        ...node,
        message: "",
        request: null,
        headers: [],
        fields: [],
        doneDot: { x: 0, y: 0 },
        errorDot: { x: 0, y: 0 },
      };
    } else if (nodeType === "Random") {
      node = {
        ...node,
        options: [
          {
            index: 0,
            value: 50,
          },
          {
            index: 1,
            value: 50,
          },
        ],
      };
    } else if (nodeType === "Pause") {
      node = {
        ...node,
        message: "Enter the pause duration",
        flow: "",
      };
    } else if (nodeType === "Filter") {
      node = {
        ...node,
        match: "all",
        conditions: [],
      };
    }
    return {
      ...state,
      nodeList: [...nodeList, node],
    };
  } else if (action.type === ActionTypes.ON_MOUSE_UP) {
    const node = action.payload;
    return {
      ...state,
      toNode: node,
    };
  } else if (action.type === ActionTypes.ON_SIZE_CHANGE) {
    const { height, node } = action.payload;
    const { nodeList } = state;
    const filterItems = nodeList.filter((n) => n.id === node.id);
    const currentItem = filterItems[0];
    let updatedItem = currentItem;
    if (currentItem) {
      updatedItem = {
        ...currentItem,
        height,
      };
    }
    const exceptCurrentItems = nodeList.filter((n) => n.id !== node.id);
    const updatedList = [...exceptCurrentItems, updatedItem];
    return {
      ...state,
      nodeList: updatedList,
    };
  } else if (action.type === ActionTypes.ON_NODE_CHANGE) {
    const node = action.payload;
    const { nodeList } = state;
    let updatedItem = node;
    const exceptCurrentItems = nodeList.filter((n) => n.id !== updatedItem.id);
    const updatedList = [...exceptCurrentItems, updatedItem];
    return {
      ...state,
      nodeList: updatedList,
    };
  } else if (action.type === ActionTypes.API_NODE_DRAG_MOVE) {
    const { nodeList } = state;
    const { newPosition, event, node } = action.payload;
    const filterNodes = nodeList.map((n) => {
      return n.id === node.id ? { ...n, ...newPosition, activeNode: true } : n;
    });

    const currentFilterNode = filterNodes.filter((n) => n.id === node.id);
    const currentNode = currentFilterNode[0];

    const parentNodes = filterNodes.filter((n) =>
      currentNode.prevNodeId.includes(n.id)
    );
    let updatedLines = [];

    if (currentNode.lines.length > 0) {
      // current nodes has child nodes
      currentNode.lines.map((line) => {
        let startX, startY, endX, endY;

        if (node.type === "API") {
          startX = currentNode.x + currentNode.width;
          startY = currentNode.y + 32;
        } else {
          startX = currentNode.x + currentNode.width;
          startY = currentNode.y + 32;
        }
        const curvature = 50;
        endX = line.endX;
        endY = line.endY;
        const midX = (startX + endX) / 2 + curvature;
        const midY = (startY + endY) / 2 - curvature;
        const offsetX = Math.abs(startX - endX) / 2;
        const offsetY = Math.abs(startY - endY) / 2;
        const controlX = midX - offsetX;
        const controlY = midY + offsetY;

        const lineData = {
          ...line,
          startX,
          startY,
          controlX,
          startY,
          controlX,
          controlY,
          endY,
          endX,
          endY,
        };
        updatedLines.push(lineData);
      });
    }

    let updatedNodeList = [];
    let otherNodes = filterNodes.filter((n) => n.id !== node.id);

    const updatedNode = { ...currentNode, lines: updatedLines };
    updatedNodeList = [...otherNodes, { ...updatedNode }];

    if (parentNodes.length > 0 && currentNode.prevNodeId !== -1) {
      // child node has a parent node
      let parentLineList = [];
      parentNodes.map((p) => {
        const list = [];
        p.lines.map((line) => {
          let endX, endY;
          if (line.id === `${p.id}-${currentNode.id}`) {
            endX = currentNode.x;
            endY = currentNode.y + 32;
          } else {
            endX = line.endX;
            endY = line.endY;
          }

          const curvature = 50;
          const startX = line.startX;
          const startY = line.startY;
          const midX = (startX + endX) / 2 + curvature;
          const midY = (startY + endY) / 2 - curvature;
          const offsetX = Math.abs(startX - endX) / 2;
          const offsetY = Math.abs(startY - endY) / 2;
          const controlX = midX - offsetX;
          const controlY = midY + offsetY;

          const lineData = {
            ...line,
            startX,
            startY,
            controlX,
            startY,
            controlX,
            controlY,
            endY,
            endX,
            endY,
          };
          list.push(lineData);
        });
        p.lines = list;
      });
      const otherPNodes = updatedNodeList.filter(
        (n) => parentNodes.indexOf(n.id) === -1
      );
      // const updatedPNode = { ...p, lines: parentLineList };
      updatedNodeList = [...otherPNodes];
    }

    return {
      ...state,
      nodeList: updatedNodeList,
    };
  } else {
    return {
      ...state,
    };
  }
};

export default mapReducer;
