import {Modal, Radio} from "antd";
import {useState} from "react";
import "./component.css";

const Redirect_modal = ({open}) => {
  return (
    <Modal footer={null} open={open}>
      <div className="subcomponent-redirect">
        <img alt="pastedImage" src="/pastedimage-8c2c-400w.png" className="subcomponent-pasted-image2" />
        <h1 className="subcomponent-text6">Hang Tight!</h1>
        <h1 className="subcomponent-text7">Please wait a moment while we redirect you to the payment page</h1>
      </div>
    </Modal>
  );
};

const Select_Payment_Method = ({open, setPayment_method_show, payment_method,  onSelectPayment_method, setPayment_method, subscription}) => {
  const handlePaymentOptionChange = (e) => {
    setPayment_method(e.target.value);
  };

  const handleCloseModal = () => {
    setPayment_method_show(false)
  };

  const HandleOk = () => {
    onSelectPayment_method()
  };

  return (
    <Modal open={open} okText="Next" title="Payment Method" onOk={HandleOk} onCancel={handleCloseModal}>
      <Radio.Group onChange={handlePaymentOptionChange} value={payment_method}>

        {subscription.card && (
             <div className="subcomponent-paymentitem">
             <div className="subcomponent-radiocontainer">
               <Radio value={"existing_card"} className="subcomponent-radiobutton" />
             </div>
             <div className="subcomponent-cardcontainer">
               <span className="subcomponent-text1">Select Existing Card</span>
               <div className="subcomponent-container1">
                 <span className="subcomponent-text2">{subscription.card.type} Ending in {subscription.card.last_4digits}</span>
                 <span className="subcomponent-text3">Expiry {subscription.card.expiry}</span>
               </div>
             </div>
           </div>
        )}
       
        <div className="subcomponent-paymentitem1">
          <div className="subcomponent-radiocontainer1">
            <Radio value={"new_card"} className="subcomponent-radiobutton" />
          </div>
          <div className="subcomponent-cardcontainer1">
            <span className="subcomponent-text4">Credit / Debit / ATM Card</span>
            <div className="subcomponent-cardsicon">
              <div className="subcomponent-cardbox">
                <img alt="pastedImage" src="/pastedimage-51r9-200h.png" className="subcomponent-pasted-image" />
              </div>
              <div className="subcomponent-cardbox1">
                <img alt="pastedImage" src="/pastedimage-tct-200h.png" className="subcomponent-pasted-image1" />
              </div>
            </div>
          </div>
        </div>
        {/* <div className="subcomponent-paymentitem2">
          <div className="subcomponent-radiocontainer2">
            <Radio value={"crypto"} className="subcomponent-radiobutton" />
          </div>
          <div className="subcomponent-cardcontainer2">
            <span className="subcomponent-text5">Crypto Payment</span>
            <div className="subcomponent-cardsicon1">
              <img src="/bitcoin-200h.png" alt="image" className="subcomponent-image" />
              <img src="/ethereum-200h.png" alt="image" className="subcomponent-image1" />
              <img src="/tether-200h.png" alt="image" className="subcomponent-image2" />
            </div>
          </div>
        </div> */}
      </Radio.Group>
    </Modal>
  );
};

const Select_plan = ({onSelectPlan, open, setSelect_plan_show}) => {
  const HandleCloseModal = () => {
    setSelect_plan_show(false);
  };

  return (
    <Modal width="800px" open={open} title="Select Plan" footer={null} onCancel={HandleCloseModal}>
      <div className="home-plans">
        <div className="home-planitem1">
          <h1 className="home-text045">Standard</h1>
          <h1 className="home-text046">$15</h1>
          <a href="#" onClick={() => onSelectPlan("standard")} className="home-link09 button">
            Select
          </a>
          <div className="home-features1">
            <div className="home-featureitem20">
              <img alt="pastedImage" src="/external/pastedimage-97oi-200h.png" className="home-pasted-image08" />
              <span className="home-text047">1 Bot</span>
            </div>
            <div className="home-featureitem21">
              <img alt="pastedImage" src="/external/pastedimage-97oi-200h.png" className="home-pasted-image09" />
              <span className="home-text048">1000 Messages</span>
            </div>
            <div className="home-featureitem22">
              <img alt="pastedImage" src="/external/pastedimage-97oi-200h.png" className="home-pasted-image10" />
              <span className="home-text049">3 Team Member</span>
            </div>
            <div className="home-featureitem23">
              <img alt="pastedImage" src="/external/pastedimage-97oi-200h.png" className="home-pasted-image11" />
              <span className="home-text050">Unlimited Keywords</span>
            </div>
            <div className="home-featureitem24">
              <img alt="pastedImage" src="/external/pastedimage-97oi-200h.png" className="home-pasted-image12" />
              <span className="home-text051">Unlimited Flows</span>
            </div>
            <div className="home-featureitem25">
              <img alt="pastedImage" src="/external/pastedimage-97oi-200h.png" className="home-pasted-image13" />
              <span className="home-text052">Unlimited Sequence</span>
            </div>
            <div className="home-featureitem26">
              <img alt="pastedImage" src="/external/pastedimage-97oi-200h.png" className="home-pasted-image14" />
              <span className="home-text053">Unlimited Integrations</span>
            </div>
            <div className="home-featureitem27">
              <img alt="pastedImage" src="/external/pastedimage-97oi-200h.png" className="home-pasted-image15" />
              <span className="home-text054">Unlimited Plugins</span>
            </div>
          </div>
        </div>
        <div className="home-planitem2">
          <h1 className="home-text055">Business</h1>
          <h1 className="home-text056">$30</h1>
          <a href="#" onClick={() => onSelectPlan("business")} className="home-link09 button">
            Select
          </a>
          <div className="home-features2">
            <div className="home-featureitem28">
              <img alt="pastedImage" src="/external/pastedimage-97oi-200h.png" className="home-pasted-image16" />
              <span className="home-text057">1 Bot</span>
            </div>
            <div className="home-featureitem29">
              <img alt="pastedImage" src="/external/pastedimage-97oi-200h.png" className="home-pasted-image17" />
              <span className="home-text058">2000 Messages</span>
            </div>
            <div className="home-featureitem30">
              <img alt="pastedImage" src="/external/pastedimage-97oi-200h.png" className="home-pasted-image18" />
              <span className="home-text059">5 Team Member</span>
            </div>
            <div className="home-featureitem31">
              <img alt="pastedImage" src="/external/pastedimage-97oi-200h.png" className="home-pasted-image19" />
              <span className="home-text060">Unlimited Keywords</span>
            </div>
            <div className="home-featureitem32">
              <img alt="pastedImage" src="/external/pastedimage-97oi-200h.png" className="home-pasted-image20" />
              <span className="home-text061">Unlimited Flows</span>
            </div>
            <div className="home-featureitem33">
              <img alt="pastedImage" src="/external/pastedimage-97oi-200h.png" className="home-pasted-image21" />
              <span className="home-text062">Unlimited Sequence</span>
            </div>
            <div className="home-featureitem34">
              <img alt="pastedImage" src="/external/pastedimage-97oi-200h.png" className="home-pasted-image22" />
              <span className="home-text063">Unlimited Integrations</span>
            </div>
            <div className="home-featureitem35">
              <img alt="pastedImage" src="/external/pastedimage-97oi-200h.png" className="home-pasted-image23" />
              <span className="home-text064">Unlimited Plugins</span>
            </div>
          </div>
        </div>
        <div className="home-planitem3">
          <h1 className="home-text065">Enterprise</h1>
          <h1 className="home-text066">$150</h1>
          <a href="#" onClick={() => onSelectPlan("enterprise")} className="home-link09 button">
            Select
          </a>
          <div className="home-features3">
            <div className="home-featureitem36">
              <span className="home-text067">1 Bot</span>
            </div>
            <div className="home-featureitem37">
              <span className="home-text068">Unlimited Messages</span>
            </div>
            <div className="home-featureitem38">
              <span className="home-text069">5 Team Member</span>
            </div>
            <div className="home-featureitem39">
              <span className="home-text070">Unlimited Keywords</span>
            </div>
            <div className="home-featureitem40">
              <span className="home-text071">Unlimited Flows</span>
            </div>
            <div className="home-featureitem41">
              <span className="home-text072">Unlimited Sequence</span>
            </div>
            <div className="home-featureitem42">
              <span className="home-text073">Unlimited Integrations</span>
            </div>
            <div className="home-featureitem43">
              <span className="home-text074">Unlimited Plugins</span>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export {Redirect_modal};
export {Select_Payment_Method};
export {Select_plan};
