import { whitelisted_paths, base_url } from '../constants';


// const users = JSON.parse(localStorage.getItem("users"))
// const timezone = users.timezone

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const getTimezone = () => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return timezone;
  };
  


  const check_if_is_authenticated = async () => {
    let current_path = window.location.pathname
    let access_token = localStorage.getItem("access_token")

    if (!access_token && !whitelisted_paths.includes(current_path)) {
      // console.log(window.location)
      window.location.href = "/login";
    } 

    if(access_token && (current_path == "/login" || current_path == "/signup")){
      window.location.href = "/chatbots"
    }
  };



  const formatDate = (isoString) => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  
    const options = {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
      timeZone: userTimeZone, // Including the user's detected time zone
    };
  
    return new Date(isoString).toLocaleDateString(undefined, options);
  };

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  

  
const format_time_ago = (date) => {
  // check diff between now and date
  const diff = new Date() - new Date(date)
  const seconds = Math.floor(diff / 1000)
  const minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)
  const months = Math.floor(days / 30)
  const years = Math.floor(months / 12)

  if (seconds < 60) {
      return `${seconds} sec. ago`
  }
  if (minutes < 60) {
      return `${minutes} min. ago`
  }
  if (hours < 24) {
      return `${hours} hr. ago`
  }
  if (days < 30) {
      return `${days} days. ago`
  }
  if (months < 12) {
      return `${months} months. ago`
  }
  return `${years} yrs. ago`
}


function convert_utc_to_month_date(dateString) {
  const formattedDate = new Date(dateString).toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });
  return formattedDate;
}


function abbreviate_last_messaged_time(utcDateString) {
  const now = new Date();
  
  // Convert the provided UTC date string to a Date object
  const utcDate = new Date(utcDateString);
  
  // Get the timezone offset for the provided timezone
  const timeZoneOffset = utcDate.getTimezoneOffset();
  
  // Adjust the current date to match the provided timezone
  const adjustedNow = new Date(now.getTime() + timeZoneOffset * 60000);
  
  // Get the timezone offset for the adjusted timezone
  const targetTimeZoneOffset = adjustedNow.toLocaleString('en', { timeZoneName: 'short' });
  const targetOffsetInMinutes = parseInt(targetTimeZoneOffset.split(' ')[1]);
  
  // Adjust the current date to the specified timezone
  const nowInTargetTimeZone = new Date(adjustedNow.getTime() + (targetOffsetInMinutes - timeZoneOffset) * 60000);
  
  const diffMilliseconds = nowInTargetTimeZone - utcDate;
  const diffSeconds = diffMilliseconds / 1000;
  const diffMinutes = diffSeconds / 60;
  const diffHours = diffMinutes / 60;
  const diffDays = diffHours / 24;
  const diffWeeks = diffDays / 7;
  const diffMonths = (nowInTargetTimeZone.getFullYear() - utcDate.getFullYear()) * 12 + nowInTargetTimeZone.getMonth() - utcDate.getMonth() + (nowInTargetTimeZone.getDate() - utcDate.getDate()) / 30.44;
  const diffYears = diffMonths / 12;
  
  if (diffSeconds < 60) {
    return `${Math.floor(diffSeconds)} ${Math.floor(diffSeconds) === 1 ? 'sec' : 'secs'}`;
  } else if (diffMinutes < 60) {
    return `${Math.floor(diffMinutes)} ${Math.floor(diffMinutes) === 1 ? 'min' : 'mins'}`;
  } else if (diffHours < 24) {
    return `${Math.floor(diffHours)} ${Math.floor(diffHours) === 1 ? 'hr' : 'hrs'}`;
  } else if (diffDays < 7) {
    return `${Math.floor(diffDays)} ${Math.floor(diffDays) === 1 ? 'day' : 'days'}`;
  } else if (diffMonths < 1) {
    return `${Math.floor(diffWeeks)} ${Math.floor(diffWeeks) === 1 ? 'wk' : 'wks'}`;
  } else if (diffMonths < 12) {
    return `${Math.ceil(diffMonths)} ${Math.ceil(diffMonths) === 1 ? 'mon' : 'mons'}`;
  } else {
    return `${Math.floor(diffYears)} ${Math.floor(diffYears) === 1 ? 'yr' : 'yrs'}`;
  }
}


function format_to_date_time(utcDateString) {
  const date = new Date(utcDateString)
  const utcDate = new Date(date.getTime() - date.getTimezoneOffset()*60*1000);


  // Set the time zone for formatting
  const options = {
    timeZone: timezone,
    day: "2-digit",
    month: "short",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true, // Use 12-hour format with AM/PM
  };

  const formattedDate = utcDate.toLocaleDateString("en-US", options);

  return formattedDate
}



function shortenFileName(fileName, maxLength) {
  if (fileName.length <= maxLength) {
    return fileName;
  }

  const parts = fileName.split('.');
  if (parts.length === 1) {
    // If the file name has no extension, truncate it.
    return fileName.substring(0, maxLength) + '...';
  }

  const extension = parts.pop();
  const maxBaseNameLength = maxLength - extension.length - 4; // 4 for the added "..." and the dot in the extension

  if (maxBaseNameLength < 1) {
    // The desired length is too short to preserve the extension.
    return fileName.substring(0, maxLength) + '...';
  }

  const baseName = parts.join('.');
  const shortenedBaseName = baseName.substring(0, maxBaseNameLength);
  return `${shortenedBaseName}...${extension}`;
}


const utc_to_date_time_formatter = (utcString) => {
  const date = new Date(utcString)
  const utcDate = new Date(date.getTime() - date.getTimezoneOffset()*60*1000);

    const options = {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
      timeZone: timezone
    };
  
    const formattedDate = utcDate.toLocaleString('en-US', options);
    
    return formattedDate;
  
}



  export {
    getTimezone,
    check_if_is_authenticated,
    formatDate,
    sleep,
    format_time_ago,
    convert_utc_to_month_date,
    abbreviate_last_messaged_time,
    format_to_date_time,
    shortenFileName,
    utc_to_date_time_formatter
  }