import React, { useState, useEffect } from 'react'

function Livechat() { 

  const [pauseDuration, setPauseDuration] = useState()
  return (
    <>
    <div className='flex flex-col'>
      <div className='flex md:flex-row flex-col justify-between items-center min-h-[10vh] bg-white border border-gray-300 p-6 rounded-md'>
        <h2 className='text-[1em] font-semibold'>Automation Pause During Conversations</h2>
        <div className='flex flex-col md:items-start items-center justify-center gap-2 my-4 px-5'>
            <h2 className='font-semibold text-[1em] my-2'>Enter pause duration</h2>
            <div className='flex justify-between items-center md:gap-[2vw] gap-4'>
              <input type="number" value={pauseDuration} onChange={(e) => setPauseDuration(e.target.value)} className='md:w-[10vw] outline-none border-gray-300 p-2 md:px-2 px-6 text-[1em] border rounded-md'/>
              <select name="" id="" className='p-2 border outline-none border-gray-300 rounded-md'>
                <option value={"Minutes"}>Minutes</option>
                <option value={"Hours"}>Hours</option>
                <option value={"Days"}>Days</option>
              </select>
            </div>
            <button className='p-4 my-2 rounded-md border border-gray-400 text-[1.em] font-semibold'>Update</button>
        </div>
        <h3 className='md:w-[30%] w-[70%] text-[0.8em]'>During a chat conversation with a Live Chat Agent, all automation associated with a contact will be temporarily paused for a selected duration. the pause duration can range from a minimum of 30 minutes to a maximum of 24 hours.</h3>
      </div>
    </div>
    </>
  )
}

export default Livechat