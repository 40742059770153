import React, {useState, useEffect} from "react";
import {Input, Button, Select} from "antd";
import "./template_message.css";
import {get_template_detail, get_templates, update_template, get_bot} from "../../../apis/index";
import Loading from "../../../components/loading";
import Select_variable from "../../component/buttons/select_variable";
import FloatingButton from "../buttons/FloatingButton";

const Template_message = ({bot_id, onChange}) => {
  const [templates, setTemplates] = useState([]);
  const [template, setTemplate] = useState(null);
  const [selected_template, setSelected_template] = useState("");
  const [loading, setLoading] = useState(false);
  const [variables, setVariables] = useState([]);

  const [body_var_floats, setBody_var_floats] = useState([]);


  useEffect(() => {
    const onLoad = async () => {
      setLoading(true)
      let response = await get_templates(bot_id);
      if (response && response.data) {
        let newArray = response.data.map((item) => ({label: item.name, value: item.name}));
        setTemplates(newArray);
      }


      let bot = await get_bot(bot_id);
      if (bot) {
        setVariables(bot.variables);
      }
      setLoading(false)
    };

    onLoad();
  }, []);

  useEffect(() => {
    if(!template){
      return
    }
    update_template(bot_id, JSON.stringify(template));
  }, [template]);



  const Template_Select = async (template_name) => {
    if (onChange){
      onChange(template_name)

    }
    setSelected_template(template_name);
    setLoading(true);
    let response = await get_template_detail(bot_id, template_name);
    console.log(response.data)
    if (response && response.data) {
      let template = response.data

      setBody_var_floats(Array(template.body.variables.length).fill(false))
      setTemplate(template);
    }
    setLoading(false);
  };






  return (
    <div className="message-container">
      <Select value={selected_template} onSelect={Template_Select} style={{width: "100%", marginBottom: 20}} placeholder="Select template" options={templates} />

      {loading ? (
        <Loading type={"circle"} />
      ) : !template ? (
        <></>
      ) : (
        <div className="message-template">
          {template.header && (
            <div className="message-header">
              <span className="message-text">
                <span>Header</span>
                <br></br>
              </span>
              <div className="message-headertype">
                {/* <div className="message-subheader"> </div> */}
                <Button disabled={true} type="primary">
                  {template.header.component.format}
                </Button>

                {template.header.component.format == "IMAGE" ? (
                <Input value={template.header.component.example.header_handle[0]} disabled={true} style={{marginLeft: 10}} type="text" placeholder="placeholder" />

                ):(
                  <Input value={template.header.component.text} disabled={true} style={{marginLeft: 10}} type="text" placeholder="placeholder" />

                )}
              </div>

              {template.header.variables.length != 0 && (
                <div className="message-variablescontainer">
                  <span className="message-text03">
                    <span>Variables</span>
                    <br></br>
                  </span>
                  {template.header.variables.map((variable, index) => (
                    <div key={index} className="message-variable">
                      <span className="message-text06">{variable.label}</span>
                      <Input
                        value={variable.value}
                        onChange={(e) => {
                          const newTemplate = {...template};

                          newTemplate.header.variables[index].value = e.target.value;
                          setTemplate(newTemplate);
                        }}
                        style={{marginLeft: 10}}
                        type="text"
                        placeholder="placeholder"
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}

          <div className="message-body">
            <span className="message-text08">Body</span>
            <div className="message-sub-body">
              <Input.TextArea value={template.body.component.text} rows={10} disabled={true} type="text" placeholder="placeholder" />
            </div>

            {template.body.variables.length != 0 && (
              <div className="message-variablescontainer1">
                <span className="message-text09">
                  <span>Variables</span>
                  <br></br>
                </span>

                {template.body.variables.map((variable, index) => (
                  <div key={index} className="message-variable2">
                    <span className="message-text12">{variable.label}</span>
                    <Input
                      value={variable.value}
                      style={{marginLeft: 10}}
                      type="text"
                      placeholder="placeholder"
                      onChange={(e) => {
                        const newTemplate = {...template};

                        newTemplate.body.variables[index].value = e.target.value;

                        setTemplate(newTemplate);
                      }}
                    />
                    <FloatingButton
                      onClick={() => {
                        const updated_body_var_floats = [...body_var_floats];
                        updated_body_var_floats[index] = true;
                        setBody_var_floats(updated_body_var_floats);
                      }}
                    />

                    <Select_variable
                      open={body_var_floats[index]}
                      onClose={() => {
                        const updated_body_var_floats = [...body_var_floats];
                        updated_body_var_floats[index] = false;
                        setBody_var_floats(updated_body_var_floats);
                      }}
                      variables={variables}
                      onChange={(value) => {
                        const newTemplate = {...template};

                        newTemplate.body.variables[index].value += "{{" + value + "}}";

                        setTemplate(newTemplate);

                        const updated_body_var_floats = [...body_var_floats];
                        updated_body_var_floats[index] = false;
                        setBody_var_floats(updated_body_var_floats);
                      }}
                    />
                  </div>
                ))}

                {/* <div className="message-variable3">
                <span className="message-text12">{"{{1}}"}</span>
                  <Input style={{marginLeft: 10}} type="text" placeholder="placeholder" />
                </div> */}
              </div>
            )}
          </div>

          {template.footer && (
            <div className="message-footer">
              <span className="message-text14">Footer</span>
              <div className="message-sub-body1">
                <Input value={template.footer.component.text} disabled={true} style={{marginLeft: 10}} type="text" placeholder="placeholder" />
              </div>
            </div>
          )}

          {template.button && (
            <div className="message-buttonscontainer">
              <span className="message-text15">Buttons</span>

              {template.button.component.buttons.map((button) => (
                <>
                  {button.type == "QUICK_REPLY" && (
                    <div className="message-quickreply">
                      <span className="message-text16">Quicky reply</span>
                      <div className="message-column">
                        <span className="message-text17">Button text</span>
                        <Input disabled={true} value={button.text} style={{marginLeft: 10}} type="text" placeholder="placeholder" />
                      </div>
                    </div>
                  )}

                  {button.type == "URL" && (
                    <div className="message-visitwebsite">
                      <span className="message-text18">
                        <span>Visit website</span>
                        <br></br>
                      </span>
                      <div className="message-row">
                        <div className="message-column1">
                          <span className="message-text21">Button text</span>
                          <Input
                            // style={{marginLeft: 10}}
                            disabled={true}
                            value={button.text}
                            type="text"
                            placeholder="placeholder"
                          />
                        </div>
                        <div className="message-column2">
                          <span className="message-text22">Website Url</span>
                          <Input
                            // style={{marginLeft: 10}}
                            disabled={true}
                            value={button.url}
                            type="text"
                            placeholder="placeholder"
                          />
                        </div>
                      </div>
                      {button.variables.length > 0 && (
                        <div className="message-variablescontainer2">
                          <span className="message-text23">
                            <span>Variables</span>
                            <br></br>
                          </span>

                          {button.variables.map((variable, index) => (
                          
                            <div className="message-variable4">
                              

                              <span className="message-text26">{variable.label}</span>
                              <Input value={variable.value} disabled={true} style={{marginLeft: 10}} type="text" placeholder="placeholder" />

                              <Select_variable
                                open={body_var_floats[index]}
                                onClose={() => {
                                  const updated_body_var_floats = [...body_var_floats];
                                  updated_body_var_floats[index] = false;
                                  setBody_var_floats(updated_body_var_floats);
                                }}
                                variables={variables}
                                onChange={(value) => {
                                  const newTemplate = {...template};

                                  newTemplate.body.variables[index].value += "{{" + value + "}}";

                                  setTemplate(newTemplate);

                                  const updated_body_var_floats = [...body_var_floats];
                                  updated_body_var_floats[index] = false;
                                  setBody_var_floats(updated_body_var_floats);
                                }}
                              />
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  )}

                  {button.type == "CALL" && (
                    <div className="message-visitwebsite1">
                      <span className="message-text27">
                        <span>Call phone number</span>
                        <br></br>
                      </span>
                      <div className="message-row1">
                        <div className="message-column3">
                          <span className="message-text30">Button text</span>
                          <Input type="text" placeholder="placeholder" />
                        </div>
                        <div className="message-column4">
                          <span className="message-text31">Phone number</span>
                          <Input type="text" placeholder="placeholder" />
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Template_message;
