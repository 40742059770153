import {Link} from "react-router-dom";
const {format_time_ago, convert_utc_to_month_date} = require("../utils/helper");

const ChatbotItem = ({chatbot, chatbots, setChatbots, update_bot}) => {
  const to = `/chatbot/${chatbot._id}`;
  return (
    <Link to={to} className="chatbots-chatbotitem">
      <div className="chatbots-container11">
        <img alt="image" src="/user-thumbnail-200w-200w.png" className="chatbots-image" />
      </div>
      <div className="chatbots-container12">
        <span className="chatbots-text10">{chatbot.name}</span>
      </div>
      <div className="chatbots-container13">
        <span className="chatbots-text11">{chatbot.channel}</span>
      </div>
      {/* <div className="chatbots-container14">
        <span className="chatbots-text12">{chatbot.messages_sent ? chatbot.messages_sent : 0}</span>
      </div> */}
      <div className="chatbots-container15">
        <span className="chatbots-text13">{convert_utc_to_month_date(chatbot.last_modified)}</span>
      </div>
      {/* <div
        className="chatbots-container16"
        onClick={(e) => {
          e.preventDefault();
          let isActive = chatbot.isActive;
          chatbot.isActive = !isActive;
          setChatbots([...chatbots]);
          update_bot(chatbot._id, JSON.stringify({isActive: !isActive}));
        }}
      >
        {chatbot.isActive ? (
          <svg viewBox="0 0 1170.2857142857142 1024" className="chatbots-icon">
            <path d="M0 512c0-201.714 164-365.714 365.714-365.714h438.857c201.714 0 365.714 164 365.714 365.714s-164 365.714-365.714 365.714h-438.857c-201.714 0-365.714-164-365.714-365.714zM804.571 804.571c161.143 0 292.571-131.429 292.571-292.571s-131.429-292.571-292.571-292.571-292.571 131.429-292.571 292.571 131.429 292.571 292.571 292.571z"></path>
          </svg>
        ) : (
          <svg viewBox="0 0 1170.2857142857142 1024" className="chatbots-icon2">
            <path d="M658.286 512c0-161.143-131.429-292.571-292.571-292.571s-292.571 131.429-292.571 292.571 131.429 292.571 292.571 292.571 292.571-131.429 292.571-292.571zM1097.143 512c0-161.143-131.429-292.571-292.571-292.571h-220.571c89.143 66.857 147.429 173.143 147.429 292.571s-58.286 225.714-147.429 292.571h220.571c161.143 0 292.571-131.429 292.571-292.571zM1170.286 512c0 201.714-164 365.714-365.714 365.714h-438.857c-201.714 0-365.714-164-365.714-365.714s164-365.714 365.714-365.714h438.857c201.714 0 365.714 164 365.714 365.714z"></path>
          </svg>
        )}
      </div> */}
    </Link>
  );
};

export default ChatbotItem;
