import React, {useState, useEffect} from "react";
import {get_subscription, create_subscription} from "../../../apis/index"
import Subscription_plan from "./subscription_plan";
import {Select_plan, Select_Payment_Method, Redirect_modal} from "./component"
import Cards from "./cards";
import { toHaveStyle } from "@testing-library/jest-dom/matchers";

function Subscription() {
  const [current_section, setCurrent_section] = useState("subscription_plan");
  const [subscription, setSubscription] = useState({products: {}});


  const [select_plan_show, setSelect_plan_show] = useState(false)
  const [selected_plan, setSelected_plan] = useState(null)

  const [payment_method_show, setPayment_method_show] = useState(false)
  const [payment_method, setPayment_method] = useState("new_card")

  const [redirect_modal_show, setRedirect_modal_show] = useState(false)

  const onSelectPayment_method = async () => {
    // show redirect modal
    if(payment_method != "existing_card"){
      setRedirect_modal_show(true)
    }

    setPayment_method_show(false)

    let payload = {type: "subscription_plan", plan: selected_plan, payment_method}
    let response = await create_subscription(JSON.stringify(payload))
   
  if (response) {
      if(response.payment_link){
        window.open(response.payment_link);
      }else{
        if(response.status == "success"){
          toHaveStyle.success("Upgrade Successful")
        }
      }
      setRedirect_modal_show(false);
  }
  }

  const onSelectPlan = (plan) => {
    // set plan and open payment method
    setSelected_plan(plan)
    setPayment_method_show(true)
    setSelect_plan_show(false)
  }


  useEffect(() => {
    const onLoad = async () => {
      let result = await get_subscription();
      if (result) {
        let sub = result.data;
        setSubscription(sub);
      }
   };
    onLoad();
  }, []);


  const handle_payment_method_click = () => {
    setCurrent_section("payment_method");
  };

  const handle_subscription_plan_click = () => {
    setCurrent_section("subscription_plan");
  };

  const handleUpgrade = () => {
    setSelect_plan_show(true);
  };

  return (
    <>
      {/* <div className="credit-balance">
        <div className="credit-creditbalance">
          <span className="credit-text">Credit Balance</span>
          <span className="credit-text1">${subscription.credit_balance}</span>
        </div>
        <button type="button" className="credit-button button">
          + Top up
        </button>
      </div> */}

      <div className="settings-sub-header">
        <div className="settings-btn-group">
          <h1 className={`${current_section === "subscription_plan" ? "settings-text02" : "settings-text03"}`} onClick={handle_subscription_plan_click}>
            Subscription Plans
          </h1>
          <div style={{width: "70px"}}></div>
          <h1 className={`${current_section === "payment_method" ? "settings-text02" : "settings-text03"}`} onClick={handle_payment_method_click}>
            Payment Method
          </h1>
        </div>
        <div className="settings-button" onClick={handleUpgrade}>
          <img alt="pastedImage" src="/external/pastedimage-viwa-200h.png" className="settings-pasted-image" />
          <span className="settings-text04">Upgrade Plan</span>
        </div>
      </div>

      {current_section == "subscription_plan" ? <Subscription_plan subscription={subscription} setSubscription={setSubscription} handleUpgrade={handleUpgrade} handle_subscription_plan_click={handle_subscription_plan_click} /> : <Cards card={subscription.card} handleUpgrade={handleUpgrade} handle_payment_method_click={handle_payment_method_click} />}
    
      <Select_Payment_Method subscription={subscription} onSelectPayment_method={onSelectPayment_method} open={payment_method_show} setPayment_method_show={setPayment_method_show} payment_method={payment_method} setPayment_method={setPayment_method} />
      <Select_plan open={select_plan_show} onSelectPlan={onSelectPlan} setSelect_plan_show={setSelect_plan_show} setSelected_plan={setSelected_plan} />
      <Redirect_modal open={redirect_modal_show} />
    </>
  );
}

export default Subscription;
