import {format_to_date_time} from "../../utils/helper";
import {Card, Flex, Button} from "antd";
import {PoweroffOutlined} from "@ant-design/icons";

const {Meta} = Card;

const Message = ({message, contact}) => {
  let sender = message.from.sender;

  let msg = message.message;

  let text_message;
  if (msg.type == "text") {
    text_message = msg.text.body;
  } else {
    text_message = "";
  }

  const Message_Item = () => {
    if (msg.type == "text" && (sender == "bot" || sender == "admin")) {
      return (
        <div className="livechat-message">
          <span className="livechat-text79">{text_message}</span>
          <span className="livechat-text80">{format_to_date_time(message.timestamp)}</span>
        </div>
      );
    }else if(msg.type == "template"){
     return <Card hoverable title="Template Message" style={{backgroundColor: "#85DCFF", width: 300, padding: 5}}>
      <p>{msg.template.name}</p>
      <span className="livechat-text83">{format_to_date_time(message.timestamp)}</span>
    </Card>
    } else if (msg.type == "text" && sender == "contact") {
      return (
        <div className="livechat-message1">
          <span className="livechat-text82">{text_message}</span>
          <span className="livechat-text83">{format_to_date_time(message.timestamp)}</span>
        </div>
      );
    } else if (msg.type == "interactive") {
      let cover = <></>;

      let raw_message = message.raw_message;

      let text = raw_message.message;
      let header = msg.interactive.header;

      let buttons = raw_message.button_data.buttons;
      let button_type = raw_message.button_data.type;

      if (header) {
        switch (header.type) {
          case "image":
            cover = <img alt="image" src={header.image.link} />;
            break;

          case "audio":
            cover = (
              <audio controls>
                <source src={header.audio.link} type="audio/mp3" />
                Your browser does not support the audio tag.
              </audio>
            );
            break;

          case "video":
            cover = (
              <video width="320" height="240" controls>
                <source src={header.video.link} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            );
            caption = msg.video.caption;
            break;

          default:
            cover = (
              <div style={{display: "flex", alignItems: "center", justifyContent: "center", padding: 20}}>
                <img alt="image" src="/attachment.png" style={{width: "30px", height: "30px"}} onClick={() => window.open(header.document.link, "_blank")} />
                <a style={{color: "blue"}} onClick={() => window.open(header.document.link, "_blank")}>
                  attachment
                </a>
              </div>
            );
            break;
        }
      }


      return (
        <Card cover={cover} hoverable title="" style={{backgroundColor: "#85DCFF", width: 300, padding: 5}}>
          <p>{text}</p>
          <Flex gap="small" style={{marginTop: "20px"}} vertical>
            {buttons.map((button) =>
              button_type === "cta_url" ? (
                <Button key={button.id} type="primary" icon={<img style={{width: "16px", height: "16px"}} src="external-link.svg" alt="Icon" />} onClick={() => enterLoading(2)}>
                  {button.title}
                </Button>
              ) : (
                <Button key={button.id} type="primary">
                  {button.title}
                </Button>
              )
            )}
          </Flex>

          <span className="livechat-text83">{format_to_date_time(message.timestamp)}</span>
        </Card>
      );
    } else {
      let cover = <></>;
      let caption = "";

      switch (msg.type) {
        case "image":
          cover = <img alt="image" src={msg.image.link} />;
          caption = msg.image.caption;
          break;

        case "audio":
          caption = msg.audio.caption;
          cover = (
            <audio controls>
              <source src={msg.audio.link} type="audio/mp3" />
              Your browser does not support the audio tag.
            </audio>
          );

          if (caption) {
          } else {
            return cover;
          }
          break;

        case "video":
          cover = (
            <video width="320" height="240" controls>
              <source src={msg.video.link} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          );
          caption = msg.video.caption;
          break;

        default:
          cover = (
            <div style={{display: "flex", alignItems: "center", justifyContent: "center", padding: 20}}>
              <img alt="image" src="/attachment.png" style={{width: "30px", height: "30px"}} onClick={() => window.open(msg.document.link, "_blank")} />
              <a style={{color: "blue"}} onClick={() => window.open(msg.document.link, "_blank")}>
                {msg.document.filename}
              </a>
            </div>
          );
          caption = msg.document.caption;
          break;
      }

      return (
        <Card
          hoverable
          style={{
            width: 240,
            padding: 5,
            backgroundColor: "#D9D9D9",
          }}
          cover={cover}
        >
          <Meta description={caption} />
          <span className="livechat-text83">{format_to_date_time(message.timestamp)}</span>

        </Card>
      );
    }
  };

  if (sender == "contact") {
    return (
      <div className="livechat-msg-body1">
        <div className="livechat-msg-sender1">
          <img alt="image" src="/user-thumbnail-200w-200w.png" className="livechat-image27" />
          <span className="livechat-text81">{contact.variables.full_name}</span>
        </div>
        <Message_Item />
      </div>
    );
  } else if (sender == "system") {
    return (
      <div className="livechat-msg-privatenote">
        <span className="livechat-privatenote-header">{format_to_date_time(message.timestamp)}</span>
        <span className="livechat-privatenote-body">{text_message}</span>
      </div>
    );
  } else {
    return (
      <div className="livechat-msg-body">
        <div className="livechat-msg-sender">
          <span className="livechat-text78">Bot</span>
          <img alt="image" src="/bot_icon.png" className="livechat-image26" />
        </div>
        <Message_Item />
      </div>
    );
  }
};

export default Message;
