import { Button, DatePicker, Input, Select } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import FilterItem from "../Items/FilterItem";
import Select_variable from "../buttons/select_variable";


const FilterDrawer = ({ node, onNodeChange, onNodeDelete, loading, variables, setVariables}) => {
  const [filters, setFilters] = useState([]);

  useEffect(() => {
    console.log(node)
    setFilters(node.data?.conditions);
  }, [node.id]);

  
  useEffect(() => {
      const updatedFilters = filters.filter(filter => filter.criteria1 !== '');
    
      const updatedNode = {
        ...node,
        data: {
          conditions: updatedFilters,
        }
      };
    
      onNodeChange(updatedNode);
  
  }, [filters]);



  const addFilter = () => {
    setFilters([
      ...filters,
      {
        id:  `filter-${Date.now()}`,
        criteria1: "",
        condition: "",
        criteria2: "",
      },
    ]);
  };

  const onDeleteFilter = (item) => {
    const filterItems = filters.filter((fil) => fil.id !== item.id);
    setFilters(filterItems);
  };

  const onUpdateFilter = (criteria1, condition, criteria2, item) => {
    const filterFilters = filters.filter((f) => f.id === item.id);
    console.log("filters at the top", filterFilters)

    if (filterFilters.length === 0) return;

    let currentFilter = filterFilters[0];
    let newItem;
    if (criteria1 !== "") {
      newItem = {
        ...currentFilter,
        criteria1,
      };
    } else if (condition !== "") {
      newItem = {
        ...currentFilter,
        condition,
      };
    } else {
      newItem = {
        ...currentFilter,
        criteria2: criteria2,
      };
    }
   
    console.log("filters at the bottom", filters)
    const list = filters.map((f) => (f.id === item.id ? newItem : f));
    setFilters(list);
  };


  return (
    <div>
      <h3>Add Filter</h3>
      {filters.length > 0 &&
        filters.map((item) => (
          <FilterItem
            key={item.id}
            item={item}
            deleteFilter={() => onDeleteFilter(item)}
            onUpdateFilter={onUpdateFilter}
            variables={variables}
          />
        ))}
      <Input
        contentEditable={false}
        style={{ marginTop: "1rem" }}
        value="Run path: NOT"
      />
      <Button
        type="primary"
        onClick={addFilter}
        style={{ width: "100%", marginTop: "1rem" }}
      >
        Add new condition
      </Button>
    </div>
  );
};

export default FilterDrawer;
