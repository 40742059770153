import React, {useState} from "react";
import {Link} from "react-router-dom";
import Subscription from "../components/Settings/subscription/index";
import Notifications from "../components/Settings/Notifications";
import General from '../components/Settings/General'
import Livechat from '../components/Settings/Livechat'
import Integration from '../components/Settings/Integration'
import Teams from '../components/Settings/Teams'
import DataSource from "../components/Settings/DataSource";
import Sidebar from "../components/sidebar";
import {Helmet} from "react-helmet";
import "./settings.css";

const Settings = (props) => {
  const [tab, setTab] = useState("subscriptions");

  const components = {
    "general":<General/>,
    "subscriptions":<Subscription/>,
    "livechat":<Livechat/>,
    "notifications":<Notifications/>,
    "integration":<Integration/>,
    // "teams":<Teams/>,
    "data_source":<DataSource/>
}


  const handle_tab_click = (current_tab) => {
    console.log(current_tab)
    setTab(current_tab)
  }

  return (
    <div className="settings-container">
      <Helmet>
        <title>settings - 50chats No-Code Chatbot Builder</title>
        <meta name="description" content="Empower your business with 50chats, the No-Code Chatbot Builder. Create powerful chatbots effortlessly" />
        <meta property="og:title" content="settings - 50chats No-Code Chatbot Builder" />
        <meta property="og:description" content="Empower your business with 50chats, the No-Code Chatbot Builder. Create powerful chatbots effortlessly" />
      </Helmet>
      <div className="settings-container1">
        <Sidebar />
        <div className="settings-body">
          <div className="settings-header">
            <h3 className="settings-heading">Settings</h3>
          </div>

          <div className="settings-subscription-subscription-plan">
            <div className="settings-nav-bar">
              <h1 className={`${tab === "general" ? "settings-header-item01" : "settings-header-item"}`} onClick={() => handle_tab_click("general")}>General</h1>
              <h1 className={`${tab === "subscriptions" ? "settings-header-item01" : "settings-header-item"}`} onClick={() => handle_tab_click("subscriptions")}>Subscription</h1>
              <h1 className={`${tab === "livechat" ? "settings-header-item01" : "settings-header-item"}`} onClick={() => handle_tab_click("livechat")}>Livechat</h1>
              <h1 className={`${tab === "notifications" ? "settings-header-item01" : "settings-header-item"}`} onClick={() => handle_tab_click("notifications")}>Notifications</h1>
              <h1 className={`${tab === "integration" ? "settings-header-item01" : "settings-header-item"}`} onClick={() => handle_tab_click("integration")}>Integration</h1>
              <h1 className={`${tab === "teams" ? "settings-header-item01" : "settings-header-item"}`} onClick={() => handle_tab_click("teams")}>Teams</h1>
              <h1 className={`${tab === "data_source" ? "settings-header-item01" : "settings-header-item"}`} onClick={() => handle_tab_click("data_source")}>
              Data Source              </h1>
            </div>

            {components[tab]}

          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
