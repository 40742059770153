import React from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import './header.css'

const Header = (props) => {
  return (
    <header data-role="Header" className="home-header sticky top-0 z-50 w-[100vw]">
        <h1 className="home-logo"><Link to='/' className=''>50chats</Link></h1>
        <div className="home-navbar-container">
          <nav className="home-navbar">
            <Link to="/#pricing" className="home-link">
              Pricing
            </Link>
            <Link to="/#features" className="home-link01">
              Features
            </Link>
            <Link to={{pathname:"/",hash:"#faqs"}} className="home-link02">
              FAQs
            </Link>
            <Link to="/help" className="home-navlink">
              Help
            </Link>
          </nav>
        </div>
        <div className="home-btn-group">
          <Link to="/login" className="home-sign-in button">
            Sign In
          </Link>
          <Link to="/signup" className="home-register button">
            Sign Up
          </Link>
        </div>
        <div data-role="BurgerMenu" className="home-burger-menu">
          <svg viewBox="0 0 1024 1024" className="home-icon">
            <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
          </svg>
        </div>
        <div data-role="MobileMenu" className="home-mobile-menu">
          <div className="home-nav">
            <div className="home-container1">
              <h1 className="home-logo1">50chats</h1>
              <div data-role="CloseMobileMenu" className="home-menu-close">
                <svg viewBox="0 0 1024 1024" className="home-icon2">
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                </svg>
              </div>
            </div>
          </div>
          <nav className="home-navbar1">
            <Link to="#pricing" className="home-link03">
              Pricing
            </Link>
            <Link to="#features" className="home-link04">
              Features
            </Link>
            <Link to="#faqs" className="home-link05">
              FAQs
            </Link>
            <Link to="/help" className="home-navlink1">
              Help
            </Link>
          </nav>
          <div className="home-btn-group1">
            <Link to="/login" className="home-sign-in1 button">
              Sign In
            </Link>
            <Link to="/signup" className="home-register1 button">
              Sign Up
            </Link>
          </div>
        </div>
      </header>
  )
}

Header.defaultProps = {
  text: 'Pricing',
  text3: 'Help',
  rootClassName: '',
  text2: 'FAQs',
  Register: 'Sign Up\n',
  text1: 'Features',
  Sign_in: 'Sign In\n',
  image_src: 'https://presentation-website-assets.teleporthq.io/logos/logo.png',
  image_alt: 'image',
  logo: '50chats',
}

Header.propTypes = {
  text: PropTypes.string,
  text3: PropTypes.string,
  rootClassName: PropTypes.string,
  text2: PropTypes.string,
  Register: PropTypes.string,
  text1: PropTypes.string,
  Sign_in: PropTypes.string,
  image_src: PropTypes.string,
  image_alt: PropTypes.string,
  logo: PropTypes.string,
}

export default Header
