import React, {useEffect} from 'react'
import Community from './Community/Community'
import Header from '../header'
import { Link } from 'react-router-dom'
import Footer from '../footer'

function Help() {

  return (
    <div>
        <Header/>
        <Community/> 
        <Footer/>
    </div>
  )
}

export default Help